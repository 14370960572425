import React, { Component } from 'react';
import { array, bool, object, node, string, func } from 'prop-types';
import * as geolib from 'geolib';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  NamedLink,
  ResponsiveImage,
  ReviewRating,
  IconCheckmark,
  IconSpinner,
} from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ListingCard.module.css';

const { Money } = sdkTypes;

const minPriceFirst = (p1, p2) => p1.priceValue - p2.priceValue;

const getSmallestPrice = (pricePerRate, defaultPrice) => {
  const pricePerRateArray = Object.entries(pricePerRate || {})
    .map(([priceType, priceValue]) => ({ priceType, priceValue }))
    .sort(minPriceFirst);

  if (pricePerRateArray.length === 0)
    return {
      unitPrice: new Money(defaultPrice.amount, defaultPrice.currency),
      unitTranslationKey: 'LandingCard.pricePerDay',
    };

  const smallestPrice = pricePerRateArray[0];

  return {
    unitPrice: new Money(smallestPrice.priceValue, defaultPrice.currency),
    unitTranslationKey: `LandingCard.${smallestPrice.priceType}`,
  };
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price, 0);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    userLatLng,
    latLngLoading,
    isBestMatch,
    bestMatchText,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, metadata, geolocation } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { averageRating, ratingCount } = metadata || {};
  const { category, pricePerRate } = publicData || {};

  const { unitPrice, unitTranslationKey } = getSmallestPrice(pricePerRate, price);

  const { formattedPrice, priceTitle } = priceData(unitPrice, intl);

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const selectedCategory = categoryOptions.find(c => c.key === category);

  const distance =
    userLatLng && geolocation
      ? (
          geolib.getDistance(
            { latitude: userLatLng.lat.toString(), longitude: userLatLng.lng.toString() },
            {
              latitude: geolocation.lat.toString(),
              longitude: geolocation.lng.toString(),
            }
          ) * 0.000621371
        ) // transform kilometers to miles
          .toFixed(1)
      : null;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          {isBestMatch ? (
            <div className={css.bestMatchContainer}>
              <IconCheckmark size="big" rootClassName={css.bestMatchIcon} />
              <div className={css.bestMatchTitle}>{bestMatchText}</div>
            </div>
          ) : null}
          <div className={css.title}>{title}</div>
          <div className={css.categoryTitle}>{selectedCategory && selectedCategory.label}</div>
          <div className={css.ratingContainer}>
            <ReviewRating reviewStarClassName={css.rating} rating={averageRating} />
            {ratingCount && (
              <div className={css.ratingCount}>
                <FormattedMessage id="ListingCard.ratingCount" values={{ count: ratingCount }} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={css.price}>
        <div>
          {latLngLoading ? (
            <IconSpinner />
          ) : !distance ? (
            <FormattedMessage id="ListingCard.locationNotEnabled" />
          ) : distance < 1 ? (
            <FormattedMessage id="ListingCard.lessThenMileAway" />
          ) : (
            <FormattedMessage id="ListingCard.distance" values={{ distance }} />
          )}
        </div>
        <div className={css.priceValue} title={priceTitle}>
          <FormattedMessage
            id="ListingCard.price"
            values={{ price: formattedPrice, unit: <FormattedMessage id={unitTranslationKey} /> }}
          />
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
  isBestMatch: false,
  bestMatchText: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  userLatLng: object,
  latLngLoading: bool.isRequired,
  isBestMatch: bool,
  bestMatchText: node,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
