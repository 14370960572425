import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldRadioButton,
  FieldCheckbox,
  FieldPhoneNumberInput,
  FieldMultiselect,
  Dropzone,
  ImageFromFile,
  ValidationError,
  NamedLink,
  FieldNumberInput,
  FieldSelect,
  FieldInterests,
} from '../../components';
import { GENDER_PRONOUNS } from '../../constants';
import { range } from 'lodash';
import moment from 'moment';

import css from './SignupForm.module.css';

import imageImage from '../../assets/photo.png';

const TODAY = new Date();
const CURRENT_YEAR = TODAY.getFullYear();
const CURRENT_YEAR_ADULT = CURRENT_YEAR - 18;
const CURRENT_MONTH = TODAY.getMonth() + 1;
const CURRENT_DAY = TODAY.getDate();

const YEARS = range(CURRENT_YEAR_ADULT, CURRENT_YEAR_ADULT - 100, -1);
const MONTHS = year => {
  const shortMonthsString = moment.monthsShort();
  let months =
    +year === +CURRENT_YEAR_ADULT ? shortMonthsString.slice(0, CURRENT_MONTH) : shortMonthsString;
  return months.map((m, i) => ({ label: m, key: i + 1 }));
};

const DAYS = (year, month) =>
  +year === +CURRENT_YEAR_ADULT && +month === +CURRENT_MONTH
    ? range(1, CURRENT_DAY, 1)
    : range(1, moment(`${year}-${month}`, 'YYYY-MM').daysInMonth() + 1, 1);

class SignupFormComponent extends Component {
  constructor(props) {
    super(props);

    this.removeImage = this.removeImage.bind(this);
  }

  addImage = files => {
    this.props.addImage(null);
    this.props.addImage(files[0]);
  };

  removeImage(e) {
    e.preventDefault();

    this.props.addImage(null);
  }

  onChange(fromSpy, form) {
    const { values } = fromSpy;
    const { firstName, lastName } = values;
    const username =
      firstName && lastName
        ? `${firstName} ${lastName[0]}.`
        : firstName
        ? firstName
        : lastName
        ? `${lastName[0]}.`
        : null;

    if (username) form.change('username', username);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            inProgress,
            values,
            pristine,
            invalid,
            intl,
            photo,
            form,
            initialValues,
            errors,
            touched,
            onAccountTypeChange,
          } = fieldRenderProps;

          const badWordsForbidden = validators.forbidBadWords(
            intl.formatMessage({ id: 'SignupForm.badWordsError' })
          );

          // account type
          const accountTypeIndividualLabel = intl.formatMessage({
            id: 'SignupForm.accountTypeIndividualLabel',
          });
          const accountTypeBuinessLabel = intl.formatMessage({
            id: 'SignupForm.accountTypeBusinessLabel',
          });

          // firstName
          const firstNamePlaceholder = intl.formatMessage({
            id: 'SignupForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // lastName
          const lastNamePlaceholder = intl.formatMessage({
            id: 'SignupForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // business name
          const businessNamePlacholder = intl.formatMessage({
            id: 'SignupForm.businessNamePlaceholder',
          });
          const businessNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.businessNameRequired',
          });
          const businessNameRequired = validators.required(businessNameRequiredMessage);

          // username
          const userNamePlaceholder = intl.formatMessage({
            id: 'SignupForm.userNamePlaceholder',
          });
          const userNameSubline = intl.formatMessage({
            id: 'SignupForm.userNameSubline',
          });

          // date of birth
          const dayOfBirthPlaceholder = intl.formatMessage({
            id: 'SignupForm.dayOfBirthPlaceholder',
          });
          const monthOfBirthPlaceholder = intl.formatMessage({
            id: 'SignupForm.monthOfBirthPlaceholder',
          });
          const yearOfBirthPlaceholder = intl.formatMessage({
            id: 'SignupForm.yearOfBirthPlaceholder',
          });
          const dateOfBirthRequiredMessage = intl.formatMessage({
            id: 'SignupForm.dateOfBirthRequiredMessage',
          });
          const dateOfBirthSubline = intl.formatMessage({
            id: 'SignupForm.dateOfBirthSubline',
          });
          const dateOfBirthRequired = validators.required(dateOfBirthRequiredMessage);
          const currentMonths = MONTHS(values.birthYear || CURRENT_YEAR);
          const currentDays = DAYS(
            values.birthYear || CURRENT_YEAR,
            values.birthMonth || CURRENT_MONTH
          );

          // pronouns
          const pronounsPlaceholder = intl.formatMessage({
            id: 'SignupForm.pronounsPlaceholder',
          });
          const pronounsSubline = intl.formatMessage({
            id: 'SignupForm.pronounsSubline',
          });

          // business website
          const businessWebsitePlaceholder = intl.formatMessage({
            id: 'SignupForm.businessWebsitePlaceholder',
          });
          const businessWebsiteRequiredMessage = intl.formatMessage({
            id: 'SignupForm.businessWebsiteRequiredMessage',
          });
          const businessWebsiteRequired = validators.required(businessWebsiteRequiredMessage);

          // email
          const emailPlaceholder = intl.formatMessage({
            id: 'SignupForm.emailPlaceholder',
          });
          const emailSubline = intl.formatMessage({
            id: 'SignupForm.emailSubline',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'SignupForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          // phone number
          const phoneNumberPlaceholder = intl.formatMessage({
            id: 'SignupForm.phoneNumberPlaceholder',
          });
          const phoneNumberRequiredMessage = intl.formatMessage({
            id: 'SignupForm.phoneNumberRequired',
          });
          const phoneNumberSubline = intl.formatMessage({
            id: 'SignupForm.phoneNumberSubline',
          });
          const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);

          // password
          const passwordPlaceholder = intl.formatMessage({
            id: 'SignupForm.passwordPlaceholder',
          });
          const verifyPasswordPlaceholder = intl.formatMessage({
            id: 'SignupForm.verifyPasswordPlaceholder',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'SignupForm.passwordRequired',
          });
          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );
          const passwordMaxLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.passwordTooLong',
            },
            {
              maxLength: validators.PASSWORD_MAX_LENGTH,
            }
          );
          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );
          const passwordMaxLength = validators.maxLength(
            passwordMaxLengthMessage,
            validators.PASSWORD_MAX_LENGTH
          );
          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
          const passwordValidators = validators.composeValidators(
            passwordRequired,
            passwordMinLength,
            passwordMaxLength
          );
          const passwordMissmatchText = intl.formatMessage({ id: 'SignupForm.passwordMissmatch' });
          const passwordMatch = values.passwordVerified
            ? values.password === values.passwordVerified
            : true;

          const zipCodePlaceholder = intl.formatMessage({ id: 'SignupForm.zipCodePlaceholder' });
          const zipCodeRequiredMessage = intl.formatMessage({ id: 'SignupForm.zipCodeRequired' });
          const zipCodeValidators = validators.required(zipCodeRequiredMessage);

          // privacy
          const termsOfUseLink = (
            <NamedLink key="userAgreement" name="TermsOfServicePage" target="_blank">
              {intl.formatMessage({ id: 'SignupForm.termsOfUse' })}
            </NamedLink>
          );
          const privacyPolicyLink = (
            <NamedLink key="privacyPolicy" name="PrivacyPolicyPage" target="_blank">
              {intl.formatMessage({ id: 'SignupForm.privacyPolicy' })}
            </NamedLink>
          );
          const securityDisclosureLink = (
            <NamedLink key="securityDisclosure" name="SecurityPage" target="_blank">
              {intl.formatMessage({ id: 'SignupForm.securityDisclosure' })}
            </NamedLink>
          );
          const termsLabel = intl.formatMessage(
            {
              id: 'SignupForm.termsLabel',
            },
            {
              termsOfUseLink,
              privacyPolicyLink,
              securityDisclosureLink,
            }
          );
          const personalizedEmailsLabel = intl.formatMessage({
            id: 'SignupForm.personalizedEmailsLabel',
          });

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled =
            invalid ||
            submitInProgress ||
            (values.termsAndPrivacy ? !values.termsAndPrivacy.length > 0 : true) ||
            !values.accountType ||
            !passwordMatch;

          const individual = values.accountType === 'Individual';

          const birthDayFieldMeta = {
            touched: touched.birthDay && touched.birthMonth && touched.birthYear,
            error: errors.birthDay || errors.birthMonth || errors.birthYear,
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <div>
                  <h3 className={css.stepTitle}>
                    <FormattedMessage id="SignupForm.accountTypeTitle" />
                  </h3>
                  <FieldRadioButton
                    id="accountType.Individual"
                    name="accountType"
                    label={accountTypeIndividualLabel}
                    value="Individual"
                    checkedClassName={css.checked}
                    showAsRequired={pristine}
                    onChange={e => onAccountTypeChange(e.target.value)}
                  />
                  <FieldRadioButton
                    id="accountType.Business"
                    name="accountType"
                    label={accountTypeBuinessLabel}
                    value="Business"
                    checkedClassName={css.checked}
                    showAsRequired={pristine}
                    onChange={e => onAccountTypeChange(e.target.value)}
                  />
                </div>
                <h3 className={css.stepTitle}>
                  <FormattedMessage id="SignupForm.stepTitle" />
                </h3>
                <div className={css.description}>
                  <FormattedMessage id="SignupForm.stepDescription" />
                </div>
                {individual ? (
                  <div className={css.row}>
                    <FieldTextInput
                      inputRootClass={classNames(css.input, css.firstNameRoot)}
                      rootClassName={css.inputMinWidth}
                      type="text"
                      id="firstName"
                      name="firstName"
                      autoComplete="given-name"
                      placeholder={firstNamePlaceholder}
                      validate={validators.composeValidators(firstNameRequired, badWordsForbidden)}
                    />
                    <FieldTextInput
                      inputRootClass={classNames(css.input, css.lastNameRoot)}
                      rootClassName={css.inputMinWidth}
                      type="text"
                      id="lastName"
                      name="lastName"
                      autoComplete="family-name"
                      placeholder={lastNamePlaceholder}
                      validate={validators.composeValidators(lastNameRequired, badWordsForbidden)}
                    />
                  </div>
                ) : (
                  <div className={css.row}>
                    <FieldTextInput
                      inputRootClass={classNames(css.input, css.businessNameRoot)}
                      rootClassName={css.inputMinWidth}
                      type="text"
                      id="businessName"
                      name="businessName"
                      autoComplete="organization"
                      placeholder={businessNamePlacholder}
                      validate={validators.composeValidators(
                        businessNameRequired,
                        badWordsForbidden
                      )}
                    />
                  </div>
                )}

                <div className={css.row}>
                  <div className={css.column}>
                    {individual && (
                      <div>
                        {values.username ? (
                          <div className={css.username}>{values.username}</div>
                        ) : (
                          <div className={css.placeholder}>{userNamePlaceholder}</div>
                        )}
                        <FormSpy onChange={e => this.onChange(e, form)} />
                        <div className={css.subline}>{userNameSubline}</div>
                      </div>
                    )}
                    {individual ? (
                      <>
                        <div className={classNames(css.row, css.birthRow)}>
                          <FieldSelect
                            id="birthMonth"
                            name="birthMonth"
                            className={css.birthInput}
                            selectClassName={classNames(css.input, css.selectInput)}
                            validate={dateOfBirthRequired}
                            showValidationMessage={false}
                          >
                            <option value="" disabled>
                              {monthOfBirthPlaceholder}
                            </option>
                            {currentMonths.map(month => (
                              <option key={`month${month.key}`} value={month.key}>
                                {month.label}
                              </option>
                            ))}
                          </FieldSelect>
                          <FieldSelect
                            id="birthDay"
                            name="birthDay"
                            className={css.birthInput}
                            selectClassName={classNames(css.input, css.selectInput)}
                            validate={dateOfBirthRequired}
                            showValidationMessage={false}
                          >
                            <option value="" disabled>
                              {dayOfBirthPlaceholder}
                            </option>
                            {currentDays.map(day => (
                              <option key={`day${day}`} value={day}>
                                {day}
                              </option>
                            ))}
                          </FieldSelect>
                          <FieldSelect
                            id="birthYear"
                            name="birthYear"
                            className={css.birthInput}
                            selectClassName={classNames(css.input, css.selectInput)}
                            validate={dateOfBirthRequired}
                            showValidationMessage={false}
                          >
                            <option value="" disabled>
                              {yearOfBirthPlaceholder}
                            </option>
                            {YEARS.map(year => (
                              <option key={`year${year}`} value={year}>
                                {year}
                              </option>
                            ))}
                          </FieldSelect>
                        </div>
                        <ValidationError fieldMeta={birthDayFieldMeta} />
                        <div className={css.subline}>{dateOfBirthSubline}</div>
                      </>
                    ) : (
                      <div className={css.row}>
                        <FieldTextInput
                          inputRootClass={classNames(css.input, css.businessWebsiteRoot)}
                          rootClassName={css.inputMinWidth}
                          type="text"
                          id="businessWebsite"
                          name="businessWebsite"
                          autoComplete="business-website"
                          placeholder={businessWebsitePlaceholder}
                          validate={validators.composeValidators(
                            businessWebsiteRequired,
                            badWordsForbidden
                          )}
                        />
                      </div>
                    )}
                  </div>
                  {individual ? (
                    <div>
                      <FieldMultiselect
                        id="pronouns"
                        name="pronouns"
                        rootClassName={css.inputMinWidth}
                        className={css.pronouns}
                        label={pronounsPlaceholder}
                        options={Object.values(GENDER_PRONOUNS).map(g => ({ key: g, label: g }))}
                      />
                      <div className={css.pronounsSubline}>{pronounsSubline}</div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className={css.row}>
                  <div>
                    <FieldTextInput
                      inputRootClass={classNames(css.input, css.email)}
                      rootClassName={css.inputMinWidth}
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="email"
                      placeholder={emailPlaceholder}
                      disabled={initialValues.email}
                      validate={validators.composeValidators(emailRequired, emailValid)}
                    />
                    <div className={css.subline}>{emailSubline}</div>
                  </div>
                  <div>
                    <FieldPhoneNumberInput
                      inputRootClass={classNames(css.input, css.phoneNumber)}
                      rootClassName={css.inputMinWidth}
                      id="phoneNumber"
                      name="phoneNumber"
                      autoComplete="phone"
                      placeholder={phoneNumberPlaceholder}
                      validate={phoneNumberRequired}
                    />
                    <div className={css.sublineRight}>{phoneNumberSubline}</div>
                  </div>
                </div>
                <div>
                  <div className={css.row}>
                    <FieldTextInput
                      inputRootClass={classNames(css.input, css.password)}
                      rootClassName={css.inputMinWidth}
                      type="password"
                      id="password"
                      name="password"
                      autoComplete="current-password"
                      placeholder={passwordPlaceholder}
                      validate={passwordValidators}
                    />
                    <FieldTextInput
                      inputRootClass={classNames(css.input, css.verifyPassword)}
                      rootClassName={css.inputMinWidth}
                      type="password"
                      id="passwordVerified"
                      name="passwordVerified"
                      autoComplete="new-password"
                      placeholder={verifyPasswordPlaceholder}
                      validate={passwordValidators}
                    />
                  </div>
                  {individual && (
                    <>
                      <h3 className={css.stepTitle}>
                        <FormattedMessage id="SignupForm.interestsTitle" />
                      </h3>
                      <div className={css.description}>
                        <FormattedMessage id="SignupForm.interestsDescription" />
                      </div>

                      <FieldInterests name="interests" />
                    </>
                  )}

                  <ValidationError
                    className={css.passwordMissmatch}
                    fieldMeta={{
                      touched: true,
                      error: passwordMatch ? null : passwordMissmatchText,
                    }}
                  />
                </div>
                <div className={css.formRow}>
                  <FieldNumberInput
                    inputRootClass={classNames(css.input)}
                    rootClassName={css.inputMinWidth}
                    id="zipCode"
                    name="zipCode"
                    autoComplete="off"
                    placeholder={zipCodePlaceholder}
                    validate={zipCodeValidators}
                  />
                </div>
              </div>
              <div className={css.sectionImage}>
                {photo ? (
                  <ImageFromFile
                    aspectRatioClassName={css.imageRatio}
                    className={css.image}
                    id="profilePhoto"
                    file={photo}
                  />
                ) : (
                  <img className={css.image} src={imageImage} alt="default-avatar" />
                )}
                <div className={css.imageActions}>
                  <h3>
                    <FormattedMessage id="SignupForm.sectionImageTitle" />
                  </h3>
                  <Dropzone
                    dropzoneText={intl.formatMessage({ id: 'SignupForm.photoUpload' })}
                    accept="image/*"
                    multiple={false}
                    handleFiles={this.addImage}
                    className={css.button}
                  />
                  <PrimaryButton onClick={this.removeImage} className={css.button}>
                    <FormattedMessage id="SignupForm.photoRemove" />
                  </PrimaryButton>
                </div>
              </div>
              <div className={css.checkboxes}>
                <FieldCheckbox
                  key="termsAndPrivacy"
                  className={css.checkbox}
                  textClassName={css.checkboxLabel}
                  id="termsAndPrivacy"
                  name="termsAndPrivacy"
                  label={termsLabel}
                  value="termsAndPrivacy"
                  useSuccessColor
                />
                <FieldCheckbox
                  key="personalizedEmails"
                  className={css.checkbox}
                  textClassName={css.checkboxLabel}
                  id="personalizedEmails"
                  name="personalizedEmails"
                  label={personalizedEmailsLabel}
                  value="personalizedEmails"
                  useSuccessColor
                />
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  className={css.button}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SignupFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  // onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
