import React, { useState, useEffect, useMemo } from 'react';
import { bool, func, object, number, string, array } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { stringify } from '../../util/urlHelpers';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { LISTING_TYPE, DEFAULT_TITLE } from '../../constants';
import { createSlug } from '../../util/urlHelpers';

import css from './TopbarDesktop.module.css';
import NotificationBadge from '../NotificationBadge/NotificationBadge';

const TopbarDesktop = props => {
  const {
    history,
    className,
    currentUser,
    currentPage,
    rootClassName,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    currentUserExpiringBookings,
    currentUserPendingBookings,
    onCreateNewListing,
  } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      onSubmit={onSearchSubmit}
      shouldShowMarket={currentPage !== 'SearchPage'}
      initialValues={currentPage === 'WantedPage' ? {} : initialSearchFormValues}
    />
  );

  const currentPageClass = page => (currentPage === page ? css.currentPage : null);

  const notificationDot = useMemo(
    () =>
      currentUserExpiringBookings?.length > 0 || currentUserPendingBookings?.length ? (
        <div className={css.notificationDot} />
      ) : null,
    [currentUserExpiringBookings?.length, currentUserPendingBookings?.length]
  );

  const expiringBookingBadge = useMemo(
    () =>
      currentUserExpiringBookings?.length > 0 ? (
        <NotificationBadge
          className={css.expiringBookingBadge}
          count={currentUserExpiringBookings.length}
        />
      ) : null,
    [currentUserExpiringBookings?.length]
  );

  const pendingBookingBadge = useMemo(
    () =>
      currentUserPendingBookings?.length > 0 ? (
        <NotificationBadge
          className={css.expiringBookingBadge}
          count={currentUserPendingBookings.length}
        />
      ) : null,
    [currentUserPendingBookings?.length]
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu useArrow={false}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        {notificationDot}
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ChatPage">
          <NamedLink
            className={classNames(css.menuItem, css.menuItemBig, currentPageClass('ChatPage'))}
            name="ChatPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.messages" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyBorrowedItemsPage">
          <NamedLink
            className={classNames(
              css.menuItem,
              css.menuItemBig,
              currentPageClass('MyBorrowedItemsPage')
            )}
            name="MyBorrowedItemsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myBorrowedItems" />
            {expiringBookingBadge}
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyWantedPostsPage">
          <NamedLink
            className={classNames(
              css.menuItem,
              css.menuItemBig,
              currentPageClass('MyWantedPostsPage')
            )}
            name="MyWantedPostsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myWantedPosts" />
          </NamedLink>
          <hr />
        </MenuItem>
        <MenuItem key="LenderDashboardPage">
          <NamedLink
            className={classNames(
              css.menuItem,
              css.menuItemBig,
              currentPageClass('LenderDashboardPage')
            )}
            name="LenderDashboardPage"
            to={{ search: stringify({ dateRange: 'year' }) }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.lenderDashboard" />
            {pendingBookingBadge}
          </NamedLink>
        </MenuItem>
        <MenuItem key="NewListingPage">
          <span
            name="NewListingPage"
            className={classNames(css.menuItem, css.menuItemBig)}
            onClick={() =>
              onCreateNewListing({
                title: DEFAULT_TITLE,
                publicData: {
                  listingType: LISTING_TYPE.LENDER_POST,
                },
              }).then(res => {
                const { uuid } = res.data.data.id;
                const slug = createSlug(res.data.data.attributes.title);
                history.push(`/l/${slug}/${uuid}/draft/description`, {
                  claimedItemId: currentUser?.id?.uuid,
                });
              })
            }
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.listAnItem" />
          </span>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(
              css.menuItem,
              css.menuItemBig,
              currentPageClass('ManageListingsPage')
            )}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.manageListings" />
          </NamedLink>
          <hr />
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuItem, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="GetHelpPage">
          <NamedLink
            className={classNames(css.menuItem, currentPageClass('HelpPage'))}
            name="HelpPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.getHelp" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const learnMoreLink = (
    <NamedLink name="LearnMoreBasePage" className={css.learnmoreLink}>
      <span className={css.learnmore}>
        <FormattedMessage id="TopbarDesktop.learnmore" />
      </span>
    </NamedLink>
  );

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const createListingLink = (
    <span
      className={css.createListingLink}
      onClick={() =>
        onCreateNewListing({
          title: DEFAULT_TITLE,
          publicData: {
            listingType: LISTING_TYPE.LENDER_POST,
          },
        }).then(res => {
          const { uuid } = res.data.data.id;
          const slug = createSlug(res.data.data.attributes.title);
          history.push(`/l/${slug}/${uuid}/draft/description`, {
            claimedItemId: currentUser?.id?.uuid,
          });
        })
      }
    >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </span>
  );

  const wantedPageLink = authenticatedOnClientSide && (
    <NamedLink name="WantedPage" className={css.learnmoreLink}>
      <span className={css.learnmore}>
        <FormattedMessage id="TopbarDesktop.wantedPosts" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}
      {createListingLink}
      {wantedPageLink}
      {learnMoreLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  currentUserExpiringBookings: [],
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  currentUserExpiringBookings: array,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  onCreateNewListing: func.isRequired,
};

export default TopbarDesktop;
