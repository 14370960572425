import React from 'react';
import classNames from 'classnames';
import { string, bool } from 'prop-types';
import { NamedLink } from '../../components';
import { createSlug } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LISTING_TYPE } from '../../constants';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { locationBounds } from '../../util/maps';
import { stringifyDateToISO8601 } from '../../util/dates';

import defaultImage from '../../assets/LogoLight.png';
import tools from '../../assets/category-homepage-home-tools.jpg';
import office from '../../assets/category-homepage-office-electronics.jpg';
import sports from '../../assets/category-homepage-sporting.jpg';

import css from './LandingCard.module.css';

const { Money } = sdkTypes;
const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

const CURRENT_DATE = new Date();
const date = new Date()
const nextDay = date.setDate(date.getDate() +1)

const minPriceFirst = (p1, p2) => p1.priceValue - p2.priceValue;

const getSmallestPrice = (pricePerRate, defaultPrice) => {
  const pricePerRateArray = Object.entries(pricePerRate || {})
    .map(([priceType, priceValue]) => ({ priceType, priceValue }))
    .sort(minPriceFirst);

  if (pricePerRateArray.length === 0)
    return {
      unitPrice: new Money(defaultPrice.amount, defaultPrice.currency),
      unitTranslationKey: 'LandingCard.pricePerDay',
    };

  const smallestPrice = pricePerRateArray[0];

  return {
    unitPrice: new Money(smallestPrice.priceValue, defaultPrice.currency),
    unitTranslationKey: `LandingCard.${smallestPrice.priceType}`,
  };
};

const LandingCard = props => {
  const { intl, rootClassName, className, listing, isCategory, userLatLng } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (isCategory) {
    const { _id, nearbyCount, minPrice } = listing;
    const categoryLabel = categoryOptions.find(c => c.key === _id);

    const bounds = locationBounds(userLatLng, config.maps.search.currentLocationBoundsDistance);
    const startDate = stringifyDateToISO8601(CURRENT_DATE);
    const endDate = stringifyDateToISO8601(nextDay);
    const search = userLatLng
      ? `bounds=${bounds?.ne?.lat}%2C${bounds?.ne?.lng}%2C${bounds?.sw?.lat}%2C${bounds?.sw?.lng}&dates=${startDate}%2C${endDate}`
      : `address=Seattle%2C%20Washington%2C%20United%20States&bounds=47.7788030383864%2C-122.218864003193%2C47.3506859580852%2C-122.435900673909&dates=${startDate}%2C${endDate}`;

    const formattedPrice = minPrice
      ? formatMoney(intl, new Money(minPrice, config.currency), 0)
      : null;

    //Set category image on homepage based on categorylabel.key
    const category = categoryLabel?.key;
    var categoryimage;

    if (category === 'officeAndElectronics') {
      categoryimage = office;
    } else if (category === 'homeGardenTools') {
      categoryimage = tools;
    } else if (category === 'sportingEquipmentAndFitness') {
      categoryimage = sports;
    } else {
      categoryimage = defaultImage;
    }
    return (
      <NamedLink
        name="SearchPage"
        to={{ search: `${search}&pub_category=${categoryLabel?.key}` }}
        className={classes}
      >
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <img
              className={classNames(css.rootForImage, css.defaultImage)}
              src={categoryimage}
              alt="listingImage"
            />
          </div>
        </div>
        <div className={css.textContainer}>
          <div className={css.mainText}>
            <div className={css.listingTitle}>{categoryLabel ? categoryLabel.label : null}</div>
            <div className={css.listingCategory}>
              <FormattedMessage id="LandingCard.categoryCount" values={{ nearbyCount }} />
            </div>
          </div>
          <div className={css.price}>
            {formattedPrice && (
              <FormattedMessage
                id="LandingCard.categoryItemPrice"
                values={{ price: formattedPrice, lendingType: 'hour' }}
              />
            )}
          </div>
        </div>
      </NamedLink>
    );
  } else {
    const { image, sharetribeId, title, price: noTypePrice, publicData } = listing;
    const { pricePerRate } = publicData || {};

    const { unitPrice, unitTranslationKey } = getSmallestPrice(pricePerRate, noTypePrice);

    const { category, listingType, borrowingType } = publicData;
    const categoryLabel = categoryOptions.find(c => c.key === category);

    const slug = createSlug(title);

    const imageVariants = image && image.variants && Object.keys(image.variants);
    const listingImage = imageVariants && imageVariants[0] && image.variants[imageVariants[0]].url;

    const formattedPrice = unitPrice ? formatMoney(intl, unitPrice, 0) : null;

    return (
      <NamedLink name="ListingPage" params={{ id: sharetribeId, slug }} className={classes}>
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <img
              className={classNames(css.rootForImage, {
                [css.defaultImage]: !listingImage && true,
              })}
              src={listingImage ? listingImage : defaultImage}
              alt="listingImage"
            />
          </div>
        </div>
        <div className={css.textContainer}>
          <div className={css.mainText}>
            <div className={css.listingTitle}>{title}</div>
            <div className={css.listingCategory}>{categoryLabel ? categoryLabel.label : null}</div>
          </div>
          <div className={css.price}>
            {listingType === LISTING_TYPE.WANTED_POST ? (
              <FormattedMessage
                id="LandingCard.wantedItemPrice"
                values={{
                  price: formattedPrice,
                  borrowingType:
                    borrowingType === 'hourly' ? (
                      <FormattedMessage id="LandingCard.pricePerHour" />
                    ) : borrowingType === 'daily' ? (
                      <FormattedMessage id="LandingCard.pricePerDay" />
                    ) : (
                      <FormattedMessage id="LandingCard.pricePerWeek" />
                    ),
                }}
              />
            ) : (
              <FormattedMessage
                id="LandingCard.lenderItemPrice"
                values={{
                  price: formattedPrice,
                  lendingType: <FormattedMessage id={unitTranslationKey} />,
                }}
              />
            )}
          </div>
        </div>
      </NamedLink>
    );
  }
};

LandingCard.defaultProps = {
  rootClassName: null,
  className: null,
  isCategory: false,
};

LandingCard.propTypes = {
  rootClassName: string,
  className: string,
  isCategory: bool,
};

export default LandingCard;
