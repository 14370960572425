import React from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { intlShape } from '../../util/reactIntl';
import { required } from '../../util/validators';
import { Form, FieldTextInput, PrimaryButton } from '../../components';

import css from './PromoCodeForm.module.css';

const PromoCodeForm = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        intl,
        invalid,
        disabled,
        handleSubmit,
        addPromoCodeError,
        inProgress,
        ready,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);

      const submitDisabled = invalid || disabled || inProgress;

      const codePlaceholderMessage = intl.formatMessage({ id: 'PromoCodeForm.codePlaceholder' });
      const codeRequiredMessage = intl.formatMessage({ id: 'PromoCodeForm.codeRequired' });
      const codeRequired = required(codeRequiredMessage);

      const submitButtonText = intl.formatMessage({ id: 'PromoCodeForm.submitButtonText' });
      const submitError = intl.formatMessage({ id: 'PromoCodeForm.submitError' });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.container}>
            <div className={css.formRow}>
              <FieldTextInput
                className={css.input}
                id="code"
                name="code"
                type="text"
                placeholder={codePlaceholderMessage}
                validate={codeRequired}
              />
              <PrimaryButton
                inProgress={inProgress}
                ready={ready}
                className={css.button}
                disabled={submitDisabled}
                type="submit"
              >
                {submitButtonText}
              </PrimaryButton>
            </div>
            {addPromoCodeError && <div className={css.error}>{submitError}</div>}
          </div>
        </Form>
      );
    }}
  />
);

PromoCodeForm.defaultProps = {
  rootClassName: null,
  className: null,
  addPromoCodeError: null,
};

PromoCodeForm.propTypes = {
  rootClassName: string,
  className: string,

  intl: intlShape.isRequired,

  inProgress: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
};

export default PromoCodeForm;
