import api from '../../api';

// ================ Action types ================ //

export const CONTACT_US_REQUEST = 'app/HelpPage/CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'app/HelpPage/CONTACT_US_SUCCESS';
export const CONTACT_US_ERROR = 'app/HelpPage/CONTACT_US_ERROR';
export const SET_INITIAL_STATE = 'app/HelpPage/SET_INITIAL_STATE';

// ================ Reducer ================ //

const initialState = {
  contactUsInProgress: false,
  contactUsSent: false,
  contactUsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case CONTACT_US_REQUEST:
      return { ...state, contactUsInProgress: true, contactUsSent: false, contactUsError: null };
    case CONTACT_US_SUCCESS:
      return { ...state, contactUsInProgress: false, contactUsSent: true };
    case CONTACT_US_ERROR:
      console.error(payload);
      return { ...state, contactUsInProgress: false, contactUsError: payload };
    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const contactUsRequest = () => ({ type: CONTACT_US_REQUEST });
export const contactUsSuccess = () => ({ type: CONTACT_US_SUCCESS });
export const contactUsError = e => ({ type: CONTACT_US_ERROR, payload: e });

export const setInitialState = () => ({ type: SET_INITIAL_STATE });

// ================ Thunk ================ //

export const contactUs = ({ contactEmail, issueType, issue }) => (dispatch, getState, sdk) => {
  dispatch(contactUsRequest());

  return api.users
    .contactUs({ contactEmail, issueType, issue })
    .then(() => dispatch(contactUsSuccess()))
    .catch(e => {
      dispatch(contactUsError(e));
      throw e;
    });
};
