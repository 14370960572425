import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AccountSettingsPage = loadable(() => import(/* webpackChunkName: "AccountSettingsPage" */ './containers/AccountSettingsPage/AccountSettingsPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const HelpPage = loadable(() => import(/* webpackChunkName: "HelpPage" */ './containers/HelpPage/HelpPage'));
const ChatPage = loadable(() => import(/* webpackChunkName: "ChatPage" */ './containers/ChatPage/ChatPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const LearnMorePage = loadable(() => import(/* webpackChunkName: "LearnMorePage" */ './containers/LearnMorePage/LearnMorePage'));
const LenderDashboardPage = loadable(() => import(/* webpackChunkName: "LenderDashboardPage" */ './containers/LenderDashboardPage/LenderDashboardPage'));
const LoginPage = loadable(() => import(/* webpackChunkName: "LoginPage" */ './containers/LoginPage/LoginPage'));
const LoginEmailPage = loadable(() => import(/* webpackChunkName: "LoginEmailPage" */ './containers/LoginEmailPage/LoginEmailPage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
// const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const ReviewPage = loadable(() => import(/* webpackChunkName: "ReviewPage" */ './containers/ReviewPage/ReviewPage'));
const SecurityPage = loadable(() => import(/* webpackChunkName: "SecurityPage" */ './containers/SecurityPage/SecurityPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const SignupPageStepOne = loadable(() => import(/* webpackChunkName: "SignupPageStepOne" */ './containers/SignupPageStepOne/SignupPageStepOne'));
const SignupPageStepTwo = loadable(() => import(/* webpackChunkName: "SignupPageStepTwo" */ './containers/SignupPageStepTwo/SignupPageStepTwo'));
const SignupPageStepThree = loadable(() => import(/* webpackChunkName: "SignupPageStepThree" */ './containers/SignupPageStepThree/SignupPageStepThree'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ /* webpackPrefetch: true */ './containers/ManageListingsPage/ManageListingsPage'));
const WantedPage = loadable(() => import(/* webpackChunkName: "WantedPage" */ /* webpackPrefetch: true */ './containers/WantedPage/WantedPage'));
const WelcomePage = loadable(() => import(/* webpackChunkName: "WelcomePage" */ './containers/WelcomePage/WelcomePage'));
const MyWantedPostsPage = loadable(() => import(/* webpackChunkName: "MyWantedPostsPage" */ /* webpackPrefetch: true */ './containers/MyWantedPostsPage/MyWantedPostsPage'));
const MyBorrowedItemsPage = loadable(() => import(/* webpackChunkName: "MyBorrowedItemsPage" */ /* webpackPrefetch: true */ './containers/MyBorrowedItemsPage/MyBorrowedItemsPage'));



// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/help',
      name: 'HelpPage',
      component: HelpPage,
    },
    {
      path: '/learn-more',
      name: 'LearnMoreBasePage',
      component: () => <NamedRedirect name="LearnMorePage" params={{ tab: 'borrow' }} />,
    },
    {
      path: '/learn-more/:tab',
      name: "LearnMorePage",
      component: LearnMorePage,
    },
    {
      path: '/wanted',
      name: 'WantedPage',
      auth: true,
      component: WantedPage,
    },
    {
      path: '/welcome',
      name: "WelcomePage",
      auth: true,
      component: WelcomePage
    },
    {
      path: '/review-success',
      name: "ReviewSuccessPage",
      auth: true,
      component: WelcomePage,
    },
    {
      path: '/review/:id',
      name: "ReviewPage",
      auth: true,
      component: ReviewPage,
      loadData: pageDataLoadingAPI.ReviewPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/dashboard',
      name: 'LenderDashboardPage',
      auth: true,
      component: LenderDashboardPage,
    },
    {
      path: '/my-borrowed-items',
      name: 'MyBorrowedItemsPage',
      auth: true,
      component: MyBorrowedItemsPage,
    },
    {
      path: '/manage-listings',
      name: 'ManageListingsPage',
      auth: true,
      component: ManageListingsPage,
    },
    {
      path: '/my-wanted-posts',
      name: 'MyWantedPostsPage',
      auth: true,
      component: MyWantedPostsPage,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    // {
    //   path: '/profile-settings',
    //   name: 'ProfileSettingsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: ProfileSettingsPage,
    // },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
    },
    {
      path: '/login-email',
      name: 'LoginEmailPage',
      component: LoginEmailPage,
    },
    {
      path: '/signup-step-1',
      name: 'SignupPageStepOne',
      component: SignupPageStepOne,
    },
    {
      path: '/signup-step-3',
      name: 'SignupPageStepThree',
      auth: true,
      component: SignupPageStepThree,
    },
    {
      path: '/signup-step-2',
      name: 'SignupPageStepTwo',
      auth: true,
      component: SignupPageStepTwo,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: SignupPageStepOne,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/messages',
      name: 'ChatPage',
      auth: true,
      authPage: 'LoginPage',
      component: ChatPage,
      loadData: pageDataLoadingAPI.ChatPage.loadData,
    },
    {
      path: '/messages/:id',
      name: 'ChatPage',
      auth: true,
      authPage: 'LoginPage',
      component: ChatPage,
      loadData: pageDataLoadingAPI.ChatPage.loadData,
    },
    // {
    //   path: '/inbox',
    //   name: 'InboxBasePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    // },
    // {
    //   path: '/inbox/:tab',
    //   name: 'InboxPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: InboxPage,
    //   loadData: pageDataLoadingAPI.InboxPage.loadData,
    // },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: AccountSettingsPage,
      loadData: pageDataLoadingAPI.AccountSettingsPage.loadData,
    },
     {   
       path: '/account/:returnURLType',
       name: 'AccountSettingsOnboardingPage',
       auth: true,
       authPage: 'LoginPage',
       component: AccountSettingsPage,
       loadData: pageDataLoadingAPI.AccountSettingsPage.loadData,
     },
    // {
    //   path: '/account/contact-details',
    //   name: 'ContactDetailsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: ContactDetailsPage,
    //   loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    // },
    // {
    //   path: '/account/change-password',
    //   name: 'PasswordChangePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: PasswordChangePage,
    // },
    // {
    //   path: '/account/payments',
    //   name: 'StripePayoutPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: StripePayoutPage,
    //   loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    // },
    // {
    //   path: '/account/payments/:returnURLType',
    //   name: 'StripePayoutOnboardingPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: StripePayoutPage,
    //   loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    // },
    // {
    //   path: '/account/payment-methods',
    //   name: 'PaymentMethodsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: PaymentMethodsPage,
    //   loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    // },
    {
      path: '/terms',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/security',
      name: 'SecurityPage',
      component: SecurityPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
