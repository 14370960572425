import React from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { Footer, NamedLink } from '../../components';

import css from './LandingFooter.module.css';

const LandingFooter = props => {
  const { className, rootClassName, isAuthenticated } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.linksRow}>
        <div className={css.linksColumn}>
          <h2 className={css.linksTitle}>
            <FormattedMessage id="LandingFooter.community" />
          </h2>
          {!isAuthenticated && (
            <NamedLink name="LoginPage" className={css.link}>
              <FormattedMessage id="LandingFooter.signUp" />
            </NamedLink>
          )}
          <NamedLink
            name="SearchPage"
            to={{
              search: `address=Seattle%2C%20Washington%2C%20United%20States&bounds=47.7788030383864%2C-122.218864003193%2C47.3506859580852%2C-122.435900673909`,
            }}
            className={css.link}
          >
            <FormattedMessage id="LandingFooter.borrow" />
          </NamedLink>
          <NamedLink name="WantedPage" className={css.link}>
            <FormattedMessage id="LandingFooter.wantedBoard" />
          </NamedLink>
          <NamedLink name="AboutPage" className={css.link}>
            <FormattedMessage id="LandingFooter.aboutUs" />
          </NamedLink>
        </div>
        <div className={css.linksColumn}>
          <h2 className={css.linksTitle}>
            <FormattedMessage id="LandingFooter.lenders" />
          </h2>
          <NamedLink name="AccountSettingsPage" className={css.link}>
            <FormattedMessage id="LandingFooter.myAccount" />
          </NamedLink>
          <NamedLink name="LenderDashboardPage" className={css.link}>
            <FormattedMessage id="LandingFooter.lenderDashboard" />
          </NamedLink>
          <NamedLink name="ManageListingsPage" className={css.link}>
            <FormattedMessage id="LandingFooter.manageListings" />
          </NamedLink>
        </div>
        <div className={css.linksColumn}>
          <h2 className={css.linksTitle}>
            <FormattedMessage id="LandingFooter.support" />
          </h2>
          <NamedLink name="LearnMoreBasePage" className={css.link}>
            <FormattedMessage id="LandingFooter.learnMore" />
          </NamedLink>
          <NamedLink name="HelpPage" className={css.link}>
            <FormattedMessage id="LandingFooter.getHelp" />
          </NamedLink>
          <NamedLink name="TermsOfServicePage" className={css.link} target="_blank">
            <FormattedMessage id="LandingFooter.termsOfUse" />
          </NamedLink>
          <NamedLink name="SecurityPage" className={css.link} target="_blank">
            <FormattedMessage id="LandingFooter.security" />
          </NamedLink>
          <NamedLink name="PrivacyPolicyPage" className={css.link} target="_blank">
            <FormattedMessage id="LandingFooter.privacy" />
          </NamedLink>
        </div>
      </div>
      <Footer rootClassName={css.footerRoot} onLanding />
    </div>
  );
};

LandingFooter.defaultProps = {
  className: null,
  rootClassName: null,
};

LandingFooter.propTypes = {
  className: string,
  rootClassName: string,
  isAuthenticated: bool.isRequired,
};

export default LandingFooter;
