import React from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, PrimaryButton, NamedLink, AvatarMedium, } from '../../components';
import { required } from '../../util/validators';

import css from './ReportIssueForm.module.css';

const ReportAnIssueForm = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        intl,
        className,
        rootClassName,
        invalid,
        disabled,
        reportingInProgress,
        reportIssueSubmitted,
        reportingIssueError,
        handleSubmit,
        saveActionMsg,
        title,
        inputPlaceholderMessage,
        onClose,
        listerName,
        listingTitle,
        author
      } = formRenderProps;

      const textRequiredMessage = intl.formatMessage({ id: 'ReportIssueForm.textRequiredMessage' });
      const textConfirmationMessage = intl.formatMessage({ id: 'WantedTable.confirmationModal' });
      const textRequired = required(textRequiredMessage);

      const error = intl.formatMessage({ id: 'ReportIssueForm.submitError' });

      const submitDisabled = invalid || disabled || reportingInProgress;

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h1 className={css.title}>{title}</h1>

          {listerName !== undefined ? <div>
            <div className={css.lenderInfo}>
              <span>
                <AvatarMedium user={author} disableProfileLink />
              </span>
              <span className={css.lenderName}>
                <NamedLink
                  name="ProfilePage"
                  params={{ id: author.id.uuid }} >
                  {listerName}
                </NamedLink>
              </span>
            </div>
            <p>Send a message about {listingTitle}</p>
          </div>
            : null}

          <FieldTextInput
            id="text"
            name="text"
            type="textarea"
            placeholder={inputPlaceholderMessage}
            inputRootClass={css.textarea}
            validate={textRequired}
            reportIssueSubmitted={reportIssueSubmitted}
          />

          {reportIssueSubmitted && <div>{textConfirmationMessage}</div>}
          {reportingIssueError && <div className={css.error}>{error}</div>}

          <PrimaryButton
            ready={reportIssueSubmitted}
            inProgress={reportingInProgress}
            type="primary"
            className={css.submitButton}
            disabled={submitDisabled}
            onClick={e => reportIssueSubmitted ? onClose() : null}
          >
            {saveActionMsg}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ReportAnIssueForm.defaultProps = {
  className: null,
  rootClassName: null,
  reportingIssueError: null,
  confirmationMessage: null,
};

ReportAnIssueForm.propTypes = {
  className: string,
  rootClassName: string,
  reportingInProgress: bool.isRequired,
  reportIssueSubmitted: bool.isRequired,
  onSubmit: func.isRequired,
};

export default ReportAnIssueForm;
