import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearch.module.css';

const IconSearch = props => {
  const { rootClassName, className } = props;
  const circleClasses = classNames(rootClassName || css.iconRoot, className);
  const lineClasses = classNames(rootClassName || css.iconRoot, className, css.lineMargins);
  return (
    <div className={css.container}>
      <svg
        className={circleClasses}
        version="1.2"
        baseProfile="tiny-ps"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 24"
        width="32"
        height="36"
      >
        <path
          id="Path 1"
          d="M22.39 12.03C22.39 17.43 18.01 21.82 12.6 21.82C7.2 21.82 2.81 17.43 2.81 12.03C2.81 6.62 7.2 2.24 12.6 2.24C18.01 2.24 22.39 6.62 22.39 12.03Z"
        />
      </svg>
      <svg
        className={lineClasses}
        version="1.2"
        baseProfile="tiny-ps"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 11"
        width="10"
        height="11"
      >
        <path d="M7.84 8.26L2.52 2.94" />
      </svg>
    </div>
  );
};

IconSearch.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearch.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearch;
