import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import {
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_CANCEL_AS_PROVIDER,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_UPDATE_BOOKING,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_CANCEL_AS_CUSTOMER,
  TRANSITION_CANCEL_AS_OPERATOR,
  TRANSITION_CANCEL_REQUEST,
  TRANSITION_EXPIRE,
} from '../../util/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getYearStartInTimeZone, getYearEndInTimeZone } from '../../util/dates';
import api from '../../api';

const { UUID } = sdkTypes;
const currentDate = new Date();
const today = new Date(currentDate.setHours(0, 0, 0, 0));
const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1));
const currentYearStartDate = getYearStartInTimeZone(today);
const currentYearEndDate = getYearEndInTimeZone(today);
const CURRENT_YEAR_TIME_RANGE = {
  $gt: currentYearStartDate.toString(),
  $lt: currentYearEndDate.toString(),
};
const TIME_RANGES = {
  month: {
    start: moment(today)
      .subtract(30, 'days')
      .toDate(),
    end: today,
  },
  '3months': {
    start: moment(today)
      .subtract(90, 'days')
      .toDate(),
    end: today,
  },
  year: {
    start: currentYearStartDate,
    end: currentYearEndDate,
  },
};
const PAGE_LIMIT = 5;
const completedTransitions = [
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CANCEL_REQUEST,
  TRANSITION_CANCEL_AS_PROVIDER,
  TRANSITION_CANCEL_AS_OPERATOR,
  TRANSITION_CANCEL_AS_CUSTOMER,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
];
const bookedTransitions = [TRANSITION_ACCEPT, TRANSITION_UPDATE_BOOKING];

// ================ Action types ================ //

export const FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_REQUEST =
  'app/LenderDashboardPage/FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_REQUEST';
export const FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_SUCCESS =
  'app/LenderDashboardPage/FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_SUCCESS';
export const FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_ERROR =
  'app/LenderDashboardPage/FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_ERROR';

export const FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_REQUEST =
  'app/LenderDashboardPage/FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_REQUEST';
export const FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_SUCCESS =
  'app/LenderDashboardPage/FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_SUCCESS';
export const FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_ERROR =
  'app/LenderDashboardPage/FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_ERROR';

export const FETCH_PENDING_TRANSACTIONS_REQUEST =
  'app/LenderDashboardPage/FETCH_PENDING_TRANSACTIONS_REQUEST';
export const FETCH_PENDING_TRANSACTIONS_SUCCESS =
  'app/LenderDashboardPage/FETCH_PENDING_TRANSACTIONS_SUCCESS';
export const FETCH_PENDING_TRANSACTIONS_ERROR =
  'app/LenderDashboardPage/FETCH_PENDING_TRANSACTIONS_ERROR';

export const FETCH_ACTIVE_TRANSACTIONS_REQUEST =
  'app/LenderDashboardPage/FETCH_ACTIVE_TRANSACTIONS_REQUEST';
export const FETCH_ACTIVE_TRANSACTIONS_SUCCESS =
  'app/LenderDashboardPage/FETCH_ACTIVE_TRANSACTIONS_SUCCESS';
export const FETCH_ACTIVE_TRANSACTIONS_ERROR =
  'app/LenderDashboardPage/FETCH_ACTIVE_TRANSACTIONS_ERROR';

export const FETCH_BOOKED_TRANSACTIONS_REQUEST =
  'app/LenderDashboardPage/FETCH_BOOKED_TRANSACTIONS_REQUEST';
export const FETCH_BOOKED_TRANSACTIONS_SUCCESS =
  'app/LenderDashboardPage/FETCH_BOOKED_TRANSACTIONS_SUCCESS';
export const FETCH_BOOKED_TRANSACTIONS_ERROR =
  'app/LenderDashboardPage/FETCH_BOOKED_TRANSACTIONS_ERROR';

export const FETCH_COMPLETED_TRANSACTIONS_REQUEST =
  'app/LenderDashboardPage/FETCH_COMPLETED_TRANSACTIONS_REQUEST';
export const FETCH_COMPLETED_TRANSACTIONS_SUCCESS =
  'app/LenderDashboardPage/FETCH_COMPLETED_TRANSACTIONS_SUCCESS';
export const FETCH_COMPLETED_TRANSACTIONS_ERROR =
  'app/LenderDashboardPage/FETCH_COMPLETED_TRANSACTIONS_ERROR';

export const OPEN_CONFIRM_MODAL = 'app/LenderDashboardPage/OPEN_CONFIRM_MODAL';
export const CLOSE_CONFIRM_MODAL = 'app/LenderDashboardPage/CLOSE_CONFIRM_MODAL';

export const ACCEPT_SALE_REQUEST = 'app/LenderDashboardPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/LenderDashboardPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/LenderDashboardPage/ACCEPT_SALE_ERROR';

export const DENY_SALE_REQUEST = 'app/LenderDashboardPage/DENY_SALE_REQUEST';
export const DENY_SALE_SUCCESS = 'app/LenderDashboardPage/DENY_SALE_SUCCESS';
export const DENY_SALE_ERROR = 'app/LenderDashboardPage/DENY_SALE_ERROR';

export const CANCEL_BOOKED_REQUEST = 'app/LenderDashboardPage/CANCEL_BOOKED_REQUEST';
export const CANCEL_BOOKED_SUCCESS = 'app/LenderDashboardPage/CANCEL_BOOKED_SUCCESS';
export const CANCEL_BOOKED_ERROR = 'app/LenderDashboardPage/CANCEL_BOOKED_ERROR';

export const OPEN_EXTEND_MODAL = 'app/LenderDashboardPage/OPEN_EXTEND_MODAL';
export const CLOSE_EXTEND_MODAL = 'app.LenderDashboardPage/CLOSE_EXTEND_MODAL';

export const SEND_EXTEND_REQUEST = 'app/LenderDashboardPage/SEND_EXTEND_REQUEST';
export const SEND_EXTEND_SUCCESS = 'app/LenderDashboardPage/SEND_EXTEND_SUCCESS';
export const SEND_EXTEND_ERROR = 'app/LenderDashboardPage/SEND_EXTEND_ERROR';

export const OPEN_ISSUE_MODAL = 'app/LenderDashboardPage/OPEN_ISSUE_MODAL';
export const CLOSE_ISSUE_MODAL = 'app/LenderDashboardPage/CLOSE_ISSUE_MODAL';

export const REPORT_ISSUE_REQUEST = 'app/LenderDashboardPage/REPORT_ISSUE_REQUEST';
export const REPORT_ISSUE_SUCCESS = 'app/LenderDashboardPage/REPORT_ISSUE_SUCCESS';
export const REPORT_ISSUE_ERROR = 'app/LenderDashboardPage/REPORT_ISSUE_ERROR';

// ================ Reducers ================ //

const initialState = {
  fetchCompletedTransactionsWeekCountInProgress: false,
  fetchCompletedTransactionsWeekCountError: null,
  completedTransactionsWeekCount: 0,
  fetchBookedTransactionsWeekCountInProgress: false,
  fetchBookedTransactionsWeekCountError: null,
  bookedTransactionsWeekCount: 0,
  fetchPendingTransactionsInProgress: false,
  fetchPendingTransactionsError: null,
  pendingTransactionsPagination: null,
  pendingTransactions: [],
  fetchActiveTransactionsInProgress: false,
  fetchActiveTransactionsError: null,
  activeTransactionsPagination: null,
  activeTransactions: [],
  fetchBookedTransactionsInProgress: false,
  fetchBookedTransactionsError: null,
  bookedTransactionsPagination: null,
  bookedTransactions: [],
  fetchCompletedTransactionsInProgress: false,
  fetchCompletedTransactionsError: null,
  completedTransactionsPagination: null,
  completedTransactions: [],
  isConfirmModalOpen: false,
  onConfirmModal: null,
  confirmModalTitle: '',
  confirmModalButtonText: '',
  acceptInProgress: false,
  acceptInProgressTransactionId: null,
  acceptSaleError: null,
  denyInProgress: false,
  denyInProgressTransactionId: null,
  denySaleError: null,
  cancelInProgress: false,
  cancelInProgressTransactionId: null,
  cancelError: null,
  isExtendModalOpen: false,
  transactionIdToExtend: null,
  sendExtendInProgress: false,
  sendExtendSubmitted: false,
  sendExtendError: null,
  reportIssueTransactionId: null,
  reportIssueInProgress: false,
  reportIssueSubmitted: false,
  reportIssueError: null,
};

export default function lenderDashboardReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_REQUEST:
      return {
        ...state,
        fetchCompletedTransactionsWeekCountInProgress: true,
        fetchCompletedTransactionsWeekCountError: null,
      };
    case FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_SUCCESS:
      return {
        ...state,
        fetchCompletedTransactionsWeekCountInProgress: false,
        completedTransactionsWeekCount: payload,
      };
    case FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchCompletedTransactionsWeekCountInProgress: false,
        fetchCompletedTransactionsWeekCountError: payload,
      };

    case FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_REQUEST:
      return {
        ...state,
        fetchBookedTransactionsWeekCountInProgress: true,
        fetchBookedTransactionsWeekCountError: null,
      };
    case FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_SUCCESS:
      return {
        ...state,
        fetchBookedTransactionsWeekCountInProgress: false,
        bookedTransactionsWeekCount: payload,
      };
    case FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchBookedTransactionsWeekCountInProgress: false,
        fetchBookedTransactionsWeekCountError: payload,
      };

    case FETCH_PENDING_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchPendingTransactionsInProgress: true,
        fetchPendingTransactionsError: null,
      };
    case FETCH_PENDING_TRANSACTIONS_SUCCESS: {
      const { page, totalPages, items } = payload.data;

      return {
        ...state,
        fetchPendingTransactionsInProgress: false,
        pendingTransactions: items,
        pendingTransactionsPagination: {
          page: page,
          totalPages: totalPages,
          perPage: PAGE_LIMIT,
        },
      };
    }
    case FETCH_PENDING_TRANSACTIONS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchPendingTransactionsInProgress: false,
        fetchPendingTransactionsError: payload,
      };

    case FETCH_ACTIVE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchActiveTransactionsInProgress: true,
        fetchActiveTransactionsError: null,
      };
    case FETCH_ACTIVE_TRANSACTIONS_SUCCESS: {
      const { items, currentPage, totalPages } = payload.data;

      return {
        ...state,
        fetchActiveTransactionsInProgress: false,
        activeTransactions: items,
        activeTransactionsPagination: {
          page: currentPage,
          totalPages: totalPages,
          perPage: PAGE_LIMIT,
        },
      };
    }
    case FETCH_ACTIVE_TRANSACTIONS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchActiveTransactionsInProgress: false,
        fetchActiveTransactionsError: payload,
      };

    case FETCH_BOOKED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchBookedTransactionsInProgress: true,
        fetchBookedTransactionsError: null,
      };
    case FETCH_BOOKED_TRANSACTIONS_SUCCESS: {
      const { items, currentPage, totalPages } = payload.data;

      return {
        ...state,
        fetchBookedTransactionsInProgress: false,
        bookedTransactions: items,
        bookedTransactionsPagination: {
          page: currentPage,
          totalPages: totalPages,
          perPage: PAGE_LIMIT,
        },
      };
    }
    case FETCH_BOOKED_TRANSACTIONS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchBookedTransactionsInProgress: false,
        fetchBookedTransactionsError: payload,
      };

    case FETCH_COMPLETED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchCompletedTransactionsInProgress: true,
        fetchCompletedTransactionsError: null,
      };
    case FETCH_COMPLETED_TRANSACTIONS_SUCCESS: {
      const { items, currentPage, totalPages, totalItems } = payload.response.data;

      return {
        ...state,
        fetchCompletedTransactionsInProgress: false,
        completedTransactions: items,
        completedTransactionsPagination: {
          page: currentPage,
          totalPages: totalPages,
          totalItems: totalItems,
          perPage: PAGE_LIMIT,
        },
      };
    }
    case FETCH_COMPLETED_TRANSACTIONS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchCompletedTransactionsInProgress: false,
        fetchCompletedTransactionsError: payload,
      };

    case OPEN_CONFIRM_MODAL:
      const { onConfirm, titleText, buttonText } = payload;
      return {
        ...state,
        isConfirmModalOpen: true,
        onConfirmModal: onConfirm,
        confirmModalTitle: titleText,
        confirmModalButtonText: buttonText,
        acceptReady: false,
        denyReady: false,
        cancelReady: false,
      };
    case CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        isConfirmModalOpen: false,
        onConfirmModal: null,
        confirmModalTitle: '',
        confirmModalButtonText: '',
      };

    case ACCEPT_SALE_REQUEST:
      return {
        ...state,
        acceptInProgress: true,
        acceptReady: false,
        acceptInProgressTransactionId: payload,
        acceptSaleError: null,
      };
    case ACCEPT_SALE_SUCCESS: {
      const bookedTransaction = state.pendingTransactions.find(tx => tx.sharetribeId === payload);
      return {
        ...state,
        acceptInProgress: false,
        acceptReady: true,
        acceptInProgressTransactionId: null,
        pendingTransactions: state.pendingTransactions.filter(
          transaction => transaction.sharetribeId !== payload
        ),
        bookedTransitions: [...state.bookedTransactions, bookedTransaction],
      };
    }
    case ACCEPT_SALE_ERROR:
      console.error(payload);
      return {
        ...state,
        acceptInProgress: false,
        acceptSaleError: payload,
      };

    case DENY_SALE_REQUEST:
      return {
        ...state,
        denyInProgress: true,
        denyReady: false,
        denyInProgressTransactionId: payload,
        denySaleError: null,
      };
    case DENY_SALE_SUCCESS:
      return {
        ...state,
        denyInProgress: false,
        denyReady: true,
        denyInProgressTransactionId: null,
        pendingTransactions: state.pendingTransactions.filter(
          transaction => transaction.sharetribeId !== payload
        ),
      };
    case DENY_SALE_ERROR:
      console.error(payload);
      return {
        ...state,
        denyInProgress: false,
        denySaleError: payload,
      };

    case CANCEL_BOOKED_REQUEST:
      return {
        ...state,
        cancelInProgress: true,
        cancelReady: false,
        cancelInProgressTransactionId: payload,
        cancelError: null,
      };
    case CANCEL_BOOKED_SUCCESS:
      return {
        ...state,
        cancelInProgress: false,
        cancelReady: true,
        cancelInProgressTransactionId: null,
        bookedTransactions: state.bookedTransactions.filter(
          transaction => transaction.sharetribeId !== payload
        ),
        activeTransactions: state.bookedTransactions.filter(
          transaction => transaction.sharetribeId !== payload
        ),
      };
    case CANCEL_BOOKED_ERROR:
      return {
        ...state,
        cancelInProgress: false,
        cancelError: payload,
      };

    case OPEN_EXTEND_MODAL:
      return {
        ...state,
        isExtendModalOpen: true,
        transactionIdToExtend: payload,
      };
    case CLOSE_EXTEND_MODAL:
      return {
        ...state,
        isExtendModalOpen: false,
        sendExtendSubmitted: false,
        sendExtendError: null,
      };

    case SEND_EXTEND_REQUEST:
      return {
        ...state,
        sendExtendInProgress: true,
        sendExtendError: null,
      };
    case SEND_EXTEND_SUCCESS:
      const { transactionId, bookingEnd } = payload;

      const transaction = state.activeTransactions.find(t => t.sharetribeId === transactionId);
      transaction.booking.end = bookingEnd;

      const active = [
        ...state.activeTransactions.filter(t => t.sharetribeId !== transactionId),
        transaction,
      ];
      return {
        ...state,
        sendExtendInProgress: false,
        sendExtendSubmitted: true,
        activeTransactions: active,
      };
    case SEND_EXTEND_ERROR:
      return {
        ...state,
        sendExtendInProgress: false,
        sendExtendError: payload,
      };

    case OPEN_ISSUE_MODAL:
      return {
        ...state,
        reportIssueTransactionId: payload,
      };
    case CLOSE_ISSUE_MODAL:
      return {
        ...state,
        reportIssueInProgress: false,
        reportIssueTransactionId: null,
        reportIssueSubmitted: false,
        reportIssueError: null,
      };

    case REPORT_ISSUE_REQUEST:
      return {
        ...state,
        reportIssueInProgress: true,
        reportIssueSubmitted: false,
        reportIssueError: null,
      };
    case REPORT_ISSUE_SUCCESS:
      return {
        ...state,
        reportIssueInProgress: false,
        reportIssueSubmitted: true,
      };
    case REPORT_ISSUE_ERROR:
      console.error(payload);
      return {
        ...state,
        reportIssueInProgress: false,
        reportIssueError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchCompletedTransactionsWeekCountRequest = () => ({
  type: FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_REQUEST,
});
export const fetchCompletedTransactionsWeekCountSuccess = response => ({
  type: FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_SUCCESS,
  payload: response,
});
export const fetchCompletedTransactionsWeekCountError = e => ({
  type: FETCH_COMPLETED_TRANSACTIONS_WEEK_COUNT_ERROR,
  payload: e,
});

export const fetchBookedTransactionsWeekCountRequest = () => ({
  type: FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_REQUEST,
});
export const fetchBookedTransactionsWeekCountSuccess = response => ({
  type: FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_SUCCESS,
  payload: response,
});
export const fetchBookedTransactionsWeekCountError = e => ({
  type: FETCH_BOOKED_TRANSACTIONS_WEEK_COUNT_ERROR,
  payload: e,
});

export const fetchPendingTransactionsRequest = () => ({
  type: FETCH_PENDING_TRANSACTIONS_REQUEST,
});
export const fetchPendingTransactionsSuccess = response => ({
  type: FETCH_PENDING_TRANSACTIONS_SUCCESS,
  payload: response,
});
export const fetchPendingTransactionsError = e => ({
  type: FETCH_PENDING_TRANSACTIONS_ERROR,
  payload: e,
});

export const fetchActiveTransactionsRequest = () => ({
  type: FETCH_ACTIVE_TRANSACTIONS_REQUEST,
});
export const fetchActiveTransactionsSuccess = response => ({
  type: FETCH_ACTIVE_TRANSACTIONS_SUCCESS,
  payload: response,
});
export const fetchActiveTransactionsError = e => ({
  type: FETCH_ACTIVE_TRANSACTIONS_ERROR,
  payload: e,
});

export const fetchBookedTransactionsRequest = () => ({
  type: FETCH_BOOKED_TRANSACTIONS_REQUEST,
});
export const fetchBookedTransactionsSuccess = response => ({
  type: FETCH_BOOKED_TRANSACTIONS_SUCCESS,
  payload: response,
});
export const fetchBookedTransactionsError = e => ({
  type: FETCH_BOOKED_TRANSACTIONS_ERROR,
  payload: e,
});

export const fetchCompletedTransactionsRequest = () => ({
  type: FETCH_COMPLETED_TRANSACTIONS_REQUEST,
});
export const fetchCompletedTransactionsSuccess = response => ({
  type: FETCH_COMPLETED_TRANSACTIONS_SUCCESS,
  payload: { response },
});
export const fetchCompletedTransactionsError = e => ({
  type: FETCH_COMPLETED_TRANSACTIONS_ERROR,
  payload: e,
});

export const openConfirmModal = body => ({
  type: OPEN_CONFIRM_MODAL,
  payload: body,
});
export const closeConfirmModal = () => ({
  type: CLOSE_CONFIRM_MODAL,
});

export const acceptSaleRequest = transactionId => ({
  type: ACCEPT_SALE_REQUEST,
  payload: transactionId,
});
export const acceptSaleSuccess = transactionId => ({
  type: ACCEPT_SALE_SUCCESS,
  payload: transactionId,
});
export const acceptSaleError = e => ({
  type: ACCEPT_SALE_ERROR,
  payload: e,
});

export const denySaleRequest = transactionId => ({
  type: DENY_SALE_REQUEST,
  payload: transactionId,
});
export const denySaleSuccess = transactionId => ({
  type: DENY_SALE_SUCCESS,
  payload: transactionId,
});
export const denySaleError = e => ({
  type: DENY_SALE_ERROR,
  payload: e,
});

export const cancelBookedRequest = transactionId => ({
  type: CANCEL_BOOKED_REQUEST,
  payload: transactionId,
});
export const cancelBookedSuccess = transactionId => ({
  type: CANCEL_BOOKED_SUCCESS,
  payload: transactionId,
});
export const cancelBookedError = e => ({
  type: CANCEL_BOOKED_ERROR,
  payload: e,
});

export const openExtendModal = transactionId => ({
  type: OPEN_EXTEND_MODAL,
  payload: transactionId,
});

export const closeExtendModal = () => ({
  type: CLOSE_EXTEND_MODAL,
});

export const sendExtendRequest = () => ({ type: SEND_EXTEND_REQUEST });
export const sendExtendSuccess = (transactionId, bookingEnd) => ({
  type: SEND_EXTEND_SUCCESS,
  payload: { transactionId, bookingEnd },
});
export const sendExtendError = e => ({ type: SEND_EXTEND_ERROR, payload: e });

export const openIssueModal = txId => ({ type: OPEN_ISSUE_MODAL, payload: txId });
export const closeIssueModal = () => ({ type: CLOSE_ISSUE_MODAL });

export const sendIssueRequest = () => ({ type: REPORT_ISSUE_REQUEST });
export const sendIssueSuccess = () => ({ type: REPORT_ISSUE_SUCCESS });
export const sendIssueError = e => ({ type: REPORT_ISSUE_ERROR, payload: e });

// ================ Thunks ================ //

export const reportIssue = (transactionId, body) => (dispatch, getState, sdk) => {
  dispatch(sendIssueRequest());

  return api.transactions
    .reportAnIssue(transactionId, body)
    .then(res => dispatch(sendIssueSuccess()))
    .catch(e => {
      dispatch(sendIssueError(e));
      throw e;
    });
};

export const extendBookingPeriod = (id, hours) => (dispatch, getState, sdk) => {
  dispatch(sendExtendRequest());

  const transaction = getState().LenderDashboardPage.activeTransactions.find(
    t => t.sharetribeId === id
  );
  const { start, end } = transaction.booking;
  const bookingEndCalculated = moment(end)
    .add(hours, 'hours')
    .toDate();
  const startDate = new Date(start);

  return sdk.transactions
    .transition({
      id: new UUID(id),
      transition: TRANSITION_UPDATE_BOOKING,
      params: {
        bookingStart: startDate,
        bookingEnd: bookingEndCalculated,
        type: ':time',
      },
    })
    .then(res => {
      dispatch(sendExtendSuccess(id, bookingEndCalculated));
    })
    .catch(e => {
      dispatch(sendExtendError(e));
    });
};

export const acceptSale = id => (dispatch, getState, sdk) => {
  dispatch(acceptSaleRequest(id));

  return sdk.transactions
    .transition({ id: new UUID(id), transition: TRANSITION_ACCEPT, params: {} }, { expand: true })
    .then(response => {
      dispatch(acceptSaleSuccess(id));
      setTimeout(() => dispatch(closeConfirmModal()), 1500);
      return response;
    })
    .catch(e => {
      dispatch(acceptSaleError(e));
      throw e;
    });
};

export const denySale = id => (dispatch, getState, sdk) => {
  dispatch(denySaleRequest(id));

  return sdk.transactions
    .transition({ id: new UUID(id), transition: TRANSITION_DECLINE, params: {} }, { expand: true })
    .then(response => {
      dispatch(denySaleSuccess(id));
      setTimeout(() => dispatch(closeConfirmModal()), 1500);
      return response;
    })
    .catch(e => {
      dispatch(denySaleError(e));
      throw e;
    });
};

export const cancelBooked = id => (dispatch, getState, sdk) => {
  dispatch(cancelBookedRequest(id));

  return sdk.transactions
    .transition({ id, transition: TRANSITION_CANCEL_AS_PROVIDER, params: {} })
    .then(response => {
      dispatch(cancelBookedSuccess(id));
      setTimeout(() => dispatch(closeConfirmModal()), 1500);
      return response;
    })
    .catch(e => {
      dispatch(cancelBookedError(e));
      throw e;
    });
};

export const fetchCompletedTransactionsWeekCount = sharetribeProviderId => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchCompletedTransactionsWeekCountRequest());

  return api.transactions
    .getTransactions({
      sharetribeProviderId,
      lastTransition: { $in: completedTransitions },
      createdAt: CURRENT_YEAR_TIME_RANGE,
      'payoutTotal.amount': { $gt: 0 },
    })
    .then(res => dispatch(fetchCompletedTransactionsWeekCountSuccess(res.data.totalItems)))
    .catch(e => dispatch(fetchCompletedTransactionsWeekCountError(e)));
};

export const fetchBookedTransactionsWeekCount = sharetribeProviderId => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchBookedTransactionsWeekCountRequest());

  return api.transactions
    .getTransactions({
      sharetribeProviderId,
      lastTransition: { $in: bookedTransitions },
      createdAt: CURRENT_YEAR_TIME_RANGE,
      'payoutTotal.amount': { $gt: 0 },
    })
    .then(res => dispatch(fetchBookedTransactionsWeekCountSuccess(res.data.totalItems)))
    .catch(e => dispatch(fetchBookedTransactionsWeekCountError(e)));
};

const fetchTransactions = (params, requestAction, sucessAction, errorAction) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(requestAction());

  return api.transactions
    .getTransactions(params)
    .then(res => dispatch(sucessAction(res)))
    .catch(e => dispatch(errorAction(e)));
};

export const fetchPendingTransactions = (sharetribeProviderId, page = 1) => {
  return fetchTransactions(
    {
      sharetribeProviderId,
      $relations: ['listing', 'seller'],
      $limit: PAGE_LIMIT,
      $page: page,
      lastTransition: { $in: [TRANSITION_CONFIRM_PAYMENT] },
      'payoutTotal.amount': { $gt: 0 },
    },
    fetchPendingTransactionsRequest,
    fetchPendingTransactionsSuccess,
    fetchPendingTransactionsError
  );
};

export const fetchActiveTransactions = (sharetribeProviderId, page = 1) => {
  return fetchTransactions(
    {
      sharetribeProviderId,
      $relations: ['listing', 'seller'],
      $limit: PAGE_LIMIT,
      $page: page,
      lastTransition: { $in: bookedTransitions },
      'booking.start': { $gte: today },
      'booking.end': { $lte: tomorrow },
      'payoutTotal.amount': { $gt: 0 },
    },
    fetchActiveTransactionsRequest,
    fetchActiveTransactionsSuccess,
    fetchActiveTransactionsError
  );
};

export const fetchBookedTransactions = (sharetribeProviderId, page = 1) => {
  return fetchTransactions(
    {
      sharetribeProviderId,
      $relations: ['listing', 'seller'],
      $limit: PAGE_LIMIT,
      $page: page,
      lastTransition: { $in: bookedTransitions },
      'booking.start': { $gte: tomorrow },
      'payoutTotal.amount': { $gt: 0 },
    },
    fetchBookedTransactionsRequest,
    fetchBookedTransactionsSuccess,
    fetchBookedTransactionsError
  );
};

export const fetchCompletedTransactions = (
  sharetribeProviderId,
  page = 1,
  searchString = '',
  dateRange = 'year'
) => (dispatch, getState, sdk) => {
  dispatch(fetchCompletedTransactionsRequest());

  const range = TIME_RANGES[dateRange];

  const timeRangeMaybe = range
    ? { 'booking.start': { $gte: range.start }, 'booking.end': { $lte: range.end } }
    : { 'booking.end': { $lte: new Date() } };

  return api.transactions
    .getTransactions({
      sharetribeProviderId,
      $relations: ['listing', 'seller', 'buyer'],
      $limit: PAGE_LIMIT,
      $page: page,
      $searchString: searchString,
      lastTransition: { $in: completedTransitions },
      ...timeRangeMaybe,
    })
    .then(res => {
      dispatch(fetchCompletedTransactionsSuccess(res));
    })
    .catch(e => dispatch(fetchCompletedTransactionsError()));
};

export const manualLoadData = sharetribeProviderId => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(fetchCompletedTransactionsWeekCount(sharetribeProviderId)),
    dispatch(fetchBookedTransactionsWeekCount(sharetribeProviderId)),
    dispatch(fetchPendingTransactions(sharetribeProviderId)),
    dispatch(fetchActiveTransactions(sharetribeProviderId)),
    dispatch(fetchBookedTransactions(sharetribeProviderId)),
    dispatch(fetchCompletedTransactions(sharetribeProviderId)),
  ]);
};
