import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { LISTING_TYPE } from '../../constants';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

const { UUID } = sdkTypes;

const PER_PAGE = 10;
const REVIEWS_PAGE_SIZE = 4;

export const ALL_CATEGORIES_FILTER = { key: 'all', label: 'All' };

const categoryOptions = [
  ALL_CATEGORIES_FILTER,
  ...findOptionsForSelectFilter('category', config.custom.filters),
];

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,
  // [{ category, listingRefs, pagination }, ...]
  categoryListings: [],
  queryListingsInProgress: null,
  queryListingsError: null,
  // Reviews
  reviews: [],
  reviewsPagination: null,
  queryReviewsError: null,
  queryReviewsInProgress: false,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        categoryListings: payload.userId.uuid === state.userId.uuid ? state.categoryListings : [],

        queryListingsError: null,
        queryListingsInProgress: true,
      };
    case QUERY_LISTINGS_SUCCESS: {
      const { category, listingRefs, meta: pagination } = payload;
      const categoryListing = { category, listingRefs, pagination };

      const categoryListings = [...state.categoryListings];
      const fetchedCategoryListingsIndex = categoryListings.findIndex(
        cl => cl.category.key === category.key
      );

      if (fetchedCategoryListingsIndex !== -1) {
        categoryListings[fetchedCategoryListingsIndex] = {
          ...categoryListing,
          listingRefs: [
            ...categoryListings[fetchedCategoryListingsIndex].listingRefs,
            ...listingRefs,
          ],
        };
      } else if (category.key === ALL_CATEGORIES_FILTER.key) {
        categoryListings.unshift(categoryListing);
      } else {
        categoryListings.push(categoryListing);
      }

      return { ...state, categoryListings, queryListingsInProgress: false };
    }
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        categoryListings: [],
        queryListingsError: payload,
        queryListingsInProgress: false,
      };

    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsInProgress: true, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS: {
      const { reviews: newReviews, reviewsPagination } = payload;
      const reviews = [...state.reviews, ...newReviews];
      return { ...state, reviews, reviewsPagination, queryReviewsInProgress: false };
    }
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsInProgress: false, queryReviewsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = (category, listingRefs, response) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { category, listingRefs, meta: response.data.meta },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = (reviews, reviewsPagination) => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: { reviews, reviewsPagination },
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = (userId, category, pagination) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const categoryMaybe =
    category && category.key !== ALL_CATEGORIES_FILTER.key ? { pub_category: category.key } : {};

  return sdk.listings
    .query({
      ...categoryMaybe,
      page: pagination?.page + 1 || 1,
      perPage: PER_PAGE,
      author_id: userId,
      pub_listingType: LISTING_TYPE.LENDER_POST,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(category, listingRefs, response));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = (userId, page = 1) => (dispatch, getState, sdk) => {
  dispatch(queryReviewsRequest());

  return sdk.reviews
    .query({
      page,
      perPage: REVIEWS_PAGE_SIZE,
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews, response.data.meta));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = params => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    categoryOptions.map(category => dispatch(queryUserListings(userId, category))),
    dispatch(queryUserReviews(userId)),
  ]);
};
