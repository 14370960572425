import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatDateForWantedPosts } from '../../util/dates';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

export const BookingPeriod = props => {
  const { intl, startDate, endDate, delivery, onSiteRentalMinutes, isCustomer } = props;
  const bookingStart = formatDateForWantedPosts(intl, startDate);
  const bookingEnd = formatDateForWantedPosts(intl, endDate);
  const deliveryMethod =
    delivery === 'pickUp' ? (
      isCustomer ? (
        <FormattedMessage id="BookingBreakdown.deliveryPickupCustomer" />
      ) : (
        <FormattedMessage id="BookingBreakdown.deliveryPickupProvider" />
      )
    ) : delivery === 'onSiteRental' ? (
      isCustomer ? (
        <FormattedMessage
          id="BookingBreakdown.deliveryOnsiteCustomer"
          values={{ minutes: onSiteRentalMinutes }}
        />
      ) : (
        <FormattedMessage
          id="BookingBreakdown.deliveryOnsiteProvider"
          values={{ minutes: onSiteRentalMinutes }}
        />
      )
    ) : null;

  return (
    <>
      <div>
        <div className={css.segmentTitle}>
          <FormattedMessage id="BookingBreakdown.dateSegmentTitle" />
        </div>
        <div className={css.booking}>
          <b>
            <FormattedMessage id="BookingBreakdown.bookingPeriodStartBold" />
          </b>
          <FormattedMessage
            id="BookingBreakdown.bookingPeriodStart"
            values={{ date: bookingStart.date, time: bookingStart.time }}
          />
        </div>
        <div className={css.booking}>
          <b>
            <FormattedMessage id="BookingBreakdown.bookingPeriodEndBold" />
          </b>
          <FormattedMessage
            id="BookingBreakdown.bookingPeriodEnd"
            values={{ date: bookingEnd.date, time: bookingEnd.time }}
          />
        </div>
        <div className={css.delivery}>{deliveryMethod}</div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { intl, booking, delivery, onSiteRentalMinutes, isCustomer } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDate = displayEnd || end;

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          startDate={localStartDate}
          endDate={localEndDate}
          delivery={delivery}
          onSiteRentalMinutes={onSiteRentalMinutes}
          isCustomer={isCustomer}
          intl={intl}
        />
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  // from injectIntl
  intl: intlShape.isRequired,
};

export default LineItemBookingPeriod;
