import { storableError } from '../../util/errors';
import {
  TRANSITION_CLAIM_WANTED_POST,
  TRANSITION_CLAIM_AFTER_ENQUIRY,
  TRANSITION_ENQUIRE_CLAIM,
} from '../../util/transaction';
import { LISTING_STATE_PUBLISHED } from '../../util/types';
import config from '../../config';
import { LISTING_TYPE } from '../../constants';
import api from '../../api';

// ================ Action types ================ //

export const FETCH_WANTED_LISTINGS_REQUEST = 'app/WantedPage/FETCH_WANTED_LISTINGS_REQUEST';
export const FETCH_WANTED_LISTINGS_SUCCESS = 'app/WantedPage/FETCH_WANTED_LISTINGS_SUCCESS';
export const FETCH_WANTED_LISTINGS_ERROR = 'app/WantedPage/FETCH_WANTED_LISTINGS_ERROR';

export const FETCH_PREVIOUS_LISTINGS_REQUEST = 'app/WantedPage/FETCH_PREVIOUS_LISTINGS_REQUEST';
export const FETCH_PREVIOUS_LISTINGS_SUCCESS = 'app/WantedPage/FETCH_PREVIOUS_LISTINGS_SUCCESS';
export const FETCH_PREVIOUS_LISTINGS_ERROR = 'app/WantedPage/FETCH_PREVIOUS_LiSTINGS_ERROR';

export const MESSAGE_PROVIDER_REQUEST = 'app/WantedPage/MESSAGE_PROVIDER_REQUEST';
export const MESSAGE_PROVIDER_SUCCESS = 'app/WantedPage/MESSAGE_PROVIDER_SUCCESS';
export const MESSAGE_PROVIDER_ERROR = 'app/WantedPage/MESSAGE_PROVIDER_ERROR;';
export const MESSAGE_PROVIDER_CLOSE_MODAL = 'app/WantedPage/MESSAGE_PROVIDER_CLOSE_MODAL';

export const CLAIM_ITEM_REQUEST = 'app/WantedPage/CLAIM_ITEM_REQUEST';
export const CLAIM_ITEM_SUCCESS = 'app/WantedPage/CLAIM_ITEM_SUCCESS';
export const CLAIM_ITEM_ERROR = 'app/WantedPage/CLAIM_ITEM_ERROR';
export const CLAIM_ITEM_CLOSE = 'app/WantedPage/CLAIM_ITEM_CLOSE';

// ================ Reducers ================ //

const initialState = {
  listings: [],
  pagination: null,
  queryInProgress: false,
  queryListingsError: null,
  queryParams: null,

  previousListings: [],
  previousListingsPagination: null,
  queryPreviousListingsInProgress: false,
  queryPreviousListingsError: null,

  messageProviderInProgress: false,
  messageSent: false,
  messageProviderError: null,

  claimInProgress: false,
  claimSent: false,
  claimError: null,
};

const wantedPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WANTED_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        queryListingsError: null,
        listings: [],
        queryParams: payload.queryParams,
      };
    case FETCH_WANTED_LISTINGS_SUCCESS: {
      const { items, currentPage, totalPages } = payload;

      return {
        ...state,
        queryInProgress: false,
        listings: items,
        pagination: { page: currentPage, totalPages: totalPages, perPage: 5 },
      };
    }
    case FETCH_WANTED_LISTINGS_ERROR:
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryListingsError: payload,
      };

    case FETCH_PREVIOUS_LISTINGS_REQUEST:
      return {
        ...state,
        queryPreviousListingsInProgress: true,
        queryPreviousListingsError: null,
        previousListings: [],
      };
    case FETCH_PREVIOUS_LISTINGS_SUCCESS: {
      const { items, currentPage, totalPages } = payload;

      return {
        ...state,
        queryPreviousListingsInProgress: false,
        previousListings: items,
        previousListingsPagination: { page: currentPage, totalPages: totalPages, perPage: 5 },
      };
    }
    case FETCH_PREVIOUS_LISTINGS_ERROR:
      console.error(payload);
      return {
        ...state,
        queryPreviousListingsInProgress: false,
        queryPreviousListingsError: payload,
      };

    case MESSAGE_PROVIDER_REQUEST:
      return {
        ...state,
        messageProviderInProgress: true,
        messageSent: false,
        messageProviderError: null,
      };

    case MESSAGE_PROVIDER_SUCCESS:
      return {
        ...state,
        messageProviderInProgress: false,
        messageSent: true,
      };
    case MESSAGE_PROVIDER_ERROR:
      console.error(payload);
      return {
        ...state,
        messageProviderInProgress: false,
        messageProviderError: payload,
      };
    case MESSAGE_PROVIDER_CLOSE_MODAL:
      return {
        ...state,
        messageProviderInProgress: false,
        messageSent: false,
        messageProviderError: null,
      };

    case CLAIM_ITEM_REQUEST:
      return {
        ...state,
        claimInProgress: true,
        claimSent: false,
        claimError: null,
      };
    case CLAIM_ITEM_SUCCESS:
      return {
        ...state,
        claimInProgress: false,
        claimSent: true,
      };
    case CLAIM_ITEM_ERROR:
      console.error(payload);
      return {
        ...state,
        claimInProgress: false,
        claimError: payload,
      };
    case CLAIM_ITEM_CLOSE:
      return {
        ...state,
        claimInProgress: false,
        claimSent: false,
        claimError: null,
      };

    default:
      return state;
  }
};

export default wantedPageReducer;

// ================ Action creators ================ //

export const queryListingsRequest = queryParams => ({
  type: FETCH_WANTED_LISTINGS_REQUEST,
  payload: { queryParams },
});
export const queryListingsSuccesss = response => ({
  type: FETCH_WANTED_LISTINGS_SUCCESS,
  payload: response.data,
});
export const queryListingsError = e => ({
  type: FETCH_WANTED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryPreviousListingsRequest = () => ({
  type: FETCH_PREVIOUS_LISTINGS_REQUEST,
});
export const queryPreviousListingsSuccess = response => ({
  type: FETCH_PREVIOUS_LISTINGS_SUCCESS,
  payload: response.data,
});
export const queryPreviousListingsError = e => ({
  type: FETCH_PREVIOUS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const messageProviderRequest = () => ({ type: MESSAGE_PROVIDER_REQUEST });
export const messageProviderSuccess = () => ({
  type: MESSAGE_PROVIDER_SUCCESS,
});
export const messageProviderError = e => ({ type: MESSAGE_PROVIDER_ERROR, payload: e });
export const closeMessageModal = () => ({ type: MESSAGE_PROVIDER_CLOSE_MODAL });

export const claimItemRequest = () => ({
  type: CLAIM_ITEM_REQUEST,
});
export const claimItemSuccess = () => ({
  type: CLAIM_ITEM_SUCCESS,
});
export const claimItemError = e => ({
  type: CLAIM_ITEM_ERROR,
  payload: e,
});
export const closeClaimModal = () => ({
  type: CLAIM_ITEM_CLOSE,
});

// ================ Thunks ================ //

export const messageProvider = (listingId, userId, messageContent) => (dispatch, getState, sdk) => {
  dispatch(messageProviderRequest());

  return api.transactions
    .getTransactions({
      sharetribeListingId: listingId,
      sharetribeCustomerId: userId,
      lastTransition: {
        $in: [
          TRANSITION_ENQUIRE_CLAIM,
          TRANSITION_CLAIM_AFTER_ENQUIRY,
          TRANSITION_CLAIM_WANTED_POST,
        ],
      },
    })
    .then(res => {
      if (res.data.items.length > 0) {
        const transactionId = res.data.items[0].sharetribeId;
        return sdk.messages
          .send({
            transactionId: transactionId,
            content: messageContent,
          })
          .then(() => dispatch(messageProviderSuccess()))
          .catch(e => {
            dispatch(messageProviderError(e));
            throw e;
          });
      } else {
        return sdk.transactions
          .initiate({
            processAlias: config.wantedProcessAlias,
            transition: TRANSITION_ENQUIRE_CLAIM,
            params: {
              listingId,
            },
          })
          .then(res => {
            sdk.messages
              .send({
                transactionId: res.data.data.id,
                content: messageContent,
              })
              .catch(e => {
                dispatch(messageProviderError(e));
                throw e;
              });
          })
          .then(() => dispatch(messageProviderSuccess()))
          .catch(e => {
            dispatch(messageProviderError(e));
            throw e;
          });
      }
    })
    .catch(e => {
      dispatch(messageProviderError(e));
      throw e;
    });
};

const claim = (claimFunc, params, messageContent, sdk, dispatch) => {
  return claimFunc(params)
    .then(res => {
      if (messageContent) {
        sdk.messages
          .send({
            transactionId: res.data.data.id,
            content: messageContent,
          })
          .catch(e => {
            throw e;
          });
      }
    })
    .then(() => dispatch(claimItemSuccess()))
    .catch(e => {
      dispatch(claimItemError(e));
      throw e;
    });
};

export const claimItem = (claimedItemId, listingId, userId, messageContent) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(claimItemRequest());

  return api.transactions
    .getTransactions({
      sharetribeListingId: claimedItemId,
      sharetribeCustomerId: userId,
      lastTransition: TRANSITION_ENQUIRE_CLAIM,
    })
    .then(res => {
      if (res.data.items.length > 0) {
        return claim(
          sdk.transactions.transition,
          {
            id: res.data.items[0].sharetribeId,
            transition: TRANSITION_CLAIM_AFTER_ENQUIRY,
            params: {
              listingId: claimedItemId,
              protectedData: { listingId },
            },
          },
          messageContent,
          sdk,
          dispatch
        );
      } else {
        return claim(
          sdk.transactions.initiate,
          {
            processAlias: config.wantedProcessAlias,
            transition: TRANSITION_CLAIM_WANTED_POST,
            params: {
              listingId: claimedItemId,
              protectedData: { listingId },
            },
          },
          messageContent,
          sdk,
          dispatch
        );
      }
    })
    .catch(e => {
      dispatch(claimItemError(e));
      throw e;
    });
};

export const queryPreviousListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryPreviousListingsRequest());

  const { page } = queryParams;
  const { currentUser } = getState().user;
  const authorId = currentUser?.id?.uuid;

  return api.listings
    .getListings({
      sharetribeAuthorId: authorId,
      'publicData.listingType': LISTING_TYPE.LENDER_POST,
      state: LISTING_STATE_PUBLISHED,
      $limit: 5,
      $page: page,
    })
    .then(response => dispatch(queryPreviousListingsSuccess(response)))
    .catch(e => {
      dispatch(queryPreviousListingsError(e));
      throw e;
    });
};

export const queryWantedListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { page, perPage, keywords, sort, category, userLatLng } = queryParams;

  const keywordsMaybe = keywords ? { $searchString: keywords } : {};
  const categoryMaybe = category ? { 'publicData.category': category } : {};

  let params = {
    ...keywordsMaybe,
    ...categoryMaybe,
    state: LISTING_STATE_PUBLISHED,
    $page: page ? page : 1,
    $limit: perPage,
    'publicData.listingType': LISTING_TYPE.WANTED_POST,
    'publicData.endTime': { $gt: Date.now() },
    $relations: ['author'],
  };
  if (sort === 'origin' && userLatLng) {
    params = {
      ...params,
      geolocationPoint: {
        $near: {
          $geometry: { type: 'Point', coordinates: [userLatLng.lng, userLatLng.lat] },
        },
      },
    };
  } else if (sort !== 'origin') {
    params = {
      ...params,
      $sort: sort,
    };
  }

  return api.listings
    .getListings(params)
    .then(response => {
      dispatch(queryListingsSuccesss(response));
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const manualLoadData = params => (dispatch, getState, sdk) => {
  dispatch(queryWantedListings(params));
};
