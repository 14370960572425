import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL_AS_PROVIDER,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_UPDATE_BOOKING,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CANCEL_AS_OPERATOR,
  TRANSITION_CANCEL_AS_CUSTOMER,
  TRANSITION_CANCEL_REQUEST,
} from '../../util/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import api from '../../api';
import { storableError } from '../../util/errors';

const { UUID } = sdkTypes;
const currentDate = new Date();
const today = new Date(currentDate.setHours(0, 0, 0, 0));
const PAGE_LIMIT = 5;

const pastTransitions = [
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CANCEL_REQUEST,
  TRANSITION_CANCEL_AS_PROVIDER,
  TRANSITION_CANCEL_AS_OPERATOR,
  TRANSITION_CANCEL_AS_CUSTOMER,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
];
const bookedTransitions = [TRANSITION_ACCEPT, TRANSITION_UPDATE_BOOKING];

// ================ Action types ================ //

export const FETCH_UPCOMING_TRANSACTIONS_REQUEST =
  'app/MyBorrowedItemsPagePage/FETCH_UPCOMING_TRANSACTIONS_REQUEST';
export const FETCH_UPCOMING_TRANSACTIONS_SUCCESS =
  'app/MyBorrowedItemsPagePage/FETCH_UPCOMING_TRANSACTIONS_SUCCESS';
export const FETCH_UPCOMING_TRANSACTIONS_ERROR =
  'app/MyBorrowedItemsPagePage/FETCH_UPCOMING_TRANSACTIONS_ERROR';

export const FETCH_PENDING_TRANSACTIONS_REQUEST =
  'app/MyBorrowedItemsPagePage/FETCH_PENDING_TRANSACTIONS_REQUEST';
export const FETCH_PENDING_TRANSACTIONS_SUCCESS =
  'app/MyBorrowedItemsPagePage/FETCH_PENDING_TRANSACTIONS_SUCCESS';
export const FETCH_PENDING_TRANSACTIONS_ERROR =
  'app/MyBorrowedItemsPagePage/FETCH_PENDING_TRANSACTIONS_ERROR';

export const FETCH_PAST_TRANSACTIONS_REQUEST =
  'app/MyBorrowedItemsPagePage/FETCH_PAST_TRANSACTIONS_REQUEST';
export const FETCH_PAST_TRANSACTIONS_SUCCESS =
  'app/MyBorrowedItemsPagePage/FETCH_PAST_TRANSACTIONS_SUCCESS';
export const FETCH_PAST_TRANSACTIONS_ERROR =
  'app/MyBorrowedItemsPagePage/FETCH_PAST_TRANSACTIONS_ERROR';

export const CANCEL_TRANSACTION_REQUEST = 'app/MyBorrowedItemsPagePage/CANCEL_TRANSACTION_REQUEST';
export const CANCEL_TRANSACTION_SUCCESS = 'app/MyBorrowedItemsPagePage/CANCEL_TRANSACTION_SUCCESS';
export const CANCEL_TRANSACTION_ERROR = 'app/MyBorrowedItemsPagePage/CANCEL_TRANSACTION_ERROR';

export const SEND_EXTEND_REQUEST = 'app/MyBorrowedItemsPagePage/SEND_EXTEND_REQUEST';
export const SEND_EXTEND_SUCCESS = 'app/MyBorrowedItemsPagePage/SEND_EXTEND_SUCCESS';
export const SEND_EXTEND_ERROR = 'app/MyBorrowedItemsPagePage/SEND_EXTEND_ERROR';

export const CONTACT_LENDER_REQUEST = 'app/MyBorrowedItemsPagePage/CONTACT_LENDER_REQUEST';
export const CONTACT_LENDER_SUCCESS = 'app/MyBorrowedItemsPagePage/CONTACT_LENDER_SUCCESS';
export const CONTACT_LENDER_ERROR = 'app/MyBorrowedItemsPagePage/CONTACT_LENDER_ERROR';

// ================ Reducers ================ //

const initialState = {
  upcomingTransactions: [],
  upcomingTransactionsPagination: null,
  fetchUpcomingTransactionsInProgress: false,
  fetchUpcomingTransactionsError: null,

  pendingTransactions: [],
  pendingTransactionsPagination: null,
  fetchPendingTransactionsInProgress: false,
  fetchPendingTransactionsError: null,

  pastTransactions: [],
  pastTransactionsPagination: null,
  fetchPastTransactionsInProgress: false,
  fetchPastTransactionsError: null,

  contactLenderInProgress: false,
  contactLenderError: null,

  cancelTransactionInProgress: false,
  cancelTransactionError: null,
};

const myBorrowedItemsPageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_UPCOMING_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchUpcomingTransactionsInProgress: true,
        fetchUpcomingTransactionsError: null,
      };
    case FETCH_UPCOMING_TRANSACTIONS_SUCCESS: {
      const { items, currentPage, totalPages, totalItems } = payload.data;

      return {
        ...state,
        fetchUpcomingTransactionsInProgress: false,
        upcomingTransactions: items,
        upcomingTransactionsPagination: {
          page: currentPage,
          totalPages,
          totalItems,
          perPage: PAGE_LIMIT,
        },
      };
    }
    case FETCH_UPCOMING_TRANSACTIONS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchUpcomingTransactionsInProgress: false,
        fetchUpcomingTransactionsError: payload,
      };

    case FETCH_PENDING_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchPendingTransactionsInProgress: true,
        fetchPendingTransactionsError: null,
      };
    case FETCH_PENDING_TRANSACTIONS_SUCCESS: {
      const { currentPage, totalPages, totalItems, items } = payload.data;

      return {
        ...state,
        fetchPendingTransactionsInProgress: false,
        pendingTransactions: items,
        pendingTransactionsPagination: {
          page: currentPage,
          totalPages,
          totalItems,
          perPage: PAGE_LIMIT,
        },
      };
    }
    case FETCH_PENDING_TRANSACTIONS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchPendingTransactionsInProgress: false,
        fetchPendingTransactionsError: payload,
      };

    case FETCH_PAST_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchPastTransactionsInProgress: true,
        fetchPastTransactionsError: null,
      };
    case FETCH_PAST_TRANSACTIONS_SUCCESS: {
      const { items, currentPage, totalPages, totalItems } = payload.response.data;

      return {
        ...state,
        fetchPastTransactionsInProgress: false,
        pastTransactions: items,
        pastTransactionsPagination: {
          page: currentPage,
          totalPages,
          totalItems,
          perPage: PAGE_LIMIT,
        },
        pastSearchString: payload.searchString,
      };
    }
    case FETCH_PAST_TRANSACTIONS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchPastTransactionsInProgress: false,
        fetchPastTransactionsError: payload,
      };

    case CANCEL_TRANSACTION_REQUEST:
      return {
        ...state,
        cancelTransactionInProgress: true,
        cancelTransactionError: null,
      };
    case CANCEL_TRANSACTION_SUCCESS: {
      const { id, isUpcomingTx, transaction } = payload;

      const newTransactions = isUpcomingTx
        ? { upcomingTransactions: state.upcomingTransactions.filter(t => t.sharetribeId !== id) }
        : { pendingTransactions: state.pendingTransactions.filter(t => t.sharetribeId !== id) };

      let newPastTransactions = {};

      if (state.pastTransactionsPagination && state.pastTransactionsPagination.page === 1) {
        newPastTransactions = { pastTransactions: [transaction, ...state.pastTransactions] };
      }

      return {
        ...state,
        cancelTransactionInProgress: false,
        ...newTransactions,
        ...newPastTransactions,
      };
    }
    case CANCEL_TRANSACTION_ERROR:
      return {
        ...state,
        cancelTransactionInProgress: false,
        cancelTransactionError: payload,
      };

    case SEND_EXTEND_REQUEST:
      return {
        ...state,
        sendExtendInProgress: true,
        sendExtendError: null,
      };
    case SEND_EXTEND_SUCCESS:
      const { transactionId, bookingEnd } = payload;

      const transaction = state.activeTransactions.find(t => t.sharetribeId === transactionId);
      transaction.booking.end = bookingEnd;

      const active = [
        ...state.activeTransactions.filter(t => t.sharetribeId !== transactionId),
        transaction,
      ];
      return {
        ...state,
        sendExtendInProgress: false,
        sendExtendSubmitted: true,
        activeTransactions: active,
      };
    case SEND_EXTEND_ERROR:
      return {
        ...state,
        sendExtendInProgress: false,
        sendExtendError: payload,
      };

    case CONTACT_LENDER_REQUEST:
      return {
        ...state,
        contactLenderInProgress: true,
        contactLenderError: null,
      };
    case CONTACT_LENDER_SUCCESS:
      return { ...state, contactLenderInProgress: false };
    case CONTACT_LENDER_ERROR:
      return { ...state, contactLenderInProgress: false, contactLenderError: payload };

    default:
      return state;
  }
};

export default myBorrowedItemsPageReducer;

// ================ Action creators ================ //

export const fetchUpcomingTransactionsRequest = () => ({
  type: FETCH_UPCOMING_TRANSACTIONS_REQUEST,
});
export const fetchUpcomingTransactionsSuccess = response => ({
  type: FETCH_UPCOMING_TRANSACTIONS_SUCCESS,
  payload: response,
});
export const fetchUpcomingTransactionsError = e => ({
  type: FETCH_UPCOMING_TRANSACTIONS_ERROR,
  payload: e,
});

export const fetchPendingTransactionsRequest = () => ({
  type: FETCH_PENDING_TRANSACTIONS_REQUEST,
});
export const fetchPendingTransactionsSuccess = response => ({
  type: FETCH_PENDING_TRANSACTIONS_SUCCESS,
  payload: response,
});
export const fetchPendingTransactionsError = e => ({
  type: FETCH_PENDING_TRANSACTIONS_ERROR,
  payload: e,
});

export const fetchPastTransactionsRequest = () => ({
  type: FETCH_PAST_TRANSACTIONS_REQUEST,
});
export const fetchPastTransactionsSuccess = (response, searchString) => ({
  type: FETCH_PAST_TRANSACTIONS_SUCCESS,
  payload: { response, searchString },
});
export const fetchPastTransactionsError = e => ({
  type: FETCH_PAST_TRANSACTIONS_ERROR,
  payload: e,
});

export const cancelTransactionRequest = () => ({
  type: CANCEL_TRANSACTION_REQUEST,
});
export const cancelTransactionSuccess = payload => ({
  type: CANCEL_TRANSACTION_SUCCESS,
  payload,
});
export const cancelTransactionError = e => ({
  type: CANCEL_TRANSACTION_ERROR,
  payload: e,
});

export const sendExtendRequest = () => ({ type: SEND_EXTEND_REQUEST });
export const sendExtendSuccess = (transactionId, bookingEnd) => ({
  type: SEND_EXTEND_SUCCESS,
  payload: { transactionId, bookingEnd },
});
export const sendExtendError = e => ({ type: SEND_EXTEND_ERROR, payload: e });

const contactLenderRequest = () => ({ type: CONTACT_LENDER_REQUEST });
const contactLenderSuccess = () => ({ type: CONTACT_LENDER_SUCCESS });
const contactLenderError = e => ({ type: CONTACT_LENDER_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const contactLender = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(contactLenderRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(() => {
      dispatch(contactLenderSuccess());
    })
    .catch(e => {
      dispatch(contactLenderError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

export const extendBookingPeriod = (id, hours) => (dispatch, getState, sdk) => {
  dispatch(sendExtendRequest());

  const transaction = getState().MyBorrowedItemsPage.activeTransactions.find(
    t => t.sharetribeId === id
  );
  const { start, end } = transaction.booking;
  const bookingEndCalculated = moment(end)
    .add(hours, 'hours')
    .toDate();
  const startDate = new Date(start);

  return sdk.transactions
    .transition({
      id: new UUID(id),
      transition: TRANSITION_UPDATE_BOOKING,
      params: {
        bookingStart: startDate,
        bookingEnd: bookingEndCalculated,
        type: ':time',
      },
    })
    .then(res => {
      dispatch(sendExtendSuccess(id, bookingEndCalculated));
    })
    .catch(e => {
      dispatch(sendExtendError(e));
    });
};

export const cancelTransaction = (id, isUpcomingTx) => (dispatch, getState, sdk) => {
  dispatch(cancelTransactionRequest());

  const transition = isUpcomingTx ? TRANSITION_CANCEL_AS_CUSTOMER : TRANSITION_CANCEL_REQUEST;

  return api.transactions
    .transitionTransaction(id, transition)
    .then(response => {
      const { transaction } = response.data;

      dispatch(cancelTransactionSuccess({ id, isUpcomingTx, transaction }));
      return response;
    })
    .catch(e => {
      dispatch(cancelTransactionError(e));
      throw e;
    });
};

const fetchTransactions = (params, requestAction, sucessAction, errorAction) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(requestAction());

  return api.transactions
    .getTransactions(params)
    .then(res => dispatch(sucessAction(res)))
    .catch(e => dispatch(errorAction(e)));
};

export const fetchUpcomingTransactions = (sharetribeCustomerId, page = 1) => {
  return fetchTransactions(
    {
      sharetribeCustomerId,
      $relations: ['listing', 'seller'],
      $limit: PAGE_LIMIT,
      $page: page,
      lastTransition: { $in: bookedTransitions },
      'booking.start': { $gte: today },
      'payoutTotal.amount': { $gt: 0 },
    },
    fetchUpcomingTransactionsRequest,
    fetchUpcomingTransactionsSuccess,
    fetchUpcomingTransactionsError
  );
};

export const fetchPendingTransactions = (sharetribeCustomerId, page = 1) => {
  return fetchTransactions(
    {
      sharetribeCustomerId,
      $relations: ['listing', 'seller'],
      $limit: PAGE_LIMIT,
      $page: page,
      lastTransition: { $in: [TRANSITION_CONFIRM_PAYMENT] },
      'payoutTotal.amount': { $gt: 0 },
    },
    fetchPendingTransactionsRequest,
    fetchPendingTransactionsSuccess,
    fetchPendingTransactionsError
  );
};

export const fetchPastTransactions = (sharetribeCustomerId, page = 1) => {
  return fetchTransactions(
    {
      sharetribeCustomerId,
      $relations: ['listing', 'seller', 'buyer'],
      $limit: PAGE_LIMIT,
      $page: page,
      lastTransition: { $in: pastTransitions },
    },
    fetchPastTransactionsRequest,
    fetchPastTransactionsSuccess,
    fetchPastTransactionsError
  );
};

export const manualLoadData = sharetribeCustomerId => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(fetchUpcomingTransactions(sharetribeCustomerId)),
    dispatch(fetchPendingTransactions(sharetribeCustomerId)),
    dispatch(fetchPastTransactions(sharetribeCustomerId)),
  ]);
};
