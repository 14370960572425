import httpClient from '../httpClient';

const getTransactions = params => {
  return httpClient.get(`/transactions`, { params });
};

const getTransactionsWithUnreadMessages = params => {
  return httpClient.get(`/transactions/unread-messages`, { params });
};

const transitionTransaction = (txId, transition, params) => {
  return httpClient.patch(`/transactions/${txId}/transition`, {
    transition,
    params,
  });
};

const readMessage = (txId, messageId) => {
  return httpClient.post(`/transactions/${txId}/messages/${messageId}/seen`);
};

const reportAnIssue = (txId, body) => {
  return httpClient.post(`/transactions/${txId}/issues`, body);
};

const transactions = {
  getTransactions,
  getTransactionsWithUnreadMessages,
  transitionTransaction,
  readMessage,
  reportAnIssue,
};

export default transactions;
