import httpClient from '../httpClient';

const getListings = params => {
  return httpClient.get('listings', {
    params,
  });
};

const getCategories = (skip, limit, params) => {
  return httpClient.post(
    `listings/categories/get-top-with-nearby?$skip=${skip}&$limit=${limit}`,
    params
  );
};

const isListingInLaunchedLocations = listingId => {
  return httpClient.post(`listings/${listingId}/check-location`);
};

const openListing = listingId => {
  return httpClient.post(`listings/${listingId}/open`);
};

const closeListing = listingId => {
  return httpClient.post(`listings/${listingId}/close`);
};

const discardListing = listingId => {
  return httpClient.delete(`listings/${listingId}`);
};

const feedback = (listingId, listingUrl, text) => {
  return httpClient.post(`listings/${listingId}/feedback`, { listingUrl, text });
};

const listings = {
  getListings,
  getCategories,
  isListingInLaunchedLocations,
  openListing,
  closeListing,
  discardListing,
  feedback,
};

export default listings;
