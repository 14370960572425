import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import {
  getReview1Transition,
  getReview2Transition,
  txIsInFirstReviewBy,
} from '../../util/transaction';
import unionWith from 'lodash/unionWith';
import { types as sdkTypes } from '../../util/sdkLoader';
import api from '../../api';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CHATS_PAGE_SIZE = 10;

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};
const CUSTOMER = 'customer';

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/ChatPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/ChatPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_SINGLE_TRANSACTION_SUCCESS = 'app/ChatPage/FETCH_SINGLE_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/ChatPage/FETCH_TRANSACTIONS_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/ChatPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/ChatPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/ChatPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/ChatPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/ChatPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/ChatPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/ChatPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/ChatPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/ChatPage/SEND_REVIEW_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ChatPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ChatPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ChatPage/FETCH_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Transactions
  fetchTransactionsInProgress: false,
  fetchTransactionsError: null,
  pagination: null,
  transactions: [],
  transactionRefs: [],
  reviews: [],
  // Messages
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  fetchOlderMessagesDone: false,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  // Send message
  initialMessageFailedToTransaction: null,
  sendMessageInProgress: false,
  sendMessageError: null,
  // Send review
  sendReviewInProgress: false,
  sendReviewError: null,
};

export default function chatPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchTransactionsInProgress: true, fetchTransactionsError: null };
    case FETCH_TRANSACTIONS_SUCCESS: {
      const { pagination, transactions } = payload;
      const { currentPage, totalPages } = pagination;

      return {
        ...state,
        fetchTransactionsInProgress: false,
        transactions: unionWith(state.transactions, transactions, (f1, f2) => f1._id === f2._id),
        pagination: { page: currentPage, totalPages: totalPages, perPage: CHATS_PAGE_SIZE },
      };
    }
    case FETCH_SINGLE_TRANSACTION_SUCCESS: {
      const transactions = unionWith(
        state.transactions,
        payload.transaction,
        (id1, id2) => id1.sharetribeId === id2.sharetribeId
      );
      return {
        ...state,
        fetchTransactionsInProgress: false,
        transactions,
      };
    }
    case FETCH_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionsInProgress: false, fetchTransactionsError: payload };

    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        fetchOlderMessagesDone: !payload.clear,
        fetchMessagesInProgress: true,
        fetchMessagesError: null,
        messages: payload.clear ? [] : state.messages,
        totalMessages: payload.clear ? null : state.totalMessages,
        totalMessagePages: null,
        oldestMessagePageFetched: 0,
      };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, reviews: [] };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload.reviews };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchTransactionsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransactionsSuccess = (pagination, transactions) => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { pagination, transactions },
});
const fetchSingleTransactionSuccess = transaction => ({
  type: FETCH_SINGLE_TRANSACTION_SUCCESS,
  payload: { transaction },
});
const fetchTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

const fetchMessagesRequest = clear => ({ type: FETCH_MESSAGES_REQUEST, payload: { clear } });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: { reviews } });
const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

const getTransactions = pagination => (dispatch, getState, sdk) => {
  const { page = -1 } = pagination || {};
  return api.transactions.getTransactions({
    $relations: ['listing', 'buyer', 'seller', 'buyer.image', 'seller.image'],
    $page: page + 1,
    $limit: CHATS_PAGE_SIZE,
  });
};

const getSortetTransactions = () => (dispatch, getState, sdk) => {
  return api.transactions.getTransactionsWithUnreadMessages({
    $relations: ['listing', 'buyer', 'seller'],
  });
};

export const fetchTransactions = pagination => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionsRequest());
  return Promise.all([
    dispatch(getTransactions(pagination)),
    dispatch(getSortetTransactions()),
  ]).then(values => {
    const paginatedTransactions = values[0];
    const sortedUnreadTransactions = values[1];

    const transactions = [...sortedUnreadTransactions.data, ...paginatedTransactions.data.items];

    dispatch(fetchTransactionsSuccess(paginatedTransactions.data, transactions));

    return paginatedTransactions;
  });
};

const fetchSingleTransaction = id => (dispatch, getState, sdk) => {
  return api.transactions
    .getTransactions({
      sharetribeId: id,
      $relations: ['listing', 'buyer', 'seller'],
    })
    .then(response => {
      dispatch(fetchSingleTransactionSuccess(response.data.items));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionsError(storableError(e)));
      throw e;
    });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  const shouldClearMessaagesState = page === 1;
  dispatch(fetchMessagesRequest(shouldClearMessaagesState));

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().ChatPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));
      messages &&
        messages[messages.length - 1] &&
        api.transactions
          .readMessage(txId, messages[messages.length - 1].id.uuid)
          .then(res => {
            const sharetribeId = res?.data?.transaction?.sharetribeId;
            const transactions = getState()?.ChatPage?.transactions;
            const transaction =
              transactions && transactions.find(tx => tx.sharetribeId === sharetribeId);
            if (transaction?.lastMessage) {
              transaction.lastMessage.seen = undefined;
            }
          })
          .catch(e => {
            throw e;
          });

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.ChatPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: new UUID(txId), content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
  const transition = getReview2Transition(role === CUSTOMER);

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id, params, role, dispatch, sdk) => {
  const transition = getReview1Transition(role === CUSTOMER);
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx.id, params, role, dispatch, sdk);
};

export const fetchTransactionReviews = transactionId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());

  return sdk.reviews
    .query({
      transactionId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  const { id } = params;

  const transactionLoaded = getState().ChatPage.transactions.find(t => t.sharetribeId === id);
  if (transactionLoaded && id) {
    return Promise.all([dispatch(fetchMessages(id, 1)), dispatch(fetchTransactionReviews(id))]);
  }

  return dispatch(fetchTransactions()).then(res => {
    const transactionFromUrl = res.data.items.find(tx => tx.sharetribeId === id);
    if (!transactionFromUrl && id) {
      dispatch(fetchSingleTransaction(id)).then(() =>
        Promise.all([dispatch(fetchMessages(id, 1)), dispatch(fetchTransactionReviews(id))])
      );
    } else if (res.data.items.length > 0 && id) {
      Promise.all([dispatch(fetchMessages(id, 1)), dispatch(fetchTransactionReviews(id))]);
    }
  });
};
