import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isTransactionsTransitionAlreadyReviewed } from '../../util/errors';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { FieldReviewRating, Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './ReviewForm.module.css';

const ReviewFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        //review
        reviewSent,
        sendReviewError,
        sendReviewInProgress,
        //feedback
        feedbackSent,
        sendFeedbackError,
        sendFeedbackInProgress,
      } = fieldRenderProps;

      const reviewRating = intl.formatMessage({ id: 'ReviewForm.reviewRatingLabel' });
      const reviewRatingRequiredMessage = intl.formatMessage({
        id: 'ReviewForm.reviewRatingRequired',
      });

      const reviewContent = intl.formatMessage({ id: 'ReviewForm.reviewContentLabel' });
      const reviewContentPlaceholderMessage = intl.formatMessage({
        id: 'ReviewForm.reviewContentPlaceholder',
      });
      const reviewContentRequiredMessage = intl.formatMessage({
        id: 'ReviewForm.reviewContentRequired',
      });

      const feedback = intl.formatMessage({ id: 'ReviewForm.feedback' });
      const feedbackPlaceholderMessage = intl.formatMessage({
        id: 'ReviewForm.feedbackPlaceholder',
      });

      const errorReviewMessage = isTransactionsTransitionAlreadyReviewed(sendReviewError) ? (
        <p className={css.error}>
          <FormattedMessage id="ReviewForm.reviewSubmitAlreadySent" />
        </p>
      ) : (
        <p className={css.error}>
          <FormattedMessage id="ReviewForm.reviewSubmitFailed" />
        </p>
      );
      const errorFeedbackMessage = (
        <p className={css.error}>
          <FormattedMessage id="ReviewForm.feedbackSubmitFailed" />
        </p>
      );
      const errorArea = sendReviewError ? (
        errorReviewMessage
      ) : sendFeedbackError ? (
        errorFeedbackMessage
      ) : (
        <p className={css.errorPlaceholder} />
      );

      const reviewSubmitMessage = intl.formatMessage({
        id: 'ReviewForm.reviewSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = sendReviewInProgress || sendFeedbackInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h4 className={css.reviewLabel}>{reviewRating}</h4>
          <FieldReviewRating
            id={formId ? `${formId}.starRating` : 'starRating'}
            name="reviewRating"
            validate={required(reviewRatingRequiredMessage)}
          />

          <h4 className={css.inputLabel}>{reviewContent}</h4>
          <FieldTextInput
            inputRootClass={css.input}
            type="textarea"
            id={formId ? `${formId}.reviewContent` : 'reviewContent'}
            name="reviewContent"
            placeholder={reviewContentPlaceholderMessage}
            validate={required(reviewContentRequiredMessage)}
          />

          <h4 className={css.inputLabelFeedback}>{feedback}</h4>
          <FieldTextInput
            inputRootClass={css.input}
            type="textarea"
            id={formId ? `${formId}.feedback` : `feedBack`}
            name="feedback"
            placeholder={feedbackPlaceholderMessage}
          />

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={reviewSent && feedbackSent}
          >
            {reviewSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ReviewFormComponent.defaultProps = { className: null, rootClassName: null, sendReviewError: null };

const { bool, func, string } = PropTypes;

ReviewFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  reviewSent: bool.isRequired,
  sendReviewError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
};

const ReviewForm = compose(injectIntl)(ReviewFormComponent);
ReviewForm.displayName = 'ReviewForm';

export default ReviewForm;
