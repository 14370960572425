import React from 'react';
import { arrayOf, bool, object, func, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from '../../util/reactIntl';
import { Modal } from '../../components';
import { ClaimForm } from '../../forms';

import css from './ClaimModal.module.css';

const ClaimModal = props => {
  const {
    id,
    intl,
    className,
    rootClassName,
    onManageDisableScrolling,
    isOpen,
    claimedItemId,
    onCloseModal,
    onFetchPreviousListings,
    previousListings,
    previousListingsPagination,
    queryPreviousListingsInProgress,
    queryPreviousListingsError,
    onClaimItem,
    claimInProgress,
    claimSent,
    claimError,
    onCreateNewListing,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ClaimModal.close' });

  return (
    <Modal
      id={id}
      className={classes}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      {isOpen ? (
        <ClaimForm
          onSubmit={v => onClaimItem(claimedItemId, v.listingId, v.message)}
          saveActionMsg={intl.formatMessage({ id: 'ClaimModal.submit' })}
          onFetchPreviousListings={onFetchPreviousListings}
          previousListings={previousListings}
          previousListingsPagination={previousListingsPagination}
          queryPreviousListingsInProgress={queryPreviousListingsInProgress}
          queryPreviousListingsError={queryPreviousListingsError}
          claimedItemId={claimedItemId}
          onClaimItem={onClaimItem}
          claimInProgress={claimInProgress}
          claimSent={claimSent}
          claimError={claimError}
          onCreateNewListing={onCreateNewListing}
        />
      ) : null}
    </Modal>
  );
};

ClaimModal.defaultProps = {
  className: null,
  rootClassName: null,
  queryPreviousListingsError: null,
  claimError: null,
};

ClaimModal.propTypes = {
  className: string,
  rootClassName: string,
  isOpen: bool.isRequired,
  onCloseModal: func.isRequired,
  onFetchPreviousListings: func.isRequired,
  previousListings: arrayOf(object).isRequired,
  previousListingsPagination: object,
  queryPreviousListingsInProgress: bool.isRequired,
  onClaimItem: func.isRequired,
  claimSent: bool.isRequired,
  claimInProgress: bool.isRequired,
  onCreateNewListing: func.isRequired,
};

export default injectIntl(ClaimModal);
