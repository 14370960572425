import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import IconCheckmark from '../IconCheckmark/IconCheckmark';

import css from './VerifiedBadge.module.css';

export const BADGE_SIZE_SMALL = 'small';
export const BADGE_SIZE_BIG = 'big';

const VerifiedBadge = props => {
  const { rootClassName, className, isVerified, size, onlyCheckmark } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return isVerified ? (
    <div className={classes}>
      <IconCheckmark
        className={classNames({
          [css.checkmarkSmall]: size === BADGE_SIZE_SMALL,
        })}
        size={size}
      />
      {!onlyCheckmark && <FormattedMessage id="VerifiedBadge.verified" />}
    </div>
  ) : null;
};

VerifiedBadge.defaultProps = {
  rootClassName: null,
  className: null,
  size: BADGE_SIZE_BIG,
  onlyCheckmark: false,
};

const { string, bool } = PropTypes;

VerifiedBadge.propTypes = {
  rootClassName: string,
  className: string,
  isVerified: bool,
  size: string,
  onlyCheckmark: bool,
};

export default VerifiedBadge;
