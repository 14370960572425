import React, { useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { bool, func, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './SaveBioForm.module.css';

const SaveBioForm = props => {
  const [editMode, setEditMode] = useState(false);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          saveError,
          saveInProgress,
          saveSent,
          handleSubmit,
          intl,
          invalid,
          disabled,
          initialValues,
        } = fieldRenderProps;
        const { bio } = initialValues;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid || disabled || saveInProgress;

        return (
          <Form
            className={classes}
            onSubmit={v => {
              setEditMode(false);
              handleSubmit(v);
            }}
          >
            <div className={css.container}>
              <div className={css.description}>
                  {intl.formatMessage({ id: 'SaveBioForm.description' })}
                </div>
              <div className={css.formRow}>
                {editMode ? (
                  <FieldTextInput
                    id="bio"
                    // className={css.input}
                    inputRootClass={css.input}
                    name="bio"
                    type="textarea"
                    placeholder={intl.formatMessage({
                      id: 'SaveBioForm.bioPlaceholder',
                    })}
                    validate={required(intl.formatMessage({ id: 'SaveBioForm.bioRequired' }))}
                  />
                ) : (
                  <div className={css.bio}>
                    {bio ? bio : intl.formatMessage({ id: 'SaveBioForm.noBio' })}
                  </div>
                )}
                {editMode ? (
                  <PrimaryButton
                    key="submit-button"
                    className={css.button}
                    inProgress={saveInProgress}
                    disabled={submitDisabled}
                    type="submit"
                  >
                    {intl.formatMessage({ id: 'SaveBioForm.save' })}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    key="edit-button"
                    className={css.button}
                    ready={saveSent}
                    inProgress={saveInProgress}
                    disabled={submitDisabled}
                    type="button"
                    onClick={() => setEditMode(true)}
                  >
                    {intl.formatMessage({ id: 'SaveBioForm.edit' })}
                  </PrimaryButton>
                )}
              </div>
              {saveError && (
                <div className={css.error}>
                  {intl.formatMessage({ id: 'SaveNameAndPronounsForm.saveError' })}
                </div>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

SaveBioForm.defaultProps = {
  rootClassName: null,
  className: null,
  saveError: null,
};

SaveBioForm.propTypes = {
  rootClassName: string,
  className: string,

  intl: intlShape.isRequired,

  saveSent: bool.isRequired,
  saveInProgress: bool.isRequired,
  saveError: propTypes.error,

  onSubmit: func.isRequired,
};

export default SaveBioForm;
