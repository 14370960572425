import React, { useState, useMemo, useCallback } from 'react';
import { arrayOf, bool, object, func } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { formatDateForWantedPosts } from '../../util/dates';
import { formatMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { PrimaryButton, ClaimModal, ReportIssueModal } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import Menu from '../Menu/Menu';
import MenuLabel from '../MenuLabel/MenuLabel';
import MenuIcon from '../ManageListingCard/MenuIcon';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
import NamedLink from '../NamedLink/NamedLink';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

import wantedItemImage from '../../assets/wanted-item-avatar.png';

import css from './WantedTable.module.css';

const { Money } = sdkTypes;
const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

const WantedTableComponent = props => {
  const {
    history,
    // user
    currentUser,
    intl,
    className,
    listings,
    onFetchPreviousListings,
    previousListings,
    previousListingsPagination,
    queryPreviousListingsInProgress,
    queryPreviousListingsError,
    onMessageProvider,
    onCloseMessageModal,
    messageProviderInProgress,
    messageSent,
    messageProviderError,
    onClaimItem,
    onCloseClaimModal,
    claimInProgress,
    claimSent,
    claimError,
    onCreateNewListing,
    onManageDisableScrolling,
  } = props;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [claimedItemId, setClaimedItemId] = useState(null);
  const [messageItemId, setMessageItemId] = useState(null);
  const classes = useMemo(() => classNames(css.root, className), [className]);

  const tableHeaders = useMemo(
    () => [
      intl.formatMessage({ id: 'WantedTable.wantedItemAndCategory' }),
      intl.formatMessage({ id: 'WantedTable.city' }),
      intl.formatMessage({ id: 'WantedTable.quantity' }),
      intl.formatMessage({ id: 'WantedTable.timeframe' }),
      intl.formatMessage({ id: 'WantedTable.dateAndTime' }),
      intl.formatMessage({ id: 'WantedTable.maxPriceTitle' }),
      intl.formatMessage({ id: 'WantedTable.actions' }),
    ],
    [intl]
  );

  const userId = useMemo(() => currentUser?.id?.uuid || null, [currentUser]);

  const handleClaimItem = useCallback(
    (claimedItemId, listingId, messageContent) =>
      onClaimItem(claimedItemId, listingId, messageContent).then(() => {
        setClaimedItemId(null);
        onCloseClaimModal();
        setIsConfirmationModalOpen(true);
      }),
    [onClaimItem, onCloseClaimModal]
  );

  return (
    <React.Fragment>
      <table className={classes}>
        <thead className={css.header}>
          <tr>
            <th />
            {tableHeaders.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody className={css.body}>
          {listings.map((l, i) => {
            const {
              author,
              price: noTypePrice,
              publicData,
              sharetribeId,
              title,
              description,
              image,
            } = l;
            const { borrowingType, startTime, endTime, category, quantity, city } = publicData;
            const categoryLabel = categoryOptions.find(c => c.key === category);
            const isHourly = borrowingType === 'hourly';
            const imageVariants = image && image.variants && Object.keys(image.variants);
            const listingImage =
              imageVariants && imageVariants[0] && image.variants[imageVariants[0]].url;
            const disableClaim = currentUser && currentUser.id.uuid === author.sharetribeId;
            const authorName = `${author.firstName} ${author.lastName[0]}.`;
            const price = new Money(noTypePrice.amount, noTypePrice.currency);

            return (
              <tr
                key={sharetribeId}
                className={css.tableRow}
                onClick={() => {
                  history.push(
                    createResourceLocatorString(
                      'ListingPage',
                      routeConfiguration(),
                      { id: sharetribeId, slug: createSlug(title) },
                      {}
                    )
                  );
                }}
              >
                <td>
                  <div className={css.imageCell}>
                    <div className={css.imageWrapper}>
                      <div className={css.aspectWrapper}>
                        <img
                          className={css.rootForImage}
                          src={listingImage ? listingImage : wantedItemImage}
                          alt="listingImage"
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className={css.wantedPostInfo}>
                    <div className={css.cell}>{authorName}</div>
                    <div>{title}</div>
                    {categoryLabel && <div>{categoryLabel.label}</div>}
                    {description && <div className={css.description}> {description}</div>}
                  </div>
                </td>
                <td>
                  <div>{city || 'n/a'}</div>
                </td>
                <td>
                  <div>{quantity}</div>
                </td>
                <td>
                  <div className={css.cell}>
                    {borrowingType === 'hourly' ? (
                      <FormattedMessage id="WantedTable.hourly" />
                    ) : borrowingType === 'daily' ? (
                      <FormattedMessage id="WantedTable.daily" />
                    ) : (
                      <FormattedMessage id="WantedTable.weekly" />
                    )}
                  </div>
                </td>
                {isHourly ? (
                  <React.Fragment>
                    <td>
                      <div className={css.dateAndTime}>
                        <div className={classNames(css.cell, css.dateMarginBottom)}>
                          {formatDateForWantedPosts(intl, startTime).date}
                        </div>
                        <div className={classNames(css.cell, css.noWrap)}>
                          <FormattedMessage
                            id="WantedTable.dateRange"
                            values={{
                              start: formatDateForWantedPosts(intl, startTime).time,
                              end: formatDateForWantedPosts(intl, endTime).time,
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <td>
                      <div className={classNames(css.cell, css.noWrap)}>
                        <FormattedMessage
                          id="WantedTable.dateRange"
                          values={{
                            start: formatDateForWantedPosts(intl, startTime).date,
                            end: formatDateForWantedPosts(intl, endTime).date,
                          }}
                        />
                      </div>
                    </td>
                  </React.Fragment>
                )}
                <td>
                  <FormattedMessage
                    id="WantedTable.maxPrice"
                    values={{
                      price: formatMoney(intl, price, 0),
                      period:
                        borrowingType === 'hourly' ? (
                          <FormattedMessage id="WantedTable.hour" />
                        ) : borrowingType === 'daily' ? (
                          <FormattedMessage id="WantedTable.day" />
                        ) : (
                          <FormattedMessage id="WantedTable.week" />
                        ),
                    }}
                  />
                </td>
                <td>
                  <Menu contentPosition="left" useArrow={false}>
                    <MenuLabel className={css.menuLabel} isOpenClassName={css.menuLabelOpen}>
                      <MenuIcon />
                    </MenuLabel>
                    <MenuContent>
                      <MenuItem key="view-item">
                        <NamedLink
                          name="ListingPage"
                          params={{ id: sharetribeId, slug: createSlug(title) }}
                          className={css.menuItemAsButton}
                        >
                          <FormattedMessage id="WantedTable.viewItem" />
                        </NamedLink>
                      </MenuItem>
                      {!disableClaim && (
                        <MenuItem key="claim">
                          <PrimaryButton
                            className={css.menuItemAsButton}
                            onClick={e => {
                              e.stopPropagation();
                              setClaimedItemId(sharetribeId);
                            }}
                          >
                            <FormattedMessage id="WantedTable.claim" />
                          </PrimaryButton>
                        </MenuItem>
                      )}
                      {!disableClaim && (
                        <MenuItem key="message-provider">
                          <PrimaryButton
                            className={css.menuItem}
                            onClick={e => {
                              e.stopPropagation();
                              setMessageItemId(sharetribeId);
                            }}
                            disabled={messageProviderInProgress}
                          >
                            <FormattedMessage id="WantedTable.messageBorrower" />
                          </PrimaryButton>
                        </MenuItem>
                      )}
                    </MenuContent>
                  </Menu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ClaimModal
        id="WantedTable.ClaimModal"
        isOpen={!!claimedItemId}
        onCloseModal={() => {
          setClaimedItemId(null);
          onCloseClaimModal();
        }}
        claimedItemId={claimedItemId}
        onFetchPreviousListings={onFetchPreviousListings}
        previousListings={previousListings}
        previousListingsPagination={previousListingsPagination}
        queryPreviousListingsInProgress={queryPreviousListingsInProgress}
        queryPreviousListingsError={queryPreviousListingsError}
        onClaimItem={handleClaimItem}
        claimInProgress={claimInProgress}
        claimSent={claimSent}
        claimError={claimError}
        onCreateNewListing={onCreateNewListing}
        onManageDisableScrolling={onManageDisableScrolling}
      />
      <ConfirmationModal
        id="ConfirmationWantedTable"
        isOpen={isConfirmationModalOpen}
        onCloseModal={() => setIsConfirmationModalOpen(false)}
        onSubmit={() => setIsConfirmationModalOpen(false)}
        modalTitle={intl.formatMessage({ id: 'WantedTable.confirmModalTitle' })}
        modalText={intl.formatMessage({ id: 'WantedTable.confirmModalText' })}
        modalButtonText={intl.formatMessage({ id: 'WantedTable.confirmModalButtonText' })}
        onManageDisableScrolling={onManageDisableScrolling}
      />
      <ReportIssueModal
        id="MessageWanted"
        onManageDisableScrolling={onManageDisableScrolling}
        isOpen={!!messageItemId}
        onSubmit={({ text }) => onMessageProvider(messageItemId, userId, text)}
        onCloseModal={() => {
          setMessageItemId(null);
          onCloseMessageModal();
        }}
        submitInProgress={messageProviderInProgress}
        submitError={messageProviderError}
        ready={messageSent}
        headerText={intl.formatMessage({ id: 'WantedTable.sendMessageTitle' })}
        inputPlaceholder={intl.formatMessage({ id: 'WantedTable.sendMessagePlaceholder' })}
      />
    </React.Fragment>
  );
};

WantedTableComponent.defaultProps = {
  className: null,
  queryPreviousListingsError: null,
  claimError: null,
};

WantedTableComponent.propTypes = {
  listings: arrayOf(object).isRequired,
  onFetchPreviousListings: func.isRequired,
  previousListings: arrayOf(object).isRequired,
  previousListingsPagination: object,
  queryPreviousListingsInProgress: bool.isRequired,
  onClaimItem: func.isRequired,
  onCloseClaimModal: func.isRequired,
  claimSent: bool.isRequired,
  claimInProgress: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  userLatLng: object,
  latLngLoading: bool.isRequired,
  onCreateNewListing: func.isRequired,
};

export default injectIntl(WantedTableComponent);
