import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedIn.module.css';

const IconSocialMediaLinkedIn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#0077B5"
        d="M22,0H2C0.897,0,0,0.897,0,2v20c0,1.103,0.897,2,2,2h20c1.103,0,2-0.897,2-2V2C24,0.897,23.103,0,22,0z M7.744,19.089h-3.21V9.381h3.21V19.089zM6.128,8.197c-1.074,0-1.828-0.889-1.828-1.993c0-1.104,0.754-1.993,1.914-1.993S8.155,5.1,8.155,6.204C8.155,7.308,7.202,8.197,6.128,8.197zM20.004,19.089h-3.209v-5.078c0-1.274-0.457-2.143-1.607-2.143c-0.875,0-1.396,0.592-1.625,1.167c-0.084,0.204-0.105,0.488-0.105,0.775v5.278H9.911c0,0,0.045-9.468,0-10.408h3.209v1.472c0.43-0.662,1.205-1.607,2.937-1.607c2.144,0,3.759,1.405,3.759,4.421V19.089z"
      />
    </svg>
  );
};

IconSocialMediaLinkedIn.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedIn.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedIn;
