import React, { useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { bool, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import VerifiedBadge, { BADGE_SIZE_SMALL } from '../../components/VerifiedBadge/VerifiedBadge';

import css from './SaveEmailForm.module.css';

const ChangeEmailForm = props => {
  const [editMode, setEditMode] = useState(false);
  const [verifyMode, setVerifyMode] = useState(false);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          emailVerified,
          pendingEmail,
          saveEmailError,
          saveEmailInProgress,
          saveEmailSent,
          handleSubmit,
          verifyEmailError,
          verifyEmailInProgress,
          verifyEmailSent,
          onVerifyEmail,
          intl,
          invalid,
          disabled,
          initialValues,
          values,
        } = fieldRenderProps;
        const { email } = initialValues;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid || disabled || saveEmailInProgress;
        const verifyDisabled = !values.code || verifyEmailInProgress;

        return (
          <Form
            className={classes}
            onSubmit={v => {
              setEditMode(false);
              handleSubmit(v);
            }}
          >
            <div className={css.continer}>
              <div className={css.formRow}>
                <div className={css.header}>
                  <div className={css.title}>
                    <span>
                      <b>{intl.formatMessage({ id: 'SaveEmailForm.label' })}</b>
                    </span>
                    <span className={css.marginLeft}>{email}</span>
                  </div>
                  <VerifiedBadge
                    rootClassName={css.verifiedBadge}
                    isVerified={emailVerified}
                    size={BADGE_SIZE_SMALL}
                  />
                </div>
                <div className={css.formRow}>
                  {!editMode && (
                    <div className={css.formRow}>
                      <PrimaryButton
                        onClick={e => {
                          e.preventDefault();
                          setEditMode(true);
                        }}
                        inProgress={saveEmailInProgress}
                        className={css.button}
                        disabled={submitDisabled}
                      >
                        {intl.formatMessage({ id: 'SaveEmailForm.edit' })}
                      </PrimaryButton>
                    </div>
                  )}
                  {!verifyMode && !emailVerified && (
                    <PrimaryButton
                      onClick={e => {
                        e.preventDefault();
                        setVerifyMode(true);
                      }}
                      ready={verifyEmailSent}
                      disabled={verifyEmailInProgress}
                      inProgress={verifyEmailInProgress}
                      className={css.verifyButton}
                    >
                      {intl.formatMessage({ id: 'SaveEmailForm.verify' })}
                    </PrimaryButton>
                  )}
                </div>
              </div>
              {editMode && (
                <React.Fragment>
                  <div className={css.formRow}>
                    <FieldTextInput
                      className={css.input}
                      id="email"
                      name="email"
                      type="text"
                      validate={required(intl.formatMessage({ id: 'SaveEmailForm.emailRequired' }))}
                    />
                  </div>
                  <div>
                    <div className={css.description}>
                      {intl.formatMessage({ id: 'SaveEmailForm.passwordLabel' })}
                    </div>
                    <div className={css.formRow}>
                      <FieldTextInput
                        className={css.input}
                        id="currentPassword"
                        name="currentPassword"
                        type="password"
                        placeholder={intl.formatMessage({
                          id: 'SaveEmailForm.passwordPlaceholder',
                        })}
                        validate={required(
                          intl.formatMessage({ id: 'SaveEmailForm.passwordRequired' })
                        )}
                      />
                      <PrimaryButton
                        className={css.button}
                        inProgress={saveEmailInProgress}
                        disabled={submitDisabled}
                        type="submit"
                      >
                        {intl.formatMessage({ id: 'SaveEmailForm.save' })}
                      </PrimaryButton>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {saveEmailError && (
                <div className={css.error}>
                  {intl.formatMessage({ id: 'SaveEmailForm.saveError' })}
                </div>
              )}
              {saveEmailSent && !emailVerified && (
                <div className={css.description}>
                  {intl.formatMessage({ id: 'SaveEmailForm.emailSent' })}
                </div>
              )}
              {!verifyEmailError && !saveEmailError && pendingEmail && (
                <div className={css.description}>
                  {intl.formatMessage(
                    { id: 'SaveEmailForm.verificationSent' },
                    { email: pendingEmail }
                  )}
                </div>
              )}
              {verifyMode && (
                <div className={css.verifyRow}>
                  <FieldTextInput
                    className={css.input}
                    id="code"
                    name="code"
                    type="text"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: 'SaveEmailForm.codePlaceholder' })}
                  />
                  <PrimaryButton
                    className={css.sendCodeButton}
                    inProgress={verifyEmailInProgress}
                    disabled={verifyDisabled}
                    onClick={e => {
                      e.preventDefault();
                      onVerifyEmail(values.code);
                      setVerifyMode(false);
                    }}
                  >
                    {intl.formatMessage({ id: 'SaveEmailForm.sendVerificationCode' })}
                  </PrimaryButton>
                </div>
              )}
              {verifyEmailError && (
                <div className={css.error}>
                  {intl.formatMessage({ id: 'SaveEmailForm.verifyError' })}
                </div>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

ChangeEmailForm.defaultProps = {
  saveEmailError: null,
};

ChangeEmailForm.propTypes = {
  intl: intlShape.isRequired,

  emailVerified: bool.isRequired,

  onSubmit: func.isRequired,
  saveEmailError: propTypes.error,
  saveEmailInProgress: bool.isRequired,
  saveEmailSent: bool.isRequired,

  verifyEmailError: propTypes.error,
  verifyEmailInProgress: bool.isRequired,
  verifyEmailSent: bool.isRequired,
  onVerifyEmail: func.isRequired,
};

export default ChangeEmailForm;
