import React, { Component } from 'react';
import { arrayOf, bool, func, node, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';

import { FieldDateRangeController } from '../../components';
import { FilterForm } from '../../forms';

const getDatesQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'dates';
};

// Parse query parameter, which should look like "2020-05-28,2020-05-31"
const parseValue = value => {
  const rawValuesFromParams = value ? value.split(',') : [];
  const [startDate, endDate] = rawValuesFromParams.map(v => parseDateFromISO8601(v));
  return value && startDate && endDate
    ? { startDate, endDate }
    : { startDate: null, endDate: null };
};
// Format dateRange value for the query. It's given by FieldDateRangeInput:
// { dates: { startDate, endDate } }
const formatValue = (dateRange, queryParamName) => {
  const hasDates = dateRange && dateRange.dates;
  const { startDate, endDate } = hasDates ? dateRange.dates : {};
  const start = startDate ? stringifyDateToISO8601(startDate) : null;
  const end = endDate ? stringifyDateToISO8601(endDate) : null;
  const value = start && end ? `${start},${end}` : null;
  return { [queryParamName]: value };
};

export class BookingDateRangeFilterComponent extends Component {
  render() {
    const {
      className,
      rootClassName,
      initialValues,
      id,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
    } = this.props;

    const datesQueryParamName = getDatesQueryParamName(queryParamNames);
    const initialDates =
      initialValues && initialValues[datesQueryParamName]
        ? parseValue(initialValues[datesQueryParamName])
        : { dates: null };

    const handleSubmit = values => {
      const { startDate, endDate } = values ? values : {};
      if (startDate && endDate) {
        onSubmit(
          formatValue(
            {
              dates: {
                startDate: startDate.date ? startDate.date : startDate,
                endDate: endDate.date ? endDate.date : endDate,
              },
            },
            datesQueryParamName
          )
        );
      }
    };

    return (
      <FilterForm
        id={id}
        className={className}
        rootClassName={rootClassName}
        liveEdit
        contentPlacementOffset={contentPlacementOffset}
        onChange={handleSubmit}
        initialValues={initialDates}
        keepDirtyOnReinitialize={false}
      >
        <FieldDateRangeController value={initialDates} />
      </FilterForm>
    );
  }
}

BookingDateRangeFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  showAsPopup: true,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
};

BookingDateRangeFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  showAsPopup: bool,
  liveEdit: bool,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const BookingDateRangeFilter = injectIntl(BookingDateRangeFilterComponent);

export default BookingDateRangeFilter;
