import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.module.css';

const IconLocation = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 35"
      width="25"
      height="35"
    >
      <path
        fillRule="evenodd"
        d="M24.18 12.09C24.18 21.16 12.09 34.55 12.09 34.55C12.09 34.55 0 21.16 0 12.09C0 5.41 5.41 0 12.09 0C18.78 0 24.18 5.41 24.18 12.09ZM16.41 12.09C16.41 9.71 14.48 7.77 12.09 7.77C9.71 7.77 7.77 9.71 7.77 12.09C7.77 14.48 9.71 16.41 12.09 16.41C14.48 16.41 16.41 14.48 16.41 12.09Z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconLocation.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLocation.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLocation;
