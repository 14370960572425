import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './PasswordChangeForm.module.css';

const PasswordChangeForm = props => {
  const [editMode, setEditMode] = useState(false);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          changePasswordError,
          handleSubmit,
          inProgress,
          resetPasswordInProgress,
          intl,
          disabled,
          invalid,
          ready,
          form,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid || disabled || inProgress;

        // New password
        const newPasswordPlaceholder = intl.formatMessage({
          id: 'PasswordChangeForm.newPasswordPlaceholder',
        });
        const newPasswordRequiredMessage = intl.formatMessage({
          id: 'PasswordChangeForm.newPasswordRequired',
        });
        const newPasswordRequired = validators.requiredStringNoTrim(newPasswordRequiredMessage);

        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'PasswordChangeForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'PasswordChangeForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );

        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );

        const passwordPlaceholder = intl.formatMessage({
          id: 'PasswordChangeForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'PasswordChangeForm.passwordRequired',
        });

        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

        return (
          <Form
            className={classes}
            onSubmit={v => {
              handleSubmit(v).then(() => {
                setEditMode(false);
                form.reset();
              });
            }}
          >
            {editMode ? (
              <div className={css.container}>
                <FieldTextInput
                  className={css.input}
                  id={formId ? `${formId}.newPassword` : 'newPassword'}
                  name="newPassword"
                  type="password"
                  placeholder={newPasswordPlaceholder}
                  validate={validators.composeValidators(
                    newPasswordRequired,
                    passwordMinLength,
                    passwordMaxLength
                  )}
                />
                <div className={css.formRow}>
                  <FieldTextInput
                    className={css.input}
                    id={formId ? `${formId}.currentPassword` : 'currentPassword'}
                    name="currentPassword"
                    type="password"
                    placeholder={passwordPlaceholder}
                    validate={validators.composeValidators(
                      passwordRequired,
                      passwordMinLength,
                      passwordMaxLength
                    )}
                  />
                  <PrimaryButton
                    className={css.button}
                    inProgress={resetPasswordInProgress}
                    disabled={submitDisabled}
                    type="submit"
                    ready={ready}
                  >
                    {intl.formatMessage({ id: 'PasswordChangeForm.save' })}
                  </PrimaryButton>
                </div>
              </div>
            ) : (
              <div>
                <PrimaryButton
                  onClick={e => {
                    e.preventDefault();
                    setEditMode(true);
                  }}
                  className={css.changePasswordButton}
                  inProgress={resetPasswordInProgress}
                  disabled={submitDisabled}
                  ready={ready}
                >
                  {intl.formatMessage({ id: 'PasswordChangeForm.changePassword' })}
                </PrimaryButton>
              </div>
            )}
            {changePasswordError && (
              <div className={css.error}>
                {intl.formatMessage({ id: 'PasswordChangeForm.genericFailure' })}
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

PasswordChangeForm.defaultProps = {
  rootClassName: null,
  className: null,
  changePasswordError: null,
  inProgress: false,
  formId: null,
};

PasswordChangeForm.propTypes = {
  rootClassName: string,
  className: string,
  changePasswordError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  formId: string,
};

export default PasswordChangeForm;
