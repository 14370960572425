import borrowedLocations from './borrowed_locations';
import listings from './listings';
import transactions from './transactions';
import users from './users';

const api = {
  borrowedLocations,
  listings,
  transactions,
  users,
};

export default api;
