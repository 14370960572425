import httpClient from '../httpClient';

const getBorrowedToken = stToken => {
  return httpClient.get(`users/auth/${stToken}`);
};

const rateUser = (userId, transactionId, listingId, reviewContent, reviewRating) => {
  return httpClient.post(`users/reviews/${userId}`, {
    transactionId,
    listingId,
    content: reviewContent,
    rating: reviewRating,
  });
};

const createUser = params => {
  return httpClient.post(`users`, { ...params });
};

const addPhoneNumber = phoneNumber => {
  return httpClient.post(`users/phone-number`, { phoneNumber });
};

const sendSMSVerification = () => {
  return httpClient.post(`users/sms-verification-code/send`);
};

const verifyPhoneNumber = code => {
  return httpClient.post(`users/sms-verification-code/verify`, { code });
};

const deleteUser = () => {
  return httpClient.post(`users/delete-account`);
};

const contactUs = params => {
  return httpClient.post(`/contact-us`, params);
};

const usePromoCode = promoCode => {
  return httpClient.post(`/users/promo-codes`, { promoCode });
};

const validatePromoCode = code => {
  return httpClient.post(`/users/promo-codes/verify/${code}`);
};

const getUsedPromoCodes = () => {
  return httpClient.get('/users/promo-codes/used');
};

const users = {
  getBorrowedToken,
  rateUser,
  createUser,
  addPhoneNumber,
  sendSMSVerification,
  verifyPhoneNumber,
  deleteUser,
  contactUs,
  usePromoCode,
  validatePromoCode,
  getUsedPromoCodes,
};

export default users;
