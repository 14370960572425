import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { stringifyDateToISO8601 } from '../../util/dates';
import { encodeLatLngBounds, stringify } from '../../util/urlHelpers';
import { filters } from '../../marketplace-custom-config';
import { findOptionsForSelectFilter } from '../../util/search';

import landingImage1 from '../../assets/home-main.png';
import landingImage2 from '../../assets/home-diy.png';
import landingImage3 from '../../assets/home-adventure.png';

import css from './SectionHero.module.css';

const CURRENT_DATE = new Date();
const date = new Date();
const nextDay = date.setDate(date.getDate() + 1);

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const defaultMarkets = findOptionsForSelectFilter('market', filters);
  const { borrowedLocations, selectedMarketKey } = useSelector(
    ({ BorrowedLocation }) => BorrowedLocation
  );

  const markets = useMemo(() => borrowedLocations || defaultMarkets, [
    borrowedLocations,
    defaultMarkets,
  ]);

  const selectedMarketInd = useMemo(() => markets?.findIndex(m => m.key === selectedMarketKey), [
    markets,
    selectedMarketKey,
  ]);
  const ind = useMemo(() => (selectedMarketInd === -1 ? 0 : selectedMarketInd), [
    selectedMarketInd,
  ]);

  const search = useMemo(
    () =>
      stringify({
        address: markets[ind].key,
        bounds: encodeLatLngBounds(markets[ind].bounds),
        dates: `${stringifyDateToISO8601(CURRENT_DATE)},${stringifyDateToISO8601(nextDay)}`,
      }),
    [ind, markets]
  );

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <NamedLink className={css.imageContainer} name="SearchPage" to={{ search }}>
          <img src={landingImage1} className={css.mainImage} alt="landing-1" />
          <h1 className={css.bottomLeftImageText}>
            <FormattedMessage id="SectionHero.mainImageText" />
          </h1>
        </NamedLink>

        <div className={css.heroRightSection}>
          <NamedLink
            className={css.imageContainer}
            name="SearchPage"
            to={{ search: `${search}&pub_category=homeGardenTools` }}
          >
            <img src={landingImage2} className={css.asideImage} alt="landing-2" />
            <h1 className={css.bottomRightImageText}>
              <FormattedMessage id="SectionHero.rightTopImageText" />
            </h1>
          </NamedLink>
          <NamedLink
            className={css.imageContainer}
            name="SearchPage"
            to={{ search: `${search}&pub_category=sportingEquipmentAndFitness` }}
          >
            <img src={landingImage3} className={css.asideImage} alt="landing-3" />
            <h1 className={css.topRightImageText}>
              <FormattedMessage id="SectionHero.rightBottomImageText" />
            </h1>
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null, userLatLng: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  userLatLng: object,
};

export default SectionHero;
