import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCar.module.css';

const IconCar = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes} 
      version="1.0" 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 113.000000 79.000000">
        
    <g transform="translate(0.000000,79.000000) scale(0.100000,-0.100000)"
    fill="#707070" stroke="none">
    <path d="M240 779 c-44 -18 -58 -39 -105 -158 -38 -94 -52 -120 -76 -134 -17
    -9 -36 -32 -44 -51 -19 -45 -21 -236 -3 -254 7 -7 34 -12 60 -12 43 0 48 -2
    48 -22 0 -37 42 -99 80 -119 42 -23 120 -25 160 -4 38 19 68 59 80 106 l11 39
    113 0 114 0 6 -35 c8 -40 46 -90 87 -110 36 -19 112 -19 148 0 41 20 79 70 87
    110 6 33 9 35 44 35 69 0 70 1 70 112 0 84 -4 107 -21 138 -25 45 -83 80 -132
    80 -33 0 -41 8 -137 127 -55 70 -111 134 -123 142 -18 13 -61 16 -232 18 -126
    2 -220 -2 -235 -8z m170 -189 l0 -90 -105 0 c-58 0 -105 1 -105 3 0 2 12 32
    26 68 15 35 30 74 35 87 8 20 15 22 79 22 l70 0 0 -90z m305 13 c33 -42 64
    -82 68 -90 7 -11 -19 -13 -143 -13 l-150 0 0 90 0 90 83 0 82 -1 60 -76z
    m-374 -372 c35 -35 38 -77 9 -114 -60 -77 -187 -6 -150 83 26 62 95 77 141 31z
    m558 3 c25 -20 31 -34 31 -64 0 -30 -6 -44 -31 -64 -39 -33 -69 -33 -108 0
    -25 20 -31 34 -31 64 0 30 6 44 31 64 17 14 41 26 54 26 13 0 37 -12 54 -26z"/>
    </g>
    </svg>
  );
};

const { string } = PropTypes;

IconCar.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCar.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCar;
