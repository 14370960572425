import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, IconLocation, IconSearch, FieldSelect } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedMarketKey } from '../../ducks/BorrowedLocation.duck';

import css from './TopbarSearchForm.module.css';

const TopbarSearchFormComponent = props => {
  const searchInput = useRef();
  const dispatch = useDispatch();

  const { borrowedLocations: markets = [], selectedMarketKey } = useSelector(
    ({ BorrowedLocation }) => BorrowedLocation
  );

  const onSubmit = useCallback(
    values => {
      props.onSubmit(values);

      // blur search input to hide software keyboard
      if (searchInput.current) {
        searchInput.current.blur();
      }
    },
    [props]
  );

  const handleMarketChange = useCallback(key => dispatch(changeSelectedMarketKey(key)), [dispatch]);

  return (
    <FinalForm
      {...props}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          intl,
          isMobile,
          shouldShowMarket,
          handleSubmit,
        } = formRenderProps;

        const classes = classNames(rootClassName, className);

        const selectedMarketInd = markets?.findIndex(m => m.key === selectedMarketKey);
        const ind = selectedMarketInd === -1 ? 0 : selectedMarketInd;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <Field
              name="keywords"
              render={({ input }) => {
                return (
                  <React.Fragment>
                    <IconSearch />
                    <input
                      className={isMobile ? css.mobileInputRoot : css.desktopInputRoot}
                      {...input}
                      id="keyword-search"
                      ref={searchInput}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'TopbarSearchForm.placeholder',
                      })}
                      autoComplete="off"
                    />
                  </React.Fragment>
                );
              }}
            />
            {shouldShowMarket && markets && (
              <div className={css.locationRow}>
                <IconLocation className={css.iconLocation} />
                <FieldSelect
                  id="market-search"
                  name="market"
                  selectClassName={css.market}
                  defaultValue={markets[ind].key}
                  onChange={handleMarketChange}
                >
                  {markets.map(o => (
                    <option key={o.key} value={o.key}>
                      {o.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  shouldShowMarket: true,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  shouldShowMarket: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
