/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { array, bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { stringify } from '../../util/urlHelpers';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';
import { LISTING_TYPE, DEFAULT_TITLE } from '../../constants';
import { createSlug } from '../../util/urlHelpers';

import css from './TopbarMobileMenu.module.css';

const DEFAULT_SEARCH = `address=Seattle%2C%20Washington%2C%20United%20States&bounds=47.7788030383864%2C-122.218864003193%2C47.3506859580852%2C-122.435900673909`;

const UNAUTHORIZED_PAGES = [
  { linkProps: { name: 'SignupPageStepOne' }, messageId: 'TopbarMobileMenu.signupLink' },
  { linkProps: { name: 'LoginPage' }, messageId: 'TopbarMobileMenu.loginLink' },
  {
    linkProps: { name: 'SearchPage', to: { search: DEFAULT_SEARCH } },
    messageId: 'TopbarMobileMenu.borrow',
  },
  { linkProps: { name: 'WantedPage' }, messageId: 'TopbarMobileMenu.wantedPosts' },
  { linkProps: { name: 'NewListingPage' }, messageId: 'TopbarMobileMenu.listAnItem' },
  { linkProps: { name: 'LearnMoreBasePage' }, messageId: 'TopbarMobileMenu.learnMore' },
  { linkProps: { name: 'HelpPage' }, messageId: 'TopbarMobileMenu.getHelp' },
  { linkProps: { name: 'AboutPage' }, messageId: 'TopbarMobileMenu.aboutUs' },
];

const TopbarMobileMenu = props => {
  const {
    history,
    isAuthenticated,
    currentPage,
    currentUser,
    currentUserExpiringBookings,
    currentUserPendingBookings,
    onLogout,
    onCreateNewListing,
  } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={classNames(css.content, css.unauthorizedContent)}>
          {UNAUTHORIZED_PAGES.map((page, i) => (
            <NamedLink
              className={css.navigationLink}
              {...page.linkProps}
              key={`${page.messageId}_${i}`}
            >
              <FormattedMessage id={page.messageId} />
            </NamedLink>
          ))}
        </div>
      </div>
    );
  }

  const expiringBookingBadge =
    currentUserExpiringBookings?.length > 0 ? (
      <NotificationBadge
        className={css.expiringBookingBadge}
        count={currentUserExpiringBookings.length}
      />
    ) : null;

  const pendingBookingBadge =
    currentUserPendingBookings?.length > 0 ? (
      <NotificationBadge
        className={css.expiringBookingBadge}
        count={currentUserPendingBookings.length}
      />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ChatPage'))}
          name="ChatPage"
        >
          <FormattedMessage id="TopbarMobileMenu.messages" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MyBorrowedItemsPage'))}
          name="MyBorrowedItemsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.myBorrowedItems" />
          {expiringBookingBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MyWantedPostsPage'))}
          name="MyWantedPostsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.myWantedPosts" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('WantedPage'))}
          name="WantedPage"
          to={{ search: 'sort=publicData.startTime' }}
        >
          <FormattedMessage id="TopbarMobileMenu.wantedPosts" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('LenderDashboardPage'))}
          name="LenderDashboardPage"
          to={{ search: stringify({ dateRange: 'year' }) }}
        >
          <FormattedMessage id="TopbarMobileMenu.lenderDashboard" />
          {pendingBookingBadge}
        </NamedLink>

        <NamedLink name="NewListingPage" className={classNames(css.navigationLink, currentPageClass('NewListingPage'))}>
          <span
            name="NewListingPage"            
            onClick={() =>
              onCreateNewListing({
                title: DEFAULT_TITLE,
                publicData: {
                  listingType: LISTING_TYPE.LENDER_POST,
                },
              }).then(res => {
                const { uuid } = res.data.data.id;
                const slug = createSlug(res.data.data.attributes.title);
                history.push(`/l/${slug}/${uuid}/draft/description`, {
                  claimedItemId: currentUser?.id?.uuid,
                });
              })
            }
          >
            <FormattedMessage id="TopbarDesktop.listAnItem" />
          </span>
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.manageListings" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('HelpPage'))}
          name="HelpPage"
        >
          <FormattedMessage id="TopbarMobileMenu.getHelp" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentUserExpiringBookings: [],
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  currentUserExpiringBookings: array,
  onLogout: func.isRequired,
  onCreateNewListing: func.isRequired,
};

export default TopbarMobileMenu;
