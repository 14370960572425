import React from 'react';
import { arrayOf, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldCheckboxGroup } from '../../components';

import css from './FieldMultiselect.module.css';

const FieldMultiselectComponent = props => {
  const { rootClassName, className, selectClassName, id, label, options, name } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const classes = classNames(rootClassName || css.root, className);
  const selectClasses = classNames(selectClassName || css.select);

  return (
    <div className={classes}>
      <FieldCheckboxGroup
        id={id}
        name={name}
        label={label}
        options={options}
        className={selectClasses}
      />
    </div>
  );
};

FieldMultiselectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

FieldMultiselectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
};

export default FieldMultiselectComponent;
