import React, { useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { bool, func, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldMultiselect } from '../../components';
import { GENDER_PRONOUNS } from '../../constants';

import css from './SaveNameAndPronounsForm.module.css';

const SaveNameAndPronounsForm = props => {
  const [editMode, setEditMode] = useState(false);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          saveError,
          saveInProgress,
          saveSent,
          handleSubmit,
          intl,
          invalid,
          disabled,
          initialValues,
          isBusinessAccount,
        } = fieldRenderProps;
        const { firstName, lastName, pronouns, businessName } = initialValues;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid || disabled || saveInProgress;

        return (
          <Form
            className={classes}
            onSubmit={v => {
              setEditMode(false);
              handleSubmit(v);
            }}
          >
            <div className={css.container}>
              <div className={css.formRow}>
                {editMode ? (
                  <div className={css.formRow}>
                    {isBusinessAccount ? (
                      <FieldTextInput
                        id="businessName"
                        className={css.inputBusiness}
                        name="businessName"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'SaveNameAndPronounsForm.businessNamePlaceholder',
                        })}
                        validate={required(
                          intl.formatMessage({ id: 'SaveNameAndPronounsForm.businessNameRequired' })
                        )}
                      />
                    ) : (
                      <>
                        <FieldTextInput
                          id="firstName"
                          className={css.input}
                          name="firstName"
                          type="text"
                          placeholder={intl.formatMessage({
                            id: 'SaveNameAndPronounsForm.firstNamePlaceholder',
                          })}
                          validate={required(
                            intl.formatMessage({ id: 'SaveNameAndPronounsForm.firstNameRequired' })
                          )}
                        />
                        <FieldTextInput
                          id="lastName"
                          className={css.input}
                          name="lastName"
                          type="text"
                          placeholder={intl.formatMessage({
                            id: 'SaveNameAndPronounsForm.lastNamePlaceholder',
                          })}
                          validate={required(
                            intl.formatMessage({ id: 'SaveNameAndPronounsForm.lastNameRequired' })
                          )}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <div className={css.userName}>
                    {isBusinessAccount ? businessName : ` ${firstName} ${lastName}`}
                  </div>
                )}
              </div>
              <div className={css.formRow}>
                {editMode ? (
                  <FieldMultiselect
                    id="pronouns"
                    name="pronouns"
                    selectClassName={css.pronouns}
                    rootClassName={css.pronounsMargin}
                    options={Object.values(GENDER_PRONOUNS).map(g => ({ key: g, label: g }))}
                  />
                ) : (
                  <div>
                    {pronouns &&
                      pronouns?.length > 0 &&
                      Array.isArray(pronouns) &&
                      `(${pronouns
                        .filter(p => p !== GENDER_PRONOUNS.none || p !== GENDER_PRONOUNS.other)
                        .join(', ')})`}
                  </div>
                )}
              </div>
              {saveError && (
                <div className={css.error}>
                  {intl.formatMessage({
                    id: isBusinessAccount
                      ? 'SaveNameAndPronounsForm.saveBusinessError'
                      : 'SaveNameAndPronounsForm.saveError',
                  })}
                </div>
              )}
            </div>
            {editMode ? (
              <PrimaryButton
                key="submit-button"
                className={css.button}
                inProgress={saveInProgress}
                disabled={submitDisabled}
                type="submit"
              >
                {intl.formatMessage({ id: 'SaveNameAndPronounsForm.save' })}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                key="edit-button"
                className={css.button}
                ready={saveSent}
                inProgress={saveInProgress}
                disabled={submitDisabled}
                type="button"
                onClick={() => setEditMode(true)}
              >
                {intl.formatMessage({ id: 'SaveNameAndPronounsForm.edit' })}
              </PrimaryButton>
            )}
          </Form>
        );
      }}
    />
  );
};

SaveNameAndPronounsForm.defaultProps = {
  rootClassName: null,
  className: null,
  saveError: null,
};

SaveNameAndPronounsForm.propTypes = {
  rootClassName: string,
  className: string,

  intl: intlShape.isRequired,

  saveSent: bool.isRequired,
  saveInProgress: bool.isRequired,
  saveError: propTypes.error,

  onSubmit: func.isRequired,
};

export default SaveNameAndPronounsForm;
