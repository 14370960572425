import React, { useCallback, useMemo, useRef, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { DEFAULT_TITLE } from '../../constants';
import { EditListingDescriptionForm, EditListingWantedForm } from '../../forms';
import config from '../../config';
import GeocoderMapbox from '../LocationAutocompleteInput/GeocoderMapbox';
import { getPlaceAddressPart, placeOrigin } from '../../util/maps';
import { dateToMoment } from '../../util/dates';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    isWantedType,
    errors,
  } = props;

  const geocoder = useRef();

  const [isFetchingCity, setIsFetchingCity] = useState(false);
  const [hasFetchCityError, setHasFetchCityError] = useState(null);

  const categoryOptions = useMemo(
    () => findOptionsForSelectFilter('category', config.custom.filters),
    []
  );
  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);
  const currentListing = useMemo(() => ensureOwnListing(listing), [listing]);

  const { description, title, publicData, price } = useMemo(() => currentListing.attributes, [
    currentListing.attributes,
  ]);

  const {
    accessoriesIncluded,
    category,
    subCategory,
    zipCode,
    borrowingType,
    startTime,
    endTime,
    quantity,
  } = useMemo(() => publicData || {}, [publicData]);

  const isPublished = useMemo(
    () => currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT,
    [currentListing]
  );
  const panelTitle = useMemo(
    () =>
      isPublished ? (
        <FormattedMessage
          id="EditListingDescriptionPanel.title"
          values={{
            listingTitle: <ListingLink listing={listing}>{listing.attributes.title}</ListingLink>,
          }}
        />
      ) : (
        <FormattedMessage
          id={
            isWantedType
              ? 'EditListingDescriptionPanel.createWantedListingTitle'
              : 'EditListingDescriptionPanel.createLenderListingTitle'
          }
        />
      ),
    [isPublished, isWantedType, listing]
  );

  const initialValuesForWantedPost = useMemo(() => {
    if (!borrowingType) {
      return { title: title === DEFAULT_TITLE ? null : title };
    }

    if (borrowingType === 'hourly') {
      return {
        title: title === DEFAULT_TITLE ? null : title,
        description,
        price,
        borrowingType,
        hourlyDate: {
          date: dateToMoment(startTime)
            .startOf('day')
            .toDate(),
        },
        category,
        subCategory,
        quantity,
        startTime: dateToMoment(startTime).format('HH:mm'),
        endTime: dateToMoment(endTime).format('HH:mm'),
        zipCode,
      };
    }

    return {
      title: title === DEFAULT_TITLE ? null : title,
      description,
      price,
      borrowingType,
      category,
      subCategory,
      quantity,
      startDate: { date: new Date(startTime) },
      endDate: { date: new Date(endTime) },
      zipCode,
    };
  }, [
    borrowingType,
    category,
    description,
    endTime,
    price,
    quantity,
    startTime,
    subCategory,
    title,
    zipCode,
  ]);

  const getGeocoder = useCallback(() => {
    if (!geocoder.current) {
      geocoder.current = new GeocoderMapbox();
    }

    return geocoder.current;
  }, []);

  const getCityFromZipCode = useCallback(
    async zipCode => {
      try {
        setIsFetchingCity(true);
        setHasFetchCityError(false);

        const { predictions } = await getGeocoder().getPlacePredictions(zipCode);

        const firstPrediction = predictions[0];

        const city = getPlaceAddressPart(firstPrediction, 'place');
        const origin = placeOrigin(firstPrediction);

        if (city && origin) {
          return { city, origin };
        }

        setHasFetchCityError(true);
      } catch (error) {
        setHasFetchCityError(true);
      } finally {
        setIsFetchingCity(false);
      }

      return null;
    },
    [getGeocoder]
  );

  const handleWantedFormSubmit = useCallback(
    async formValues => {
      const {
        title,
        price,
        borrowingType,
        zipCode,
        category,
        subCategory,
        quantity,
        description,
      } = formValues;

      const cityWithCoords = await getCityFromZipCode(zipCode);

      if (!cityWithCoords) return;

      const updateValues = {
        title: title.trim(),
        description,
        price,
        geolocation: cityWithCoords.origin,
        publicData: {
          borrowingType,
          category,
          subCategory,
          quantity,
          zipCode,
          city: cityWithCoords.city,
        },
      };

      if (borrowingType === 'hourly') {
        const { hourlyDate, startTime, endTime } = formValues;

        const [startHours, startMinutes] = startTime.split(':');
        const [endHours, endMinutes] = endTime.split(':');

        const start = dateToMoment(hourlyDate.date)
          .hours(startHours)
          .minutes(startMinutes)
          .startOf('minute')
          .valueOf();
        const end = dateToMoment(hourlyDate.date)
          .hours(endHours)
          .minutes(endMinutes)
          .startOf('minute')
          .valueOf();

        onSubmit({
          ...updateValues,
          publicData: {
            ...updateValues.publicData,
            startTime: start,
            endTime: end,
          },
        });
      } else {
        const { startDate, endDate } = formValues;

        const startTime = dateToMoment(startDate.date)
          .startOf('day')
          .valueOf();
        const endTime = dateToMoment(endDate.date)
          .startOf('day')
          .valueOf();

        onSubmit({
          ...updateValues,
          publicData: {
            ...updateValues.publicData,
            startTime,
            endTime,
          },
        });
      }
    },
    [getCityFromZipCode, onSubmit]
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {isWantedType ? (
        <EditListingWantedForm
          className={css.form}
          initialValues={initialValuesForWantedPost}
          saveActionMsg={submitButtonText}
          onSubmit={handleWantedFormSubmit}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          categoryOptions={categoryOptions}
          hasFetchCityError={hasFetchCityError}
          isFetchingCity={isFetchingCity}
        />
      ) : (
        <EditListingDescriptionForm
          className={css.form}
          initialValues={{
            title: title === DEFAULT_TITLE ? null : title,
            accessoriesIncluded,
            description,
            category,
            subCategory,
          }}
          saveActionMsg={submitButtonText}
          onSubmit={values => {
            const { title, accessoriesIncluded, description, category, subCategory } = values;
            const updateValues = {
              title: title.trim(),
              description,
              publicData: { category, subCategory, accessoriesIncluded },
            };

            onSubmit(updateValues);
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          categoryOptions={categoryOptions}
        />
      )}
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  isWantedType: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
