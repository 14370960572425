import React from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, FieldSelect, Button } from '../../components';
import { composeValidators, emailFormatValid, required } from '../../util/validators';

import css from './ContactUsForm.module.css';

const issueTypes = [
  { key: 'borrower_support', label: 'Borrower support' },
  { key: 'lender_support', label: 'Lender Support' },
  { key: 'other', label: 'Other' },
];

const ContactUsForm = props => {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          intl,
          className,
          rootClassName,
          handleSubmit,
          submitInProgress,
          contactUsError,
          form,
          ready,
          invalid,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        // issue type
        const issueTypeLabel = intl.formatMessage({ id: 'ContactUsForm.issueTypeLabel' });
        const issueTypeRequiredMessage = intl.formatMessage({
          id: 'ContactUsForm.issueTypeRequiredMessage',
        });
        const issueTypeRequired = required(issueTypeRequiredMessage);

        // contact email
        const contactEmailPlaceholder = intl.formatMessage({
          id: 'ContactUsForm.contactEmailPlaceholder',
        });
        const contactEmailRequiredMessage = intl.formatMessage({
          id: 'ContactUsForm.contactEmailRequiredMessage',
        });
        const contactEmailRequired = required(contactEmailRequiredMessage);
        const contactEmailInvalidMessage = intl.formatMessage({
          id: 'ContactUsForm.contactEmailInvalidMessage',
        });
        const contactEmailInvalid = emailFormatValid(contactEmailInvalidMessage);
        const contactImailValidators = composeValidators(contactEmailRequired, contactEmailInvalid);

        // issue content
        const issueContentPlaceholder = intl.formatMessage({
          id: 'ContactUsForm.issueContentPlaceholder',
        });
        const issueContentRequiredMessage = intl.formatMessage({
          id: 'ContactUsForm.issueContentRequiredMessage',
        });
        const issueContentRequired = required(issueContentRequiredMessage);

        // description text
        const description = intl.formatMessage({ id: 'ContactUsForm.descriptionText' });

        // submit button
        const submitText = intl.formatMessage({ id: 'ContactUsForm.submitText' });
        const submitDisabled = invalid || submitInProgress;

        const error = intl.formatMessage({ id: 'ContactUsForm.error' });

        return (
          <Form
            className={classes}
            onSubmit={values => {
              handleSubmit(values).then(() => form.restart());
            }}
          >
            <FieldSelect
              className={css.select}
              id="issueType"
              name="issueType"
              validate={issueTypeRequired}
            >
              <option disabled value="">
                {issueTypeLabel}
              </option>
              {issueTypes.map(o => (
                <option value={o.label} key={o.key}>
                  {o.label}
                </option>
              ))}
            </FieldSelect>
            <FieldTextInput
              className={css.contactEmail}
              id="contactEmail"
              name="contactEmail"
              type="text"
              placeholder={contactEmailPlaceholder}
              autoComplete="email"
              validate={contactImailValidators}
            />

            <FieldTextInput
              className={css.input}
              inputRootClass={css.issue}
              id="issue"
              name="issue"
              type="textarea"
              placeholder={issueContentPlaceholder}
              autoComplete="off"
              validate={issueContentRequired}
            />

            <div className={css.description}>{description}</div>

            {contactUsError && <div className={css.error}>{error}</div>}

            <Button
              disabled={submitDisabled}
              ready={ready}
              inProgress={submitInProgress}
              className={css.submit}
              type="submit"
            >
              {submitText}
            </Button>
          </Form>
        );
      }}
    />
  );
};

ContactUsForm.defaultProps = {
  className: null,
  rootClassName: null,
  contactUsError: null,
};

ContactUsForm.propTypes = {
  className: string,
  rootClassName: string,
  onSubmit: func.isRequired,
  submitInProgress: bool.isRequired,
  ready: bool.isRequired,
};

export default ContactUsForm;
