import React, { useMemo } from 'react';
import { string, arrayOf, object, shape, oneOfType, node, number, bool, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import IconSpinner from '../IconSpinner/IconSpinner';
import Overlay from '../ManageListingCard/Overlay';
import PaginationLinks from '../PaginationLinks/PaginationLinks';
import { propTypes } from '../../util/types';

import css from './ItemTable.module.css';

const ItemTable = props => {
  const {
    intl,
    className,
    spacingClassName,
    tableHeaders,
    fetchInProgress,
    fetchError,
    itemActionInProgress,
    itemActionError,
    items,
    noItemsMessage,
    usePagination,
    pagination,
    onFetchPage,
  } = props;

  const classes = useMemo(() => classNames(css.root, className), [className]);

  return fetchError ? (
    intl.formatMessage({
      id: 'ItemTable.actionFailed',
    })
  ) : !fetchInProgress ? (
    <>
      <table className={classes}>
        <thead className={css.header}>
          <tr>
            {tableHeaders.map(header => (
              <th key={header.key} className={css.tableHeader}>
                {header.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.length === 0 && (
            <tr className={css.tableRowEmpty}>
              <td colSpan={tableHeaders.length}>{noItemsMessage}</td>
            </tr>
          )}
          {items.map((item, itemIndex) => {
            const { key, value } = item;

            const data = Object.values(value);

            return (
              <React.Fragment key={key}>
                <tr className={css.tableRow}>
                  {data.map((d, i) => (
                    <td key={key + i}>{d}</td>
                  ))}

                  {itemActionInProgress === key ? (
                    <Overlay>
                      <IconSpinner />
                    </Overlay>
                  ) : itemActionError === key ? (
                    <Overlay
                      errorMessage={intl.formatMessage({
                        id: 'ItemTable.actionFailed',
                      })}
                      errorMessageClassName={css.overlay}
                    />
                  ) : null}
                </tr>
                {itemIndex < items.length - 1 && (
                  <tr className={classNames(css.spacing, spacingClassName)}>
                    <td colSpan={tableHeaders.length} />
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {usePagination && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          useButtons
          className={css.pagination}
          onFetch={onFetchPage}
          pagination={pagination}
        />
      ) : null}
    </>
  ) : (
    <div className={css.spinner}>
      <IconSpinner />
    </div>
  );
};

ItemTable.defaultProps = {
  className: null,
  spacingClassName: null,
  items: [],
  tableHeaders: [],
  fetchInProgress: false,
  fetchError: false,
};

ItemTable.propTypes = {
  className: string,
  spacingClassName: string,
  intl: intlShape.isRequired,

  fetchInProgress: bool,
  fetchError: bool,
  itemActionInProgress: oneOfType([string, number]),
  itemActionError: oneOfType([string, number]),

  usePagination: bool,
  pagination: propTypes.pagination,
  onFetchPage: func,

  tableHeaders: arrayOf(
    shape({ key: oneOfType([string, number]).isRequired, value: oneOfType([string, node]) })
      .isRequired
  ).isRequired,
  items: arrayOf(
    shape({ key: oneOfType([string, number]).isRequired, value: object.isRequired }).isRequired
  ).isRequired,
  noItemsMessage: oneOfType([string, node]).isRequired,
};

export default injectIntl(ItemTable);
