import React, { useCallback, useMemo, useState } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { intlShape } from '../../util/reactIntl';
import {
  getStartHours,
  getEndHours,
  isInRange,
  isSameDate,
  isDayMomentInsideRange,
  resetToStartOfDay,
  dateIsAfter,
  findNextBoundary,
  timestampToDate,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
  getDayOfWeekStringFromDate,
  convertTime12To24,
  dateToMoment,
  addHoursToDate,
  calculateQuantityFrom,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import { bookingDateRequired } from '../../util/validators';
import { FieldDateInput, FieldSelect } from '../../components';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import css from './FieldDateAndTimeInput.module.css';

// MAX_TIME_SLOTS_RANGE is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;

const TODAY = new Date();

const endOfRange = date => {
  return resetToStartOfDay(date, undefined, MAX_TIME_SLOTS_RANGE - 1);
};

const getFirstAvailableToday = () => {
  const todayMoment = dateToMoment();

  const nextHourMoment = todayMoment
    .clone()
    .startOf('hour')
    .add(1, 'hour');

  const minutesDiff = calculateQuantityFrom(todayMoment, nextHourMoment, 'minutes');

  // If there are less than 10 minutes until the hour changes
  // we should use the next hour
  if (minutesDiff <= 10) {
    return nextHourMoment.toDate();
  }

  return todayMoment.toDate();
};

const getMonthlyTimeSlots = monthlyTimeSlots => {
  if (!monthlyTimeSlots || !monthlyTimeSlots.timeSlots) return [];

  return monthlyTimeSlots.timeSlots;
};

const filterHoursByPickUpDropOff = (hours, pickUpDropOff, isEndHours) =>
  hours.filter(({ timeOfDay }) => {
    // Transform the AM/PM time to 24h
    const timeStringNormalized = convertTime12To24(timeOfDay);

    return pickUpDropOff.some(pickUp => {
      const { startTime, endTime } = pickUp;

      const isHourBeforePickUpEnd = isEndHours
        ? timeStringNormalized <= endTime
        : timeStringNormalized < endTime;

      return timeStringNormalized >= startTime && isHourBeforePickUpEnd;
    });
  });

const Next = props => {
  const { currentMonth } = props;
  const nextMonthDate = nextMonthFn(currentMonth);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY)) ? null : <NextMonthIcon />;
};
const Prev = props => {
  const { currentMonth } = props;
  const prevMonthDate = prevMonthFn(currentMonth);
  const currentMonthDate = getMonthStartInTimeZone(TODAY);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

const getTimeSlots = (timeSlots, date) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter(t => {
        if (!date) return false;

        const { start, end } = t.attributes;

        const millisecondBeforeEndTime = new Date(end.getTime() - 1);

        return dateToMoment(date).isBetween(start, millisecondBeforeEndTime, 'day', '[]');
      })
    : [];
};

// We want to find the final TS that is connected through this date,
// because TS's are split when the seats differ
const findLastConnectedTSEndDate = (timeSlots, endDate) => {
  if (!timeSlots || !timeSlots[0]) return null;

  let connectedSlot = endDate;

  for (let index = 0; index < timeSlots.length; index++) {
    const { start, end } = timeSlots[index].attributes;

    if (isSameDate(connectedSlot, start)) {
      connectedSlot = end;
    }
  }

  return connectedSlot;
};

const FieldDateAndTimeInput = props => {
  const {
    rootClassName,
    className,
    formId,
    startDateInputProps,
    endDateInputProps,
    values,
    monthlyTimeSlots,
    intl,
    timeZone,
    listingId,
    onFetchTimeSlots,
    onMonthChanged,
    form,
    pickUpDropOffPlan,
  } = props;

  const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(TODAY));

  const classes = classNames(rootClassName || css.root, className);

  const { bookingStartDate: bookingStart, bookingStartTime, bookingEndDate: bookingEnd } = useMemo(
    () => values,
    [values]
  );

  const { date: bookingStartDate } = useMemo(() => bookingStart || {}, [bookingStart]);
  const { date: bookingEndDate } = useMemo(() => bookingEnd || {}, [bookingEnd]);

  const startTimeDisabled = useMemo(() => !bookingStartDate || !bookingEndDate, [
    bookingEndDate,
    bookingStartDate,
  ]);
  const endDateDisabled = useMemo(() => !bookingStartDate, [bookingStartDate]);
  const endTimeDisabled = useMemo(() => !bookingStartDate || !bookingStartTime || !bookingEndDate, [
    bookingEndDate,
    bookingStartDate,
    bookingStartTime,
  ]);

  const placeholderStartTime = useMemo(
    () => intl.formatMessage({ id: 'BookingTimeForm.startTime' }),
    [intl]
  );

  const placeholderEndTime = useMemo(() => intl.formatMessage({ id: 'BookingTimeForm.endTime' }), [
    intl,
  ]);

  const timeSlotsOnSelectedMonth = useMemo(() => getMonthlyTimeSlots(monthlyTimeSlots), [
    monthlyTimeSlots,
  ]);
  const timeSlotsOnSelectedDate = useMemo(
    () => getTimeSlots(timeSlotsOnSelectedMonth, bookingStartDate),
    [bookingStartDate, timeSlotsOnSelectedMonth]
  );

  const isPickUpDropOffAvailable = useCallback(
    date => {
      const dayOfWeek = getDayOfWeekStringFromDate(date, timeZone);

      if (!pickUpDropOffPlan || !pickUpDropOffPlan[dayOfWeek]) return false;

      return pickUpDropOffPlan[dayOfWeek].length > 0;
    },
    [pickUpDropOffPlan, timeZone]
  );

  const getPickUpDropOffSlots = useCallback(
    date => {
      if (!pickUpDropOffPlan) return [];

      const dayOfWeek = getDayOfWeekStringFromDate(date, timeZone);

      return pickUpDropOffPlan[dayOfWeek];
    },
    [pickUpDropOffPlan, timeZone]
  );

  const fetchMonthData = useCallback(
    date => {
      const endOfRangeDate = endOfRange(TODAY);

      // Don't fetch timeSlots for past months or too far in the future
      if (isInRange(date, TODAY, endOfRangeDate)) {
        // Use "today", if the first day of given month is in the past
        const start = dateIsAfter(TODAY, date) ? TODAY : date;

        // Use endOfRangeDate, if the first day of the next month is too far in the future
        const nextMonthDate = nextMonthFn(date);
        const end = dateIsAfter(nextMonthDate, endOfRangeDate)
          ? resetToStartOfDay(endOfRangeDate, undefined, 0)
          : nextMonthDate;

        // Fetch time slots for given time range
        onFetchTimeSlots(listingId, start, end);
      }
    },
    [listingId, onFetchTimeSlots]
  );

  const onMonthClick = useCallback(
    monthFn => {
      let nextMonth;

      setCurrentMonth(prevMonth => {
        nextMonth = monthFn(prevMonth);

        return nextMonth;
      });

      // Callback function after month has been updated.
      // react-dates component has next and previous months ready (but inivisible).
      // we try to populate those invisible months before user advances there.
      fetchMonthData(monthFn(nextMonth));

      // If previous fetch for month data failed, try again.
      const monthId = monthIdStringInTimeZone(nextMonth);
      if (monthlyTimeSlots?.fetchTimeSlotsError) {
        fetchMonthData(nextMonth);
      }

      // Call onMonthChanged function if it has been passed in among props.
      if (onMonthChanged) {
        onMonthChanged(monthId);
      }
    },
    [fetchMonthData, monthlyTimeSlots, onMonthChanged]
  );

  const getAvailableStartTimes = useCallback(
    (bookingStart, timeSlotsOnSelectedDate) => {
      if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
        return [];
      }
      const bookingStartDate = resetToStartOfDay(bookingStart);

      const today = getFirstAvailableToday();

      const nextHour = addHoursToDate(today, 1, null);
      const nextAvailableHour = addHoursToDate(today, 2, null);

      const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
        const startDate = t.attributes.start;
        const endDate = t.attributes.end;
        const nextDate = resetToStartOfDay(bookingStartDate, null, 1);

        // If the start date is after timeslot start, use the start date.
        // Otherwise use the timeslot start time.
        const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;

        // Remove the first hour option if it's incoming in <=1h since that bookings will
        // always auto-cancel due to the Borrowed Cancellation Policy that says:
        // "All proposed borrow requests will auto cancel within 1 hour of the proposed start time..."
        const withoutIncomingHourMaybe = dateIsAfter(nextHour, startLimit)
          ? nextAvailableHour
          : startLimit;

        // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
        // Otherwise use the end of the timeslot.
        const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;

        const hours = getStartHours(intl, undefined, withoutIncomingHourMaybe, endLimit);
        return availableHours.concat(hours);
      }, []);

      const pickUpDropOff = getPickUpDropOffSlots(bookingStart, pickUpDropOffPlan, timeZone);

      // We need to limit the start times by
      // seeing if they are inside the pick up times
      const hoursLimitedByPickUp = filterHoursByPickUpDropOff(allHours, pickUpDropOff);

      return hoursLimitedByPickUp;
    },
    [getPickUpDropOffSlots, intl, pickUpDropOffPlan, timeZone]
  );

  const availableStartTimes = useMemo(
    () => getAvailableStartTimes(bookingStartDate, timeSlotsOnSelectedDate),
    [bookingStartDate, getAvailableStartTimes, timeSlotsOnSelectedDate]
  );

  const getAvailableEndTimes = useCallback(
    (bookingStartTime, bookingEndDate, selectedTimeSlot) => {
      if (
        !selectedTimeSlot ||
        !selectedTimeSlot.attributes ||
        !bookingEndDate ||
        !bookingStartTime
      ) {
        return [];
      }

      const selectedTSEnd = selectedTimeSlot.attributes.end;

      // We want to find the final TS that is connected through this date,
      // because TS's are split when the seats differ
      const lastConnectedTsEnd = findLastConnectedTSEndDate(
        timeSlotsOnSelectedMonth,
        selectedTSEnd
      );

      const endDate = lastConnectedTsEnd || selectedTSEnd;

      const bookingStartTimeAsDate = timestampToDate(bookingStartTime);

      const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, undefined, 1);
      const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, undefined, 1);
      const startOfEndDay = resetToStartOfDay(bookingEndDate);

      let startLimit;
      let endLimit;

      if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
        startLimit = bookingStartTimeAsDate;
        endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
      } else {
        // If the end date is on the same day as the selected booking start time
        // use the start time as limit. Otherwise use the start of the selected end date.
        startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
          ? bookingStartTimeAsDate
          : startOfEndDay;

        // If the selected end date is on the same day as timeslot end, use the timeslot end.
        // Else use the start of the next day after selected date.
        endLimit = isSameDate(resetToStartOfDay(endDate), startOfEndDay)
          ? endDate
          : dayAfterBookingEnd;
      }

      const endHours = getEndHours(intl, undefined, startLimit, endLimit);

      const pickUpDropOff = getPickUpDropOffSlots(
        new Date(endLimit - 1),
        pickUpDropOffPlan,
        timeZone
      );

      // We need to limit the end times by
      // seeing if they are inside the drop off times
      const hoursLimitedByDropOff = filterHoursByPickUpDropOff(endHours, pickUpDropOff, true);

      return hoursLimitedByDropOff;
    },
    [getPickUpDropOffSlots, intl, pickUpDropOffPlan, timeSlotsOnSelectedMonth, timeZone]
  );

  // Use start date to calculate the first possible start time or times, end date and end time or times.
  // If the selected value is passed to function it will be used instead of calculated value.
  const getAllTimeValues = useCallback(
    (timeSlots, startDate, selectedStartTime, selectedEndDate) => {
      const startTimes = selectedStartTime
        ? []
        : getAvailableStartTimes(startDate, getTimeSlots(timeSlots, startDate));

      // Value selectedStartTime is a string when user has selected it through the form.
      // That's why we need to convert also the timestamp we use as a default
      // value to string for consistency. This is expected later when we
      // want to compare the sartTime and endTime.
      const startTime = selectedStartTime
        ? selectedStartTime
        : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
        ? startTimes[0].timestamp.toString()
        : null;

      const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

      // Note: We need to remove 1ms from the calculated endDate so that if the end
      // date would be the next day at 00:00 the day in the form is still correct.
      // Because we are only using the date and not the exact time we can remove the
      // 1ms.
      const endDate = selectedEndDate
        ? selectedEndDate
        : startTimeAsDate
        ? findNextBoundary(undefined, startTimeAsDate)
        : null;

      const selectedTimeSlot = timeSlots.find(t =>
        isInRange(startTimeAsDate, t.attributes.start, t.attributes.end)
      );

      const endTimes = getAvailableEndTimes(startTime, endDate, selectedTimeSlot);

      // We need to convert the timestamp we use as a default value
      // for endTime to string for consistency. This is expected later when we
      // want to compare the sartTime and endTime.
      const endTime =
        endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp
          ? endTimes[0].timestamp.toString()
          : null;

      return { startTime, endDate, endTime, selectedTimeSlot };
    },
    [getAvailableEndTimes, getAvailableStartTimes]
  );

  const firstAvailableStartTime = useMemo(
    () =>
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null,
    [availableStartTimes]
  );

  const { startTime, endDate, selectedTimeSlot } = useMemo(
    () =>
      getAllTimeValues(
        timeSlotsOnSelectedDate,
        bookingStartDate,
        bookingStartTime || firstAvailableStartTime,
        bookingEndDate || bookingStartDate
      ),
    [
      bookingEndDate,
      bookingStartDate,
      bookingStartTime,
      firstAvailableStartTime,
      getAllTimeValues,
      timeSlotsOnSelectedDate,
    ]
  );

  const availableEndTimes = useMemo(
    () =>
      getAvailableEndTimes(
        bookingStartTime || startTime,
        bookingEndDate || endDate,
        selectedTimeSlot
      ),
    [bookingEndDate, bookingStartTime, endDate, getAvailableEndTimes, selectedTimeSlot, startTime]
  );

  const onBookingStartDateChange = useCallback(
    value => {
      if (!value || !value.date) {
        form.batch(() => {
          form.change('bookingStartTime', null);
          form.change('bookingEndDate', { date: null });
          form.change('bookingEndTime', null);
        });
        // Reset the currentMonth too if bookingStartDate is cleared
        setCurrentMonth(getMonthStartInTimeZone(TODAY));

        return;
      }

      const startDate = value.date;
      const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots);
      const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate);

      const { startTime, endDate, endTime } = getAllTimeValues(timeSlotsOnSelectedDate, startDate);

      form.batch(() => {
        form.change('bookingStartTime', startTime);
        form.change('bookingEndDate', { date: endDate });
        form.change('bookingEndTime', endTime);
      });
    },
    [form, getAllTimeValues, monthlyTimeSlots]
  );

  const onBookingStartTimeChange = useCallback(
    value => {
      const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots);
      const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, bookingStartDate);

      const { endDate, endTime } = getAllTimeValues(
        timeSlotsOnSelectedDate,
        bookingStartDate,
        value
      );

      form.batch(() => {
        form.change('bookingEndDate', { date: endDate });
        form.change('bookingEndTime', endTime);
      });
    },
    [bookingStartDate, form, getAllTimeValues, monthlyTimeSlots]
  );

  const onBookingEndDateChange = useCallback(
    value => {
      if (!value || !value.date) {
        form.change('bookingEndTime', null);
        return;
      }

      const endDate = value.date;

      const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots);
      const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, bookingStartDate);

      const { endTime } = getAllTimeValues(
        timeSlotsOnSelectedDate,
        bookingStartDate,
        bookingStartTime,
        endDate
      );

      form.change('bookingEndTime', endTime);
    },
    [bookingStartDate, bookingStartTime, form, getAllTimeValues, monthlyTimeSlots]
  );

  const isOutsideRange = useCallback(
    (day, bookingStartDate, selectedTimeSlot) => {
      if (!selectedTimeSlot) {
        return true;
      }

      // Given day (endDate) should be after the start of the day of selected booking start date.
      const startDate = resetToStartOfDay(bookingStartDate);
      // 00:00 would return wrong day as the end date.
      // Removing 1 millisecond, solves the exclusivity issue.
      const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
      // Given day (endDate) should be before the "next" day of selected timeSlots end.
      const endDate = resetToStartOfDay(inclusiveEnd, null, 1);

      // We want to find the final TS that is connected through this date,
      // because TS's are split when the seats differ
      const lastConnectedTsEnd = findLastConnectedTSEndDate(
        timeSlotsOnSelectedMonth,
        selectedTimeSlot.attributes.end
      );

      let endDateFinal = lastConnectedTsEnd || endDate;

      return !(dateIsAfter(day, startDate) && dateIsAfter(endDateFinal, day));
    },
    [timeSlotsOnSelectedMonth]
  );

  const isDayBlocked = useMemo(
    () =>
      timeSlotsOnSelectedMonth
        ? day => {
            if (!isPickUpDropOffAvailable(day, pickUpDropOffPlan, timeZone)) return true;

            return !timeSlotsOnSelectedMonth.find(timeSlot =>
              isDayMomentInsideRange(day, timeSlot.attributes.start, timeSlot.attributes.end)
            );
          }
        : () => false,
    [isPickUpDropOffAvailable, pickUpDropOffPlan, timeSlotsOnSelectedMonth, timeZone]
  );

  return (
    <div className={classes}>
      <div className={css.formRow}>
        <div className={css.formRowLabel}>{intl.formatMessage({ id: 'BookingTimeForm.from' })}</div>
        <div className={css.fields}>
          <div className={classNames(css.field)}>
            <FieldDateInput
              className={css.input}
              name="bookingStartDate"
              id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
              label={startDateInputProps.label}
              placeholderText={startDateInputProps.placeholderText}
              isDayBlocked={isDayBlocked}
              onChange={onBookingStartDateChange}
              onPrevMonthClick={() => onMonthClick(prevMonthFn)}
              onNextMonthClick={() => onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={currentMonth} />}
              navPrev={<Prev currentMonth={currentMonth} />}
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              onClose={event => setCurrentMonth(getMonthStartInTimeZone(event?.date ?? TODAY))}
              readOnly
            />
          </div>
          <div
            className={classNames(css.field, {
              [css.hideField]: !(bookingStartDate && bookingEndDate),
            })}
          >
            <FieldSelect
              name="bookingStartTime"
              id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
              className={css.input}
              disabled={startTimeDisabled}
              onChange={onBookingStartTimeChange}
            >
              <option disabled value="">
                {placeholderStartTime}
              </option>

              {bookingStartDate
                ? availableStartTimes.map(p => (
                    <option key={p.timeOfDay} value={p.timestamp}>
                      {p.timeOfDay}
                    </option>
                  ))
                : null}
            </FieldSelect>
          </div>
        </div>
      </div>
      <div className={classNames(css.formRow, { [css.endDateHidden]: !bookingStartDate })}>
        <div className={css.formRowLabel}>{intl.formatMessage({ id: 'BookingTimeForm.to' })}</div>
        <div className={css.fields}>
          <div className={css.field}>
            <FieldDateInput
              {...endDateInputProps}
              name="bookingEndDate"
              id={formId ? `${formId}.bookingEndDate` : 'bookingEndDate'}
              className={css.input}
              label={endDateInputProps.label}
              placeholderText={endDateInputProps.placeholderText}
              isDayBlocked={isDayBlocked}
              onChange={onBookingEndDateChange}
              onPrevMonthClick={() => onMonthClick(prevMonthFn)}
              onNextMonthClick={() => onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={currentMonth} />}
              navPrev={<Prev currentMonth={currentMonth} />}
              isOutsideRange={day => isOutsideRange(day, bookingStartDate, selectedTimeSlot)}
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              disabled={endDateDisabled}
              showLabelAsDisabled={endDateDisabled}
              readOnly
            />
          </div>

          <div
            className={classNames(css.field, {
              [css.hideField]: !(bookingStartDate && bookingEndDate),
            })}
          >
            <FieldSelect
              name="bookingEndTime"
              id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
              className={css.input}
              disabled={endTimeDisabled}
            >
              <option disabled value="">
                {placeholderEndTime}
              </option>

              {bookingStartDate && bookingStartTime && bookingEndDate
                ? availableEndTimes.map(p => (
                    <option key={p.timeOfDay} value={p.timestamp}>
                      {p.timeOfDay}
                    </option>
                  ))
                : null}
            </FieldSelect>
          </div>
        </div>
      </div>
    </div>
  );
};

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDateAndTimeInput;
