import React, { useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { bool, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldPhoneNumberInput, FieldTextInput } from '../../components';
import VerifiedBadge, { BADGE_SIZE_SMALL } from '../../components/VerifiedBadge/VerifiedBadge';

import css from './SavePhoneNumberForm.module.css';

const SavePhoneNumberForm = props => {
  const [editMode, setEditMode] = useState(false);
  const [verifyMode, setVerifyMode] = useState(false);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          isPhoneNumberVerified,
          savePhoneNumberError,
          savePhoneNumberInProgress,
          savePhoneNumberSent,
          handleSubmit,
          verifyPhoneNumberError,
          verifyPhoneNumberInProgress,
          verifyPhoneNumberSent,
          onVerifyPhone,
          intl,
          invalid,
          disabled,
          initialValues,
          values,
        } = fieldRenderProps;
        const { phoneNumber } = savePhoneNumberSent
          ? { phoneNumber: values.phoneNumber }
          : initialValues || '';

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid || disabled || savePhoneNumberInProgress;
        const verifyDisabled = !values.code || verifyPhoneNumberInProgress;

        return (
          <Form
            className={classes}
            onSubmit={v => {
              setEditMode(false);
              handleSubmit(v);
            }}
          >
            <div className={css.container}>
              <div className={css.formRow}>
                <div className={css.header}>
                  <div className={css.title}>
                    <span>
                      <b>{intl.formatMessage({ id: 'SavePhoneNumberForm.label' })}</b>
                    </span>
                    <span className={css.marginLeft}>{phoneNumber}</span>
                  </div>
                  <VerifiedBadge
                    rootClassName={css.verifiedBadge}
                    isVerified={isPhoneNumberVerified}
                    size={BADGE_SIZE_SMALL}
                  />
                </div>
                <div className={css.formRow}>
                  {!editMode && (
                    <PrimaryButton
                      onClick={e => {
                        e.preventDefault();
                        setEditMode(true);
                      }}
                      ready={savePhoneNumberSent}
                      className={css.button}
                      inProgress={savePhoneNumberInProgress}
                      disabled={submitDisabled}
                    >
                      {intl.formatMessage({ id: 'SavePhoneNumberForm.edit' })}
                    </PrimaryButton>
                  )}
                  {!verifyMode && !isPhoneNumberVerified && (
                    <PrimaryButton
                      onClick={e => {
                        e.preventDefault();
                        setVerifyMode(true);
                      }}
                      ready={verifyPhoneNumberSent}
                      disabled={verifyPhoneNumberInProgress}
                      inProgress={verifyPhoneNumberInProgress}
                      className={css.verifyButton}
                    >
                      {intl.formatMessage({ id: 'SavePhoneNumberForm.verify' })}
                    </PrimaryButton>
                  )}
                </div>
              </div>
              {editMode && (
                <div className={css.formRow}>
                  <FieldPhoneNumberInput
                    className={css.input}
                    id="phoneNumber"
                    name="phoneNumber"
                    autoComplete="phone"
                    placeholder={intl.formatMessage({ id: 'SavePhoneNumberForm.phonePlaceholder' })}
                    validate={required(
                      intl.formatMessage({ id: 'SavePhoneNumberForm.phoneNumberRequired' })
                    )}
                  />
                  <PrimaryButton
                    className={css.button}
                    inProgress={savePhoneNumberInProgress}
                    disabled={submitDisabled}
                    type="submit"
                  >
                    {intl.formatMessage({ id: 'SavePhoneNumberForm.save' })}
                  </PrimaryButton>
                </div>
              )}
              {savePhoneNumberSent && !verifyPhoneNumberSent && (
                <div className={css.description}>
                  {intl.formatMessage(
                    { id: 'SavePhoneNumberForm.phoneNumberSent' },
                    { phoneNumber }
                  )}
                </div>
              )}
              {savePhoneNumberError && (
                <div className={css.error}>
                  {intl.formatMessage({ id: 'SavePhoneNumberForm.saveError' })}
                </div>
              )}
              {verifyMode && (
                <div className={css.verifyRow}>
                  <FieldTextInput
                    className={css.input}
                    id="code"
                    name="code"
                    type="text"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: 'SavePhoneNumberForm.codePlaceholder' })}
                  />
                  <PrimaryButton
                    className={css.sendCodeButton}
                    inProgress={verifyPhoneNumberInProgress}
                    disabled={verifyDisabled}
                    onClick={e => {
                      e.preventDefault();
                      onVerifyPhone(values.code);
                      setVerifyMode(false);
                    }}
                  >
                    {intl.formatMessage({ id: 'SavePhoneNumberForm.verifyCode' })}
                  </PrimaryButton>
                </div>
              )}
              {verifyPhoneNumberError && (
                <div className={css.error}>
                  {intl.formatMessage({ id: 'SavePhoneNumberForm.verifyError' })}
                </div>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

SavePhoneNumberForm.defaultProps = {
  savePhoneNumberError: null,
};

SavePhoneNumberForm.propTypes = {
  intl: intlShape.isRequired,

  isPhoneNumberVerified: bool.isRequired,

  savePhoneNumberError: propTypes.error,
  savePhoneNumberInProgress: bool.isRequired,
  savePhoneNumberSent: bool.isRequired,
  onSubmit: func.isRequired,

  verifyPhoneNumberError: propTypes.error,
  verifyPhoneNumberInProgress: bool.isRequired,
  verifyPhoneNumberSent: bool.isRequired,
  onVerifyPhone: func.isRequired,
};

export default SavePhoneNumberForm;
