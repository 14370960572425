import api from '../../api';
import { LISTING_STATE_PUBLISHED } from '../../util/types';
import { LISTING_TYPE } from '../../constants';
import unionWith from 'lodash/unionWith';

export const PAGE_LIMIT = 3;
// how far should the location search in meters. (10 miles)
export const CATEGORIES_RADIUS = 16095;

// ================ Action types ================ //

export const LOAD_INITIAL_STATE = 'app/LandingPage/LOAD_INITIAL_STATE';

export const FETCH_CATEGORIES_NEAR_REQUEST = 'app/LandingPage/FETCH_CATEGORIES_NEAR_REQUEST';
export const FETCH_CATEGORIES_NEAR_SUCCESS = 'app/LandingPage/FETCH_CATEGORIES_NEAR_SUCCESS';
export const FETCH_CATEGORIES_NEAR_ERROR = 'app/LandingPage/FETCH_CATEGORIES_NEAR_ERROR';
export const CAN_LOAD_MORE_CATEGORIES = 'app/LandingPage/CAN_LOAD_MORE_CATEGORIES';

export const FETCH_NEW_LISTINGS_NEAR_REQUEST = 'app/LandingPage/FETCH_NEW_LISTINGS_NEAR_REQUEST';
export const FETCH_NEW_LISTINGS_NEAR_SUCCESS = 'app/LandingPage/FETCH_NEW_LISTINGS_NEAR_SUCCESS';
export const FETCH_NEW_LISTINGS_NEAR_ERROR = 'app/LandingPage/FETCH_NEW_LISTINGS_NEAR_ERROR';

export const FETCH_WANTED_LISTINGS_NEAR_REQUEST =
  'app/LandingPage/FETCH_WANTED_LISTINGS_NEAR_REQUEST';
export const FETCH_WANTED_LISTINGS_NEAR_SUCCESS =
  'app/LandingPage/FETCH_WANTED_LISTINGS_NEAR_SUCCESS';
export const FETCH_WANTED_LISTINGS_NEAR_ERROR = 'app/LandingPage/FETCH_WANTED_LISTINGS_NEAR_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchCategoriesNearInProgress: false,
  fetchCategoriesNearError: null,
  categoriesNear: [],
  canLoadMoreCategories: false,
  currentCategoriesPage: 1,

  fetchNewListingsNearInProgress: false,
  fetchNewListingsNearError: null,
  newListingsNearPagination: null,
  newListingsNear: [],

  fetchWantedListingsNearInProgress: false,
  fetchWantedListingsNearError: null,
  wantedListingsNearPagination: null,
  wantedListingsNear: [],
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_INITIAL_STATE:
      return initialState;
    case FETCH_CATEGORIES_NEAR_REQUEST:
      return {
        ...state,
        fetchCategoriesNearInProgress: true,
        fetchCategoriesNearError: null,
      };
    case FETCH_CATEGORIES_NEAR_SUCCESS: {
      return {
        ...state,
        fetchCategoriesNearInProgress: false,
        categoriesNear: unionWith(state.categoriesNear, payload, (c1, c2) => c1._id === c2._id),
        currentCategoriesPage: state.currentCategoriesPage + 1,
      };
    }
    case FETCH_CATEGORIES_NEAR_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchCategoriesNearInProgress: false,
        fetchCategoriesNearError: payload,
      };
    case CAN_LOAD_MORE_CATEGORIES:
      return { ...state, canLoadMoreCategories: payload };

    case FETCH_NEW_LISTINGS_NEAR_REQUEST:
      return {
        ...state,
        fetchNewListingsNearInProgress: true,
        fetchNewListingsNearError: null,
      };
    case FETCH_NEW_LISTINGS_NEAR_SUCCESS: {
      const { currentPage, totalPages, items } = payload.data;
      return {
        ...state,
        fetchNewListingsNearInProgress: false,
        newListingsNearPagination: {
          page: currentPage,
          totalPages: totalPages,
          perPage: PAGE_LIMIT,
        },
        newListingsNear: unionWith(
          state.newListingsNear,
          items,
          (l1, l2) => l1.sharetribeId === l2.sharetribeId
        ),
      };
    }
    case FETCH_NEW_LISTINGS_NEAR_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchNewListingsNearInProgress: false,
        fetchNewListingsNearError: payload,
      };

    case FETCH_WANTED_LISTINGS_NEAR_REQUEST:
      return {
        ...state,
        fetchWantedListingsNearInProgress: true,
        fetchWantedListingsNearError: null,
      };
    case FETCH_WANTED_LISTINGS_NEAR_SUCCESS: {
      const { currentPage, totalPages, items } = payload.data;
      return {
        ...state,
        fetchWantedListingsNearInProgress: false,
        wantedListingsNearPagination: {
          page: currentPage,
          totalPages: totalPages,
          perPage: PAGE_LIMIT,
        },
        wantedListingsNear: unionWith(
          state.wantedListingsNear,
          items,
          (l1, l2) => l1.sharetribeId === l2.sharetribeId
        ),
      };
    }
    case FETCH_WANTED_LISTINGS_NEAR_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchWantedListingsNearInProgress: false,
        fetchWantedListingsNearError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const loadInitialState = () => ({ type: LOAD_INITIAL_STATE });

export const fetchCategoriesNearRequest = () => ({ type: FETCH_CATEGORIES_NEAR_REQUEST });
export const fetchCategoriesNearSuccess = payload => ({
  type: FETCH_CATEGORIES_NEAR_SUCCESS,
  payload,
});
export const fetchCategoriesNearError = e => ({ type: FETCH_CATEGORIES_NEAR_ERROR, payload: e });
export const canLoadMoreCategories = payload => ({ type: CAN_LOAD_MORE_CATEGORIES, payload });

export const fetchNewListingsNearRequest = () => ({ type: FETCH_NEW_LISTINGS_NEAR_REQUEST });
export const fetchNewListingsNearSuccess = response => ({
  type: FETCH_NEW_LISTINGS_NEAR_SUCCESS,
  payload: response,
});
export const fetchNewListingsNearError = e => ({ type: FETCH_NEW_LISTINGS_NEAR_ERROR, payload: e });

export const fetchWantedListingsNearRequest = () => ({ type: FETCH_WANTED_LISTINGS_NEAR_REQUEST });
export const fetchWantedListingsNearSuccess = response => ({
  type: FETCH_WANTED_LISTINGS_NEAR_SUCCESS,
  payload: response,
});
export const fetchWantedListingsNearError = e => ({
  type: FETCH_WANTED_LISTINGS_NEAR_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const fetchCategoriesNear = latlng => (dispatch, getState, sdk) => {
  dispatch(fetchCategoriesNearRequest());
  const locationMaybe = latlng
    ? {
        coordinates: [latlng.lng, latlng.lat],
        meters: CATEGORIES_RADIUS,
      }
    : {};

  const { currentCategoriesPage } = getState().LandingPage;

  return Promise.all([
    api.listings.getCategories((currentCategoriesPage - 1) * PAGE_LIMIT, PAGE_LIMIT, locationMaybe),
    api.listings.getCategories(currentCategoriesPage * PAGE_LIMIT, PAGE_LIMIT, locationMaybe),
  ])
    .then(response => {
      const currentPageItems = response[0];
      const nextPageItems = response[1];

      dispatch(fetchCategoriesNearSuccess(currentPageItems.data));
      const hasMore = nextPageItems.data.length > 0;

      dispatch(canLoadMoreCategories(hasMore));
    })
    .catch(e => {
      dispatch(fetchCategoriesNearError(e));
      throw e;
    });
};

export const fetchNewListingsNear = (page = 1, latlng) => (dispatch, getState, sdk) => {
  dispatch(fetchNewListingsNearRequest());
  const locationMaybe = latlng
    ? {
        geolocationPoint: {
          $near: { $geometry: { type: 'Point', coordinates: [latlng.lng, latlng.lat] } },
        },
      }
    : {};

  return api.listings
    .getListings({
      $page: page,
      $limit: PAGE_LIMIT,
      $sort: 'createdAt',
      state: LISTING_STATE_PUBLISHED,
      'publicData.listingType': LISTING_TYPE.LENDER_POST,
      ...locationMaybe,
    })
    .then(response => {
      dispatch(fetchNewListingsNearSuccess(response));
    })
    .catch(e => {
      dispatch(fetchNewListingsNearError(e));
      throw e;
    });
};

export const fetchWantedListingsNear = (page = 1, latlng) => (dispatch, getState, sdk) => {
  dispatch(fetchWantedListingsNearRequest());
  const locationMaybe = latlng
    ? {
        geolocationPoint: {
          $near: { $geometry: { type: 'Point', coordinates: [latlng.lng, latlng.lat] } },
        },
      }
    : {};

  return api.listings
    .getListings({
      $page: page,
      $limit: PAGE_LIMIT,
      state: LISTING_STATE_PUBLISHED,
      'publicData.listingType': LISTING_TYPE.WANTED_POST,
      'publicData.endTime': { $gt: Date.now() },
      ...locationMaybe,
    })
    .then(response => {
      dispatch(fetchWantedListingsNearSuccess(response));
    })
    .catch(e => {
      dispatch(fetchWantedListingsNearError(e));
      throw e;
    });
};

export const manualLoadData = (page, latLng) => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(loadInitialState()),
    dispatch(fetchCategoriesNear(latLng)),
    dispatch(fetchNewListingsNear(page, latLng)),
    dispatch(fetchWantedListingsNear(page, latLng)),
  ]);
};
