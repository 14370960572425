import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, IconClose, IconSearch, IconSpinner, InlineTextButton } from '../../components';
import debounce from 'lodash/debounce';

import css from './SearchForm.module.css';

class SearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = debounce(this.onChange.bind(this), 500);
  }

  onChange(value) {
    this.props.onSubmit({ keywords: value });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            inputClassName,
            intl,
            placeHolder,
            onClear,
            inProgress,
          } = formRenderProps;

          const classes = classNames(css.root, className);
          const inputClasses = inputClassName || css.inputRoot;

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();

          const placeholder = placeHolder || intl.formatMessage({ id: 'SearchForm.placeholder' });

          return (
            <Form className={classes} onSubmit={preventFormSubmit}>
              <div className={css.icon}>
                {inProgress ? <IconSpinner className={css.iconSpinner} /> : <IconSearch />}
              </div>
              <Field
                name="keywords"
                render={({ input }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = e => {
                    const value = e.target.value;

                    onChange(value);
                    this.onChange(value);
                  };

                  const searchInput = { ...restInput, onChange: searchOnChange };

                  return (
                    <input
                      className={inputClasses}
                      {...searchInput}
                      id="keyword-search"
                      type="text"
                      placeholder={placeholder}
                      autoComplete="off"
                    />
                  );
                }}
              />
              <InlineTextButton className={css.clearButton} onClick={onClear}>
                <IconClose />
              </InlineTextButton>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string } = PropTypes;

SearchFormComponent.defaultProps = {
  className: null,
  inputClassName: null,
};

SearchFormComponent.propTypes = {
  className: string,
  inputClassName: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchForm = injectIntl(SearchFormComponent);

export default SearchForm;
