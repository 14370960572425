import { getDistance } from 'geolib';
import api from '../api';
import { DEFAULT_MARKETS, filters } from '../marketplace-custom-config';
import { get } from 'lodash';
import GeocoderMapbox from '../components/LocationAutocompleteInput/GeocoderMapbox';
import { placeOrigin } from '../util/maps';
import StorageService from '../services/StorageService';

// ================ Action types ================ //

export const BORROWED_LOCATION_REQUEST = 'app/BorrowedLocation/BORROWED_LOCATION_REQUEST';
export const BORROWED_LOCATION_SUCCESS = 'app/BorrowedLocation/BORROWED_LOCATION_SUCCESS';
export const BORROWED_LOCATION_ERROR = 'app/BorrowedLocation/BORROWED_LOCATION_ERROR';

export const SELECTED_MARKET_KEY_CHANGED = 'app/BorrowedLocation/SELECTED_MARKET_KEY_CHANGED';

// ================ Reducer ================ //

const initialState = {
  borrowedLocations: null,
  borrowedLocationsError: null,
  borrowedLocationsInProgress: false,
  selectedMarketKey: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case BORROWED_LOCATION_REQUEST:
      return {
        ...state,
        borrowedLocationsInProgress: true,
        borrowedLocationsError: null,
      };
    case BORROWED_LOCATION_SUCCESS: {
      const filterRef = filters.find(f => f.id === 'market');

      filterRef.config.options = payload;

      return {
        ...state,
        borrowedLocationsInProgress: false,
        borrowedLocations: payload,
      };
    }
    case BORROWED_LOCATION_ERROR:
      return { ...state, borrowedLocationsInProgress: false, borrowedLocationsError: payload };
    case SELECTED_MARKET_KEY_CHANGED:
      return { ...state, selectedMarketKey: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const borrowedLocationRequest = () => ({ type: BORROWED_LOCATION_REQUEST });
export const borrowedLocationSuccess = payload => ({ type: BORROWED_LOCATION_SUCCESS, payload });
export const borrowedLocationError = error => ({
  type: BORROWED_LOCATION_ERROR,
  payload: error,
  error: true,
});

export const selectedMarketKeyChanged = payload => ({ type: SELECTED_MARKET_KEY_CHANGED, payload });

// ================ Thunks ================ //

export const fetchBorrowedLocations = () => async (dispatch, getState, sdk) => {
  const { borrowedLocationsInProgress } = getState().BorrowedLocation;
  const { currentUser } = getState().user;

  if (borrowedLocationsInProgress) {
    return Promise.resolve();
  }

  dispatch(borrowedLocationRequest());

  try {
    const locationsResult = await api.borrowedLocations.getLocations();
    const { items: locations } = locationsResult.data;

    // Options contains default markets (defined on marketplace)
    // and markets defined on backend (borrowed locations).
    // We need to remove duplicates.
    let options = [
      ...DEFAULT_MARKETS,
      ...locations.map(({ address, bounds, origin }) => ({
        key: address,
        label: address,
        bounds,
        origin,
      })),
    ].filter((o, i, self) => i === self.findIndex(o2 => o.key === o2.key));

    // For a authenticated user: Default the closest market based on their zip code.
    if (currentUser) {
      try {
        const { zipCode, userLocationFromZip } = get(currentUser, 'attributes.profile.publicData');

        let currentLocation = userLocationFromZip;

        if (!currentLocation) {
          const { predictions } = await new GeocoderMapbox().getPlacePredictions(zipCode);

          const { lat: lng, lng: lat } = placeOrigin(predictions[0]);
          currentLocation = { lat, lng };

          sdk.currentUser.updateProfile({ publicData: { userLocationFromZip: currentLocation } });
        }

        options = options.sort(
          (a, b) => getDistance(a.origin, currentLocation) - getDistance(b.origin, currentLocation)
        );

        dispatch(changeSelectedMarketKey(options[0].key));
      } catch (e) {}
    }
    // For non authenticated user: Default to cached or Seattle
    else {
      const cachedKey = StorageService.getItem('selectedMarketKey');

      dispatch(changeSelectedMarketKey(cachedKey || options[0].key));
    }

    return dispatch(borrowedLocationSuccess(options));
  } catch (e) {
    dispatch(borrowedLocationError(e));
  }
};

export const changeSelectedMarketKey = (key, isReset = false) => async (dispatch, getState) => {
  if (!isReset && !key) {
    return;
  }

  StorageService.setItem('selectedMarketKey', key);

  dispatch(selectedMarketKeyChanged(key));
};
