import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFilter.module.css';

const IconFilter = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0.625 0.862 9.75 8.775"
    >
      <path d="M10.375.862H.625l3.9 4.612v3.188l1.95.975V5.474l3.9-4.612z" />
    </svg>
  );
};

const { string } = PropTypes;

IconFilter.defaultProps = {
  className: null,
  rootClassName: null,
};

IconFilter.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFilter;
