import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { dateIsAfter, timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldSelect,
  Modal,
  ValidationError,
} from '../../components';
import { lineItemsTotal } from '../../components/BookingBreakdown/LineItemSubTotalMaybe';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { required } from '../../util/validators';

import css from './BookingTimeForm.module.css';

const MAX_SEATS = 1000000;

const getSelectedSlotsForDates = (timeSlots, startDate, endDate) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter(t => {
        const { start, end } = t.attributes;

        return dateIsAfter(end, startDate) && dateIsAfter(endDate, start);
      })
    : [];
};

const getMinSeatsFromTimeSlots = timeSlots => {
  const min = timeSlots.reduce(
    (minSeats, slot) => Math.min(minSeats, slot.attributes.seats),
    MAX_SEATS
  );

  if (min === MAX_SEATS) return null;

  return min;
};

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricingIsOpen: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(values) {
    const { deliveryOnSiteRentalMinutes } = this.props;
    const { delivery } = values;
    const deliveryOnSiteRentalMinutesMaybe =
      delivery === 'onSiteRental' ? { onSiteRentalMinutes: deliveryOnSiteRentalMinutes } : {};
    this.props.onSubmit({
      ...values,
      ...deliveryOnSiteRentalMinutesMaybe,
    });
  }

  getAvailableQuantity = (startDate, endDate) => {
    const { monthlyTimeSlots } = this.props;

    if (!startDate || !endDate) return null;

    const timeSlots = monthlyTimeSlots?.timeSlots || [];

    const timeSlotsForDates = getSelectedSlotsForDates(timeSlots, startDate, endDate);

    const minSeats = getMinSeatsFromTimeSlots(timeSlotsForDates);

    return minSeats;
  };

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues, form) {
    const { fetchLineItemsInProgress, onFetchTransactionLineItems } = this.props;
    const { bookingStartTime, bookingEndTime, quantity } = formValues.values;

    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (!fetchLineItemsInProgress) {
      const availableQuantity = this.getAvailableQuantity(startDate, endDate);

      if (availableQuantity && quantity && availableQuantity < quantity && !isSameTime) {
        form.change('quantity', availableQuantity);
      }

      if (quantity && bookingStartTime && bookingEndTime && !isSameTime) {
        onFetchTransactionLineItems({
          bookingData: { startDate, endDate, seats: quantity },
          listingId,
          isOwnListing,
        });
      }
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            deliveryMethod,
            deliveryOnSiteRentalMinutes,
            listingId,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            onManageDisableScrolling,
            pickUpDropOffPlan,
            borrowingType,
          } = fieldRenderProps;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const availableQuantity = this.getAvailableQuantity(startDate, endDate);

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const startDateInputProps = {
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const subTotal = showEstimatedBreakdown ? lineItemsTotal(lineItems) : null;
          const formattedSubTotal = showEstimatedBreakdown ? formatMoney(intl, subTotal) : null;

          const submitDisabled = !(
            values.delivery &&
            values.bookingStartDate &&
            values.bookingStartTime &&
            values.bookingEndDate &&
            values.bookingEndTime &&
            !fetchLineItemsInProgress
          );

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values, form);
                }}
              />
              <h3 className={css.segmentTitle}>
                {intl.formatMessage({ id: 'BookingTimeForm.title' })}
              </h3>
              {monthlyTimeSlots?.timeSlots?.length > 0 ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  pickUpDropOffPlan={pickUpDropOffPlan}
                  borrowingType={borrowingType}
                />
              ) : null}

              {deliveryOnSiteRentalMinutes && values.delivery === 'onSiteRental' && (
                <div className={css.tinyFont}>
                  <FormattedMessage
                    id="BookingTimeForm.delivery"
                    values={{ minutes: deliveryOnSiteRentalMinutes }}
                  />
                </div>
              )}
              <hr className={css.linebreak}></hr>
              <div>
                <h3 className={css.segmentTitle}>
                  {intl.formatMessage({ id: 'BookingTimeForm.deliveryOptions' })}
                </h3>
                {deliveryMethod && Array.isArray(deliveryMethod) && (
                  <FieldSelect
                    className={css.deliverySelect}
                    name="delivery"
                    disabled={deliveryMethod.length === 0}
                    validate={required(
                      intl.formatMessage({ id: 'BookingTimeForm.deliveryRequired' })
                    )}
                  >
                    <option disabled value="">
                      {intl.formatMessage({ id: 'BookingTimeForm.deliveryPlaceholder' })}
                    </option>
                    {deliveryMethod.map(method => (
                      <option key={method} value={method}>
                        {method === 'pickUp'
                          ? intl.formatMessage({ id: 'BookingTimeForm.usePickup' })
                          : method === 'onSiteRental'
                          ? intl.formatMessage({ id: 'BookingTimeForm.useOnSite' })
                          : null}
                      </option>
                    ))}
                  </FieldSelect>
                )}
              </div>
              {values.bookingStartDate && !!availableQuantity && (
                <div>
                  <hr className={css.linebreak}></hr>
                  <h3 className={css.segmentTitle}>
                    {intl.formatMessage({ id: 'BookingTimeForm.quantity' })}
                  </h3>
                  <div className={css.quantityContainer}>
                    <div className={css.quantityActions}>
                      <button
                        type="button"
                        onClick={e => {
                          if (values.quantity > 1) form.change('quantity', +values.quantity - 1);
                        }}
                        className={classNames(css.quantityAction, {
                          [css.quantityActionDisable]: values.quantity === 1,
                        })}
                        disabled={fetchLineItemsInProgress}
                      >
                        -
                      </button>
                      <Field
                        component={props => {
                          const { input, meta } = props;
                          return (
                            <>
                              <input {...input} />
                              <ValidationError fieldMeta={meta} />
                            </>
                          );
                        }}
                        name="quantity"
                        type="hidden"
                        validate={required('Quantity required')}
                      />
                      <div className={css.quantityNumber}>{values.quantity}</div>
                      <button
                        type="button"
                        onClick={() => {
                          if (values.quantity < availableQuantity)
                            form.change('quantity', +values.quantity + 1);
                        }}
                        className={classNames(css.quantityAction, {
                          [css.quantityActionDisable]: values.quantity === availableQuantity,
                        })}
                        disabled={fetchLineItemsInProgress}
                      >
                        +
                      </button>
                    </div>
                    <div className={css.tinyFont}>
                      {intl.formatMessage(
                        { id: 'BookingTimeForm.quantityAvailable' },
                        { quantity: availableQuantity }
                      )}
                    </div>
                  </div>
                </div>
              )}
              <hr className={css.linebreak}></hr>
              <div className={css.segmentTitle}>
                {intl.formatMessage({ id: 'BookingTimeForm.pricing' })}
              </div>

              <Modal
                id="pricingSection"
                isOpen={this.state.pricingIsOpen}
                onClose={() => this.setState({ pricingIsOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
                closeButtonMessage={intl.formatMessage({ id: 'BookingTimeForm.closeModal' })}
              >
                {bookingInfoMaybe}
              </Modal>

              <div className={css.pricing}>
                <PrimaryButton disabled={submitDisabled} className={css.submitButton} type="submit">
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>

                {loadingSpinnerMaybe ? (
                  loadingSpinnerMaybe
                ) : bookingInfoErrorMaybe ? (
                  bookingInfoErrorMaybe
                ) : formattedSubTotal ? (
                  <div className={css.pricingSection}>
                    <div className={css.price}> {formattedSubTotal}</div>
                    <div className={css.priceDescription}>
                      {intl.formatMessage({ id: 'BookingTimeForm.pricingDescription' })}
                    </div>
                    <div
                      className={css.priceDetails}
                      onClick={() => this.setState({ pricingIsOpen: true })}
                    >
                      {intl.formatMessage({ id: 'BookingTimeForm.pricingDetails' })}
                    </div>
                  </div>
                ) : null}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  deliveryMethod: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = injectIntl(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
