import { LINE_ITEM_DAYS, LINE_ITEM_HOURS, LINE_ITEM_WEEKS, PERIOD_LINE_ITEMS } from './types';

export const getTranslationsForUsedPeriods = (intl, lineItems) => {
  if (!lineItems) return null;

  const usedPeriodLineItems = lineItems.filter(
    item => PERIOD_LINE_ITEMS.includes(item.code) && !item.reversal
  );

  if (!usedPeriodLineItems || usedPeriodLineItems.length === 0) return null;

  const translations = [];

  // 'order' represents which is the first translations that should appear
  // weeks (1) - days (2) - hours (3)
  for (let index = 0; index < usedPeriodLineItems.length; index++) {
    const { code, quantity } = usedPeriodLineItems[index];

    switch (code) {
      case LINE_ITEM_HOURS:
        translations.push({
          message: intl.formatMessage(
            { id: 'BookingBreakdown.quantityHours' },
            { quantity: quantity.toNumber() }
          ),
          order: 3,
        });

        break;

      case LINE_ITEM_DAYS:
        translations.push({
          message: intl.formatMessage(
            { id: 'BookingBreakdown.quantityDays' },
            { quantity: quantity.toNumber() }
          ),
          order: 2,
        });
        break;

      case LINE_ITEM_WEEKS:
        translations.push({
          message: intl.formatMessage(
            { id: 'BookingBreakdown.quantityWeeks' },
            { quantity: quantity.toNumber() }
          ),
          order: 1,
        });

        break;

      default:
        break;
    }
  }

  return translations
    .sort((t1, t2) => t1.order - t2.order)
    .map(t => t.message)
    .join(' + ');
};
