import React, { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { string } from 'prop-types';
import classNames from 'classnames';
import IconAdd from '../IconAdd/IconAdd';
import IconMinus from '../IconMinus/IconMinus';

import css from './FieldSelectableCheckboxItem.module.css';

const FieldSelectableCheckboxItem = props => {
  const { className, id, label, value, fields } = props;

  const isSelected = useMemo(() => fields.value?.indexOf(value) > -1, [fields.value, value]);

  const classes = useMemo(() => classNames(css.root, { [css.selected]: isSelected }, className), [
    className,
    isSelected,
  ]);

  const handleOnChange = useCallback(() => {
    const index = fields.value?.indexOf(value);

    index === -1 ? fields.push(value) : fields.remove(index);
  }, [fields, value]);

  return (
    <Field name={fields.name}>
      {() => (
        <>
          <input
            id={id}
            type="checkbox"
            className={css.input}
            name={fields.name}
            value={value}
            onChange={handleOnChange}
          />
          <label htmlFor={id} className={classes}>
            <span>
              {isSelected ? <IconMinus className={css.icon} /> : <IconAdd className={css.icon} />}
            </span>
            {label}
          </label>
        </>
      )}
    </Field>
  );
};

FieldSelectableCheckboxItem.defaultProps = {
  className: null,
};

FieldSelectableCheckboxItem.propTypes = {
  className: string,
  id: string.isRequired,
  value: string.isRequired,
};

export default FieldSelectableCheckboxItem;
