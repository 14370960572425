import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { getTranslationsForUsedPeriods } from '../../util/booking';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl } = props;

  const { attributes } = transaction;

  const { lineItems } = attributes || {};

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === unitType && !item.reversal);

  const usedPeriodTranslation = getTranslationsForUsedPeriods(intl, lineItems);

  const seats = unitPurchase && unitPurchase.seats ? unitPurchase.seats.toString() : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return usedPeriodTranslation && total ? (
    <React.Fragment>
      <div className={css.segmentTitle}>
        <FormattedMessage id="BookingBreakdown.priceDetails" />
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage
            id="BookingBreakdown.unitPrice"
            values={{
              usedPeriods: usedPeriodTranslation,
            }}
          />
        </span>
        <span className={css.itemValue}>{total}</span>
      </div>
      {seats && (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.seats" />
          </span>
          <span className={css.itemValue}>{seats}</span>
        </div>
      )}
    </React.Fragment>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
