import api from '../../api';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getExclusiveEndDate, resetToStartOfDay } from '../../util/dates';
import { DEFAULT_TITLE } from '../../constants';

const { UUID } = sdkTypes;
const currentDate = resetToStartOfDay(new Date());
const tomorrow = getExclusiveEndDate(currentDate);

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const FETCH_TIMESLOTS_REQUEST = 'app/ManageListingsPage/FETCH_TIMESLOTS_REQUEST';
export const FETCH_TIMESLOTS_SUCCESS = 'app/ManageListingsPage/FETCH_TIMESLOTS_SUCCESS';
export const FETCH_TIMESLOTS_ERROR = 'app/ManageListingsPage/FETCH_TIMESLOTS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const DISCARD_LISTING_REQUEST = 'app/ManageListingsPage/DISCARD_LISTING_REQUEST';
export const DISCARD_LISTING_SUCCESS = 'app/ManageListingsPage/DISCARD_LISTING_SUCCESS';
export const DISCARD_LISTING_ERROR = 'app/ManageListingsPage/DISCARD_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  listings: [],
  timeslots: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  discardListing: null,
  discardListingError: null,
};

const updateListingAttributes = (state, listingEntity) => {
  const newListings = [...state.listings];
  const oldListingIndex = newListings.findIndex(l => l.sharetribeId === listingEntity.id.uuid);

  if (oldListingIndex > -1) {
    newListings[oldListingIndex] = {
      ...newListings[oldListingIndex],
      state: listingEntity.attributes.state,
    };
  }

  return {
    ...state,
    listings: newListings,
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        listings: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      const { items, currentPage, totalPages } = payload.data;

      return {
        ...state,
        listings: items.map(listing => ({
          ...listing,
          title: listing.title === DEFAULT_TITLE ? 'No title' : listing.title,
        })),
        pagination: { page: currentPage, totalPages: totalPages, perPage: 10 },
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case FETCH_TIMESLOTS_REQUEST: {
      const { listingId } = payload;
      const timeSlots = { ...state.timeslots };
      timeSlots[listingId] = { inProgress: true, timeslots: [], error: null };
      return {
        ...state,
        timeslots: timeSlots,
      };
    }
    case FETCH_TIMESLOTS_SUCCESS: {
      const { listingId, timeslots } = payload;
      const timeSlots = { ...state.timeslots };
      timeSlots[listingId] = { inProgress: false, timeslots: timeslots.data, error: null };
      return {
        ...state,
        timeslots: timeSlots,
      };
    }
    case FETCH_TIMESLOTS_ERROR: {
      const { listingId, error } = payload;
      console.error(error);
      const timeSlots = { ...state.timeslots };
      timeSlots[listingId] = { inProgress: false, timeslots: [], error };
      return {
        ...state,
        timeslots: timeSlots,
      };
    }

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case DISCARD_LISTING_REQUEST:
      return {
        ...state,
        discardListing: payload.listingId,
        discardListingError: null,
      };
    case DISCARD_LISTING_SUCCESS:
      return {
        ...state,
        listings: state.listings.filter(l => l.sharetribeId !== state.discardListing),
        discardListing: null,
      };
    case DISCARD_LISTING_ERROR:
      console.error(payload);
      return {
        ...state,
        discardListing: null,
        discardListingError: {
          listingId: state.discardListing,
          error: payload,
        },
      };

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Action creators ================ //

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const discardListingRequest = listingId => ({
  type: DISCARD_LISTING_REQUEST,
  payload: { listingId },
});
export const discardListingSuccess = () => ({
  type: DISCARD_LISTING_SUCCESS,
});
export const discardListingError = e => ({
  type: DISCARD_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchTimeslotsRequest = listingId => ({
  type: FETCH_TIMESLOTS_REQUEST,
  payload: { listingId },
});

export const fetchTimeslotsSuccess = (listingId, timeslots) => ({
  type: FETCH_TIMESLOTS_SUCCESS,
  payload: { listingId, timeslots },
});

export const fetchTimeslotsError = (listingId, e) => ({
  type: FETCH_TIMESLOTS_ERROR,
  payload: { listingId, error: e },
});

const fetchTimeslots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeslotsRequest(listingId));

  return sdk.timeslots
    .query({
      listingId,
      start: currentDate,
      end: tomorrow,
    })
    .then(res => {
      dispatch(fetchTimeslotsSuccess(listingId, res.data));
    })
    .catch(e => {
      dispatch(fetchTimeslotsError(listingId, e));
      throw e;
    });
};

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, $limit: 5, per_page: perPage };

  return api.listings
    .getListings(params)
    .then(response => {
      dispatch(queryListingsSuccess(response));
      response.data.items.forEach(l => dispatch(fetchTimeslots(l.sharetribeId)));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: new UUID(listingId) }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const discardListing = listingId => (dispatch, getState, sdk) => {
  dispatch(discardListingRequest(listingId));

  return sdk.ownListings
    .discardDraft({ id: new UUID(listingId) }, { expand: true })
    .then(response =>
      api.listings
        .discardListing(listingId)
        .then(() => dispatch(discardListingSuccess(response)))
        .catch(e => dispatch(discardListingError(e)))
    )
    .catch(e => dispatch(discardListingError(storableError(e))));
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: new UUID(listingId) }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const manualLoadData = params => {
  return queryOwnListings({
    ...params,
    'publicData.listingType': 'LENDER_POST',
    $relations: ['author'],
  });
};
