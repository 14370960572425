import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconReviewStar } from '../../components';
import { REVIEW_RATINGS } from '../../util/types';

import css from './ReviewRating.module.css';

const ReviewRating = props => {
  const { className, rootClassName, reviewStarClassName, rating } = props;
  const classes = classNames(rootClassName || css.root, className);

  const roundedRating = Number(Math.round(rating + 'e' + 2) + 'e' + -2);

  const stars = REVIEW_RATINGS;
  return (
    <span className={classes}>
      {stars.map(star => (
        <IconReviewStar
          key={`star-${star}`}
          className={reviewStarClassName}
          isFilled={star <= roundedRating}
          isHalfFilled={star > roundedRating && star - 0.5 <= roundedRating}
        />
      ))}
    </span>
  );
};

ReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
};

const { string, oneOfType, number } = PropTypes;

ReviewRating.propTypes = {
  rating: oneOfType([string, number]),
  reviewStartClassName: string,
  rootClassName: string,
  className: string,
};

export default ReviewRating;
