import React from 'react';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from '../../util/reactIntl';
import { Modal } from '../../components';
import { ReportIssueForm } from '../../forms';

import css from './ReportIssueModal.module.css';

const ReportIssueModal = props => {
  const {
    id,
    intl,
    className,
    rootClassName,
    onManageDisableScrolling,
    isOpen,
    ready,
    onCloseModal,
    onSubmit,
    submitInProgress,
    submitError,
    headerText,
    inputPlaceholder,
    displayName,
    listingTitle,
    author,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ReportIssueModal.close' });

  return (
    <Modal
      id={id}
      className={classes}
      isOpen={isOpen}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      onClose={onCloseModal}
      closeButtonMessage={closeButtonMessage}
    >
      {isOpen ? (
        <ReportIssueForm
          onClose={onCloseModal}
          title={headerText}
          inputPlaceholderMessage={inputPlaceholder}
          onSubmit={onSubmit}
          saveActionMsg={intl.formatMessage({ id: 'ReportIssueModal.submit' })}
          intl={intl}
          reportingInProgress={submitInProgress}
          reportIssueSubmitted={ready}
          reportingIssueError={submitError}
          listerName={displayName}
          listingTitle={listingTitle}
          author={author}
        />
      ) : null}
    </Modal>
  );
};

ReportIssueModal.defaultProps = {
  className: null,
  rootClassName: null,
  submitError: null,
};

ReportIssueModal.propTypes = {
  className: string,
  rootClassName: string,
  isOpen: bool.isRequired,
  submitInProgress: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  headerText: string.isRequired,
  inputPlaceholder: string.isRequired,
};

export default injectIntl(ReportIssueModal);
