import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import {
  getReview1Transition,
  getReview2Transition,
  txIsInFirstReviewBy,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import api from '../../api';

const { UUID } = sdkTypes;
const CUSTOMER = 'customer';
const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// ================ Action types ================ //

export const FETCH_TRANSACTION_REQUEST = 'app/ReviewPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/ReviewPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/ReviewPage/FETCH_TRANSACTION_ERROR';

export const SEND_REVIEW_REQUEST = 'app/ReviewPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/ReviewPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/ReviewPage/SEND_REVIEW_ERROR';

export const SEND_FEEDBACK_REQUEST = 'app/ReviewPage/SEND_FEEDBACK_REQUEST';
export const SEND_FEEDBACK_SUCCESS = 'app/ReviewPage/SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_ERROR = 'app/ReviewPage/SEND_FEEDBACK_ERROR';

// ================ Reducers ================ //

const initialState = {
  transaction: null,
  queryInProgress: false,
  queryError: null,

  sendReviewInProgress: false,
  reviewSent: false,
  sendReviewError: null,

  sendFeedbackInProgress: false,
  feedbackSent: false,
  sendFeedbackError: null,
};

export default function reviewPageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        transaction: null,
        queryError: null,
      };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        queryInProgress: false,
        transaction: payload,
      };
    case FETCH_TRANSACTION_ERROR:
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryError: payload,
      };

    case SEND_REVIEW_REQUEST:
      return {
        ...state,
        sendReviewInProgress: true,
        sendReviewError: null,
        reviewSent: false,
      };
    case SEND_REVIEW_SUCCESS:
      return {
        ...state,
        sendReviewInProgress: false,
        reviewSent: true,
      };
    case SEND_REVIEW_ERROR:
      console.error(payload);
      return {
        ...state,
        sendReviewInProgress: false,
        sendReviewError: payload,
      };

    case SEND_FEEDBACK_REQUEST:
      return {
        ...state,
        sendFeedbackInProgress: true,
        sendFeedbackError: null,
        feedbackSent: false,
      };
    case SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        sendFeedbackInProgress: false,
        feedbackSent: true,
      };
    case SEND_FEEDBACK_ERROR:
      console.error(payload);
      return {
        ...state,
        sendFeedbackInProgress: false,
        sendFeedbackError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchTransactionRequest = () => ({
  type: FETCH_TRANSACTION_REQUEST,
});
export const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
export const fetchTransactionError = e => ({
  type: FETCH_TRANSACTION_ERROR,
  payload: e,
});

export const sendReviewRequest = () => ({
  type: SEND_REVIEW_REQUEST,
});
export const sendReviewSuccess = () => ({
  type: SEND_REVIEW_SUCCESS,
});
export const sendReviewError = e => ({
  type: SEND_REVIEW_ERROR,
  payload: e,
});

export const sendFeedbackRequest = () => ({
  type: SEND_FEEDBACK_REQUEST,
});
export const sendFeedbackSuccess = () => ({
  type: SEND_FEEDBACK_SUCCESS,
});
export const sendFeedbackError = e => ({
  type: SEND_FEEDBACK_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx.id, params, role, dispatch, sdk);
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id, params, role, dispatch, sdk) => {
  const transition = getReview1Transition(role === CUSTOMER);
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
  const transition = getReview2Transition(role === CUSTOMER);

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

export const sendFeedback = (listingId, listingUrl, text) => (dispatch, getState, sdk) => {
  dispatch(sendFeedbackRequest());

  return api.listings
    .feedback(listingId, listingUrl, text)
    .then(res => {
      dispatch(sendFeedbackSuccess());
    })
    .catch(e => {
      dispatch(sendFeedbackError(e));
      throw e;
    });
};

export const fetchTransaction = transactionId => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  const queryParams = {
    id: new UUID(transactionId),
    include: ['booking', 'listing', 'provider'],
  };

  return sdk.transactions
    .show(queryParams)
    .then(response => {
      const denormalized = denormalisedResponseEntities(response);
      dispatch(fetchTransactionSuccess(denormalized[0]));
    })
    .catch(e => {
      dispatch(fetchTransactionError(e));
      throw e;
    });
};

export const loadData = params => {
  const { id } = params;
  return fetchTransaction(id);
};
