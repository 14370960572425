import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { Button } from '../../components';

import css from './FieldToggle.module.css';

const IconCheckbox = props => {
  const { className } = props;
  return (
    <svg className={className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M5.636621 10.7824771L3.3573694 8.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254782 2.8256927-4.5462307c.3934117-.5431636 1.1545778-.6695372 1.7055985-.278265.5473554.3912721.6731983 1.150729.2797866 1.6951077l-3.6650524 5.709111c-.2199195.306213-.5803433.5067097-.9920816.5067097-.3225487 0-.6328797-.1263736-.8637952-.3560334z" />
      </g>
    </svg>
  );
};

const FieldToggleComponent = props => {
  const {
    rootClassName,
    className,
    iconRootClassName,
    textClassName,
    initialValue,
    id,
    label,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const svgClassName = classNames(css.icon || iconRootClassName);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    const { onInputChange } = props;
    onChange(event);
    onInputChange && onInputChange(event);
    onBlur(event);
  };

  return (
    <span className={classes}>
      <label htmlFor={id} className={css.label}>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
      <Field initialValue={initialValue ? initialValue : false} {...rest}>
        {props => {
          const { input } = props;
          const { value } = input;

          return (
            <Button
              className={classNames(css.container, { [css.containerChecked]: value })}
              onClick={e => {
                e.preventDefault();
                handleOnChange(input, !value);
              }}
            >
              <div className={classNames(css.circle, { [css.circleChecked]: value })}>
                {value && <IconCheckbox className={svgClassName} />}
              </div>
            </Button>
          );
        }}
      </Field>
    </span>
  );
};

FieldToggleComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldToggleComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldToggleComponent;
