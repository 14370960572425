import httpClient from '../httpClient';

const isGeolocationInLaunchedLocations = geolocation => {
  return httpClient.post(`borrowed-locations/check-location`, { geolocation });
};

const getLocations = params => {
  return httpClient.get('borrowed-locations', { params });
};

const borrowedLocations = {
  isGeolocationInLaunchedLocations,
  getLocations,
};

export default borrowedLocations;
