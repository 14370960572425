import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { arrayOf, bool, string, func, object, shape } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  Button,
  IconSpinner,
  FieldRadioButton,
  PaginationLinks,
  FieldTextInput,
} from '../../components';
import { required } from '../../util/validators';
import { createSlug } from '../../util/urlHelpers';
import { LISTING_TYPE, DEFAULT_TITLE } from '../../constants';

import css from './ClaimForm.module.css';

const ClaimFormComponent = props => {
  const { onFetchPreviousListings } = props;
  useEffect(() => {
    onFetchPreviousListings();
  }, [onFetchPreviousListings]);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          rootClassName,
          handleSubmit,
          intl,
          history,
          invalid,
          disabled,
          pristine,
          saveActionMsg,
          // previous listings
          previousListings,
          previousListingsPagination,
          onFetchPreviousListings,
          queryPreviousListingsInProgress,
          queryPreviousListingsError,
          // claim item
          claimedItemId,
          claimInProgress,
          claimSent,
          claimError,
          onCreateNewListing,
        } = formRenderProps;

        const newListingLinkLabel = intl.formatMessage({
          id: 'ClaimForm.newListingLinkLabel',
        });

        const choosePreviousListingLabel = intl.formatMessage({
          id: 'ClaimForm.choosePreviousListingLabel',
        });
        const choosePreviousListingRequiredMessage = intl.formatMessage({
          id: 'ClaimForm.choosePreviousListingRequiredMessage',
        });
        const choosePreviousListingRequired = required(choosePreviousListingRequiredMessage);
        const queryListingsError = queryPreviousListingsError
          ? intl.formatMessage({ id: 'ClaimForm.queryListingsError' })
          : null;

        const claimListingError = claimError ? (
          <div className={css.error}>{intl.formatMessage({ id: 'ClaimForm.claimError' })}</div>
        ) : null;

        const noListings = previousListings.length === 0;

        const classes = classNames(rootClassName || css.root, className);

        const submitReady = claimSent;
        const submitInProgress = claimInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || claimSent;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.previousListingContainer}>
              {queryListingsError ? (
                <div className={css.error}>{queryListingsError}</div>
              ) : queryPreviousListingsInProgress ? (
                <IconSpinner className={css.spinner} />
              ) : (
                <div>
                  <div className={css.radioRow}>
                    <legend>{choosePreviousListingLabel}</legend>
                    {noListings && (
                      <div className={css.noListingLabel}>
                        {intl.formatMessage({ id: 'ClaimForm.noPreviousListings' })}
                      </div>
                    )}
                    {previousListings.map(l => (
                      <FieldRadioButton
                        name="listingId"
                        key={l.id}
                        id={l.id}
                        value={l.sharetribeId}
                        label={l.title}
                        checkedClassName={css.checkedAvailable}
                        showAsRequired={pristine}
                        validate={choosePreviousListingRequired}
                      />
                    ))}
                    {previousListingsPagination && previousListingsPagination.totalPages > 1 ? (
                      <PaginationLinks
                        useButtons={true}
                        className={css.pagination}
                        onFetch={onFetchPreviousListings}
                        pagination={previousListingsPagination}
                      />
                    ) : null}
                  </div>
                  {!noListings && (
                    <FieldTextInput
                      type="textarea"
                      name="message"
                      id="message"
                      label={intl.formatMessage({ id: 'ClaimForm.messageLabel' })}
                      placeholder={intl.formatMessage({ id: 'ClaimForm.messagePlaceholder' })}
                      className={css.message}
                    />
                  )}
                </div>
              )}
              {claimListingError}
              {!noListings && (
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              )}
            </div>

            <div className={css.newListingLink}>
              <div
                onClick={() =>
                  onCreateNewListing({
                    title: DEFAULT_TITLE,
                    publicData: {
                      listingType: LISTING_TYPE.LENDER_POST,
                    },
                  }).then(res => {
                    console.log(history)
                    const { uuid } = res.data.data.id;
                    const slug = createSlug(res.data.data.attributes.title);
                    history.push(`/l/${slug}/${uuid}/draft/description`, {
                      claimedItemId: claimedItemId,
                    });
                  })
                }
              >
                {newListingLinkLabel}
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

ClaimFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  queryPreviousListingsError: null,
  claimError: null,
};

ClaimFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  onFetchPreviousListings: func.isRequired,
  previousListings: arrayOf(object).isRequired,
  previousListingsPagination: object,
  queryPreviousListingsInProgress: bool.isRequired,
  claimedItemId: string,
  claimSent: bool.isRequired,
  claimInProgress: bool.isRequired,
  onCreateNewListing: func.isRequired,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(ClaimFormComponent);
