import { getCookie } from './cookieParser';

export const AUTH_COOKIE_KEY = process.env.REACT_APP_COOKIE_NAME;

export function getJwtToken() {
  const authCookie = getCookie(AUTH_COOKIE_KEY);
  try {
    if (authCookie) {
      const parsedCookie = JSON.parse(authCookie);
      return parsedCookie.access_token;
    }
  } catch (err) {
    console.error('Failed parsing token');
  }
  return null;
}
