import React, { useCallback, useMemo } from 'react';
import { bool, node, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Form,
  InlineTextButton,
  IconClose,
  PrimaryButton,
  FieldSelect,
  FieldNumberInput,
  FieldRadioButton,
} from '../../components';
import { composeValidators, minValue, required } from '../../util/validators';
import { AVAILABILITY_PERIODS } from '../../constants';
import { addMinutesToTime24, convertTime24To12, dateToMoment } from '../../util/dates';

import css from './EditListingAvailabilityPlanForm.module.css';

const printHourStrings = (h, m = '00') => (h > 9 ? `${h}:${m}` : `0${h}:${m}`);

const ALL_START_HOURS = [];
const ALL_END_HOURS = [];

for (let h = 0; h < 24; h++) {
  ALL_START_HOURS.push(printHourStrings(h), printHourStrings(h, 30));

  ALL_END_HOURS.push(printHourStrings(h, 30), printHourStrings(h + 1));
}

const sortEntries = (defaultCompareReturn = 0) => (a, b) => {
  if (a.startTime && b.startTime) {
    const [aHours, aMinutes] = a.startTime.split(':');
    const [bHours, bMinutes] = b.startTime.split(':');
    const aStart = Number.parseInt(aHours) * 60 + Number.parseInt(aMinutes);
    const bStart = Number.parseInt(bHours) * 60 + Number.parseInt(bMinutes);
    return aStart - bStart;
  }
  return defaultCompareReturn;
};

const findEntryFn = entry => e => e.startTime === entry.startTime && e.endTime === entry.endTime;

const filterStartHours = (availableStartHours, values, dayOfWeek, index) => {
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no end time selected, return all the available start times
  if (!currentEntry.endTime) {
    return availableStartHours;
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the previous entry
  const sortedEntries = [...entries].sort(sortEntries());

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry or the previous entry does not have endTime,
  // return all the available times before current selected end time.
  // Otherwise return all the available start times that are after the previous entry or entries.
  const prevEntry = sortedEntries[currentIndex - 1];
  const pickBefore = time => h => h < time;
  const pickBetween = (start, end) => h => h >= start && h < end;

  return !prevEntry || !prevEntry.endTime
    ? availableStartHours.filter(pickBefore(currentEntry.endTime))
    : availableStartHours.filter(pickBetween(prevEntry.endTime, currentEntry.endTime));
};

const filterEndHours = (availableEndHours, values, dayOfWeek, index) => {
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no start time selected, return an empty array;
  if (!currentEntry.startTime) {
    return [];
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the allowed start times
  const sortedEntries = [...entries].sort(sortEntries(-1));

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry,
  // return all the available end times that are after the start of current entry.
  // Otherwise return all the available end hours between current start time and next entry.
  const nextEntry = sortedEntries[currentIndex + 1];
  const pickAfter = time => h => h > time;
  const pickBetween = (start, end) => h => h > start && h <= end;

  return !nextEntry || !nextEntry.startTime
    ? availableEndHours.filter(pickAfter(currentEntry.startTime))
    : availableEndHours.filter(pickBetween(currentEntry.startTime, nextEntry.startTime));
};

const getEntryBoundaries = (values, dayOfWeek, findStartHours) => index => {
  const entries = values[dayOfWeek];

  return entries.reduce((allHours, entry, i) => {
    const { startTime, endTime } = entry || {};

    if (i !== index && startTime && endTime) {
      let currentTime = startTime;

      const hoursBetween = findStartHours ? [startTime] : [];

      // 24:00 is saved as 00:00
      const endTimeFinal = endTime === '00:00' ? '24:00' : endTime;

      while (currentTime < endTimeFinal) {
        const time = dateToMoment(addMinutesToTime24(currentTime, 30)).format('HH:mm');

        // If time is 00:00 it means we reached 24h
        // so we should break because of infinite loop
        if (time === '00:00') break;

        if (findStartHours && time === endTimeFinal) break;

        hoursBetween.push(time);

        currentTime = time;
      }

      return allHours.concat(hoursBetween);
    }

    return allHours;
  }, []);
};

const DailyPlan = props => {
  const { dayOfWeek, values, isFirstDayOfWeek, intl, form } = props;

  const getEntryStartTimes = useMemo(() => getEntryBoundaries(values, dayOfWeek, true), [
    dayOfWeek,
    values,
  ]);
  const getEntryEndTimes = useMemo(() => getEntryBoundaries(values, dayOfWeek, false), [
    dayOfWeek,
    values,
  ]);

  const isSpecificTime = useMemo(
    () => values.availabilityPeriod?.[dayOfWeek] === AVAILABILITY_PERIODS.SPECIFIC_TIMES,
    [dayOfWeek, values]
  );

  const hasEntries = useMemo(() => values[dayOfWeek] && values[dayOfWeek][0], [dayOfWeek, values]);

  const startTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.startTimePlaceholder',
  });
  const endTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.endTimePlaceholder',
  });

  const availabilityPeriodClasses = useMemo(
    () =>
      classNames({
        [css.labelAlwaysVisible]: isFirstDayOfWeek,
        [css.availabilityPeriodLabel]: !isFirstDayOfWeek,
      }),
    [isFirstDayOfWeek]
  );

  const onAvailabilityPeriodChange = useCallback(
    event => {
      const { value } = event.target;

      switch (value) {
        case AVAILABILITY_PERIODS.ALL_DAY:
          form.change(dayOfWeek, [{ startTime: '00:00', endTime: '24:00' }]);

          break;

        case AVAILABILITY_PERIODS.SPECIFIC_TIMES:
          form.change(dayOfWeek, [{ startTime: '09:00', endTime: '17:00' }]);

          break;

        case AVAILABILITY_PERIODS.NOT_AVAILABLE:
          form.change(dayOfWeek, []);

          break;

        default:
          break;
      }
    },
    [dayOfWeek, form]
  );

  const availabilityPeriodProps = useMemo(
    () => ({
      name: `availabilityPeriod.${dayOfWeek}`,
      svgClassName: css.radioButtonIcon,
      onChange: onAvailabilityPeriodChange,
      className: css.availabilityPeriodRadio,
      labelClassName: css.availabilityPeriodRadioLabel,
    }),
    [dayOfWeek, onAvailabilityPeriodChange]
  );

  return (
    <div className={classNames(css.weekDay, { [css.hasEntries]: hasEntries })}>
      <div className={css.dayOfWeekLabel}>
        <FormattedMessage id={`EditListingAvailabilityPlanForm.dayOfWeek.${dayOfWeek}`} />
      </div>

      <FieldArray name={dayOfWeek}>
        {({ fields }) => {
          return (
            <div className={css.dayOfWeek}>
              <div className={css.availabilityPeriod}>
                <FieldRadioButton
                  id={`availabilityPeriodNotAvailable.${dayOfWeek}`}
                  value={AVAILABILITY_PERIODS.NOT_AVAILABLE}
                  label={
                    <span className={availabilityPeriodClasses}>
                      {intl.formatMessage({
                        id: 'EditListingAvailabilityPlanForm.notAvailable',
                      })}
                    </span>
                  }
                  {...availabilityPeriodProps}
                />
                <FieldRadioButton
                  id={`availabilityPeriodAllDay.${dayOfWeek}`}
                  value={AVAILABILITY_PERIODS.ALL_DAY}
                  label={
                    <span className={availabilityPeriodClasses}>
                      {intl.formatMessage({
                        id: 'EditListingAvailabilityPlanForm.allDayLabel',
                      })}
                    </span>
                  }
                  {...availabilityPeriodProps}
                />
                <FieldRadioButton
                  id={`availabilityPeriodSpecificTimes.${dayOfWeek}`}
                  value={AVAILABILITY_PERIODS.SPECIFIC_TIMES}
                  label={
                    <span className={availabilityPeriodClasses}>
                      {intl.formatMessage({
                        id: 'EditListingAvailabilityPlanForm.specificTimesLabel',
                      })}
                    </span>
                  }
                  {...availabilityPeriodProps}
                />
              </div>

              {isSpecificTime ? (
                <div className={css.specificTimes}>
                  {fields.map((name, index) => {
                    // Pick available start hours
                    const pickUnreservedStartHours = h => !getEntryStartTimes(index).includes(h);
                    const availableStartHours = ALL_START_HOURS.filter(pickUnreservedStartHours);

                    // Pick available end hours
                    const pickUnreservedEndHours = h => !getEntryEndTimes(index).includes(h);
                    const availableEndHours = ALL_END_HOURS.filter(pickUnreservedEndHours);

                    return (
                      <div className={css.fieldWrapper} key={name}>
                        <div className={css.formRow}>
                          <div className={css.timeRow}>
                            <span className={css.fromToText}>
                              <FormattedMessage id="EditListingAvailabilityPlanForm.from" />
                            </span>
                            <div className={css.field}>
                              <FieldSelect
                                id={`${name}.startTime`}
                                name={`${name}.startTime`}
                                selectClassName={css.fieldSelect}
                              >
                                <option disabled value="">
                                  {startTimePlaceholder}
                                </option>
                                {filterStartHours(
                                  availableStartHours,
                                  values,
                                  dayOfWeek,
                                  index
                                ).map(s => (
                                  <option value={s} key={s}>
                                    {convertTime24To12(s)}
                                  </option>
                                ))}
                              </FieldSelect>
                            </div>
                            <span className={css.fromToText}>
                              <FormattedMessage id="EditListingAvailabilityPlanForm.to" />
                            </span>
                            <div className={css.field}>
                              <FieldSelect
                                id={`${name}.endTime`}
                                name={`${name}.endTime`}
                                selectClassName={css.fieldSelect}
                              >
                                <option disabled value="">
                                  {endTimePlaceholder}
                                </option>
                                {filterEndHours(availableEndHours, values, dayOfWeek, index).map(
                                  s => (
                                    <option value={s} key={s}>
                                      {convertTime24To12(s)}
                                    </option>
                                  )
                                )}
                              </FieldSelect>
                            </div>
                          </div>
                        </div>

                        <button
                          type="button"
                          className={classNames(css.buttonRemoveItem, {
                            [css.hiddenInput]: index === 0,
                          })}
                          onClick={() => fields.remove(index)}
                        >
                          <IconClose />
                        </button>
                      </div>
                    );
                  })}

                  {fields.length > 0 && (
                    <InlineTextButton
                      type="button"
                      className={css.buttonAddNew}
                      onClick={() =>
                        fields.push({
                          startTime: null,
                          endTime: null,
                        })
                      }
                    >
                      <FormattedMessage id="EditListingAvailabilityPlanForm.addAnother" />
                    </InlineTextButton>
                  )}
                </div>
              ) : null}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

const EditListingAvailabilityPlanFormComponent = props => {
  const { onSubmit, weekdays, ...restOfprops } = props;

  const onFormSubmit = useCallback(
    values => {
      const sortedValues = weekdays.reduce(
        (submitValues, day) => {
          const filteredValues = submitValues[day]
            ? submitValues[day].filter(t => t.startTime && t.endTime)
            : [];

          return filteredValues
            ? {
                ...submitValues,
                [day]: filteredValues.sort(sortEntries()),
              }
            : submitValues;
        },
        { ...values }
      );

      onSubmit(sortedValues);
    },
    [onSubmit, weekdays]
  );

  return (
    <FinalForm
      {...restOfprops}
      onSubmit={onFormSubmit}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          panelUpdated,
          fetchErrors,
          values,
          form,
          exceptionSection,
          saveActionMsg,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const seatsLabel = (
          <span className={css.dayOfWeekLabel}>
            {intl.formatMessage({
              id: 'EditListingAvailabilityPlanForm.seatsLabel',
            })}
          </span>
        );

        const seatsPlaceholder = intl.formatMessage({
          id: 'EditListingAvailabilityPlanForm.seatsPlaceholder',
        });

        const seatsRequired = intl.formatMessage({
          id: 'EditListingAvailabilityPlanForm.seatsRequired',
        });

        const seatsMinError = intl.formatMessage(
          {
            id: 'EditListingAvailabilityPlanForm.seatsMinError',
          },
          { min: 1 }
        );

        const seatsValidators = composeValidators(
          required(seatsRequired),
          minValue(seatsMinError, 1)
        );

        const { updateListingError } = fetchErrors || {};

        return (
          <Form id={formId} className={classes} onSubmit={handleSubmit}>
            <FieldNumberInput
              id="seats"
              name="seats"
              min={1}
              label={seatsLabel}
              placeholder={seatsPlaceholder}
              className={css.seatsField}
              inputClassName={css.seatsInput}
              validate={seatsValidators}
            />

            <div className={css.week}>
              {weekdays.map((w, i) => (
                <DailyPlan
                  dayOfWeek={w}
                  key={w}
                  values={values}
                  isFirstDayOfWeek={i === 0}
                  intl={intl}
                  form={form}
                />
              ))}
            </div>
            {exceptionSection}
            <div className={css.submitButton}>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingAvailabilityPlanForm.updateFailed" />
                </p>
              ) : null}
              <PrimaryButton
                type="submit"
                inProgress={inProgress}
                disabled={inProgress}
                ready={panelUpdated}
              >
                {saveActionMsg}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingAvailabilityPlanFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

EditListingAvailabilityPlanFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  panelUpdated: bool,
  fetchErrors: object.isRequired,

  exceptionSection: node,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingAvailabilityPlanForm = injectIntl(EditListingAvailabilityPlanFormComponent);

EditListingAvailabilityPlanForm.displayName = 'EditListingAvailabilityPlanForm';

export default EditListingAvailabilityPlanForm;
