import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { string } from 'prop-types';
import { Form, PrimaryButton, FieldInterests } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { saveInterests } from '../../containers/AccountSettingsPage/AccountSettingsPage.duck';
import arrayMutators from 'final-form-arrays';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './InterestsForm.module.css';

const categories = findOptionsForSelectFilter('category', config.custom.filters);

const InterestsForm = props => {
  const { rootClassName, className, intl, ...rest } = props;
  const { interests = [] } = rest.initialValues;

  const [isEditModeActive, setIsEditModeActive] = useState(false);

  const { saveInterestsError, saveInterestsInProgress, saveInterestsSent } = useSelector(
    ({ AccountSettingsPage }) => AccountSettingsPage
  );

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async values => {
      await dispatch(saveInterests(values));

      setIsEditModeActive(false);
    },
    [dispatch]
  );

  const currentInterests = useMemo(
    () =>
      categories
        .filter(({ key }) => interests.some(interest => key === interest))
        .map(({ label }) => <div>{label}</div>),
    [interests]
  );

  if (!isEditModeActive) {
    return (
      <>
        <h3 className={css.title}>
          <FormattedMessage id="InterestsForm.title" />
        </h3>
        <div className={css.description}>
          <FormattedMessage id="InterestsForm.description" />
        </div>
        <div className={css.formRow}>
          <div className={css.interests}>
            {currentInterests.length > 0 ? (
              currentInterests
            ) : (
              <FormattedMessage id="InterestsForm.noInterests" />
            )}
          </div>
          <PrimaryButton
            type="button"
            className={css.button}
            onClick={() => setIsEditModeActive(true)}
          >
            <FormattedMessage id="General.edit" />
          </PrimaryButton>
        </div>
      </>
    );
  }

  return (
    <FinalForm
      {...rest}
      onSubmit={onSubmit}
      mutators={arrayMutators}
      render={({ invalid, disabled, pristine, handleSubmit }) => {
        const submitDisabled = invalid || disabled || saveInterestsInProgress;

        return (
          <Form
            className={classNames(rootClassName || css.root, className)}
            onSubmit={handleSubmit}
          >
            <h3 className={css.title}>
              <FormattedMessage id="InterestsForm.title" />
            </h3>
            <div className={css.description}>
              <FormattedMessage id="InterestsForm.description" />
            </div>

            <div className={css.formRow}>
              <FieldInterests name="interests" />

              <PrimaryButton
                type="submit"
                className={css.button}
                disabled={submitDisabled}
                inProgress={saveInterestsInProgress}
                ready={pristine && saveInterestsSent}
              >
                <FormattedMessage id="General.save" />
              </PrimaryButton>
            </div>
            {saveInterestsError && (
              <div className={css.error}>
                <FormattedMessage id="General.error" />
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

InterestsForm.defaultProps = {
  rootClassName: null,
  className: null,
};

InterestsForm.propTypes = {
  rootClassName: string,
  className: string,
};

export default InterestsForm;
