import React, { Component } from 'react';
import { func, object, number, shape, string, bool, oneOfType, node } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldNumberInput.module.css';

const SPECIAL_NUMBER_CHARS = ['e', 'E', '+', '-'];

class FieldNumberInputComponent extends Component {
  render() {
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      input,
      label,
      placeholderText,
      meta,
      // eslint-disable-next-line no-unused-vars
      onUnmount,
      inputRef,
      showErrorBorder,
      inputClassName,
      ...rest
    } = this.props;
    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Use inputRef if it is passed a prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};

    const inputClasses =
      inputRootClass ||
      classNames(
        css.input,
        {
          [css.inputSuccess]: valid,
          [css.inputError]: !showErrorBorder && hasError,
          [css.borderError]: hasError && showErrorBorder,
        },
        inputClassName
      );

    const inputProps = {
      className: inputClasses,
      id,
      placeholder: placeholderText,
      type: 'number',
      ...input,
      ...refMaybe,
      ...rest,
    };

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <input
          {...inputProps}
          onKeyDown={e => SPECIAL_NUMBER_CHARS.includes(e.key) && e.preventDefault()}
        />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldNumberInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  customErrorText: null,
  showErrorBorder: false,
  id: null,
  label: null,
  input: null,
  placeholderText: null,
  meta: null,
  onUnmount: null,
  inputRef: null,
  min: null,
  max: null,
};

FieldNumberInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  inputClassName: string,
  customErrorText: string,
  showErrorBorder: bool,
  id: string,
  label: oneOfType([string, node]),
  placeholderText: string,
  meta: object.isRequired,
  input: shape({
    onBlur: func,
    onChange: func.isRequired,
    onFocus: func,
  }).isRequired,
  onUnmount: func,
  inputRef: object,
  min: number,
  max: number,
};

class FieldNumberInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldNumberInputComponent} {...this.props} />;
  }
}

export default FieldNumberInput;
