import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { string } from 'prop-types';

import TooltipImage from '../../assets/welcome-questionmark.png';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const {
    className,
    rootClassName,
    imageClassName,
    tooltipClassName,
    id,
    content,
    ...rest
  } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);
  const imageClasses = useMemo(() => classNames(imageClassName, css.image), [imageClassName]);
  const tooltipClasses = useMemo(() => classNames(tooltipClassName, css.tooltip), [
    tooltipClassName,
  ]);

  return (
    <div className={classes}>
      <img
        data-tip={id}
        data-for={id}
        src={TooltipImage}
        className={imageClasses}
        alt="tooltip-trigger"
      />
      <ReactTooltip effect="solid" id={id} className={tooltipClasses} {...rest}>
        {content}
      </ReactTooltip>
    </div>
  );
};

Tooltip.defaultProps = {
  className: null,
  rootClassName: null,
  imageClassName: null,
  tooltipClassName: null,
};

Tooltip.propTypes = {
  className: string,
  rootClassName: string,
  imageClassName: string,
  tooltipClassName: string,

  id: string.isRequired,
  content: string.isRequired,
};

export default Tooltip;
