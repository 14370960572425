import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp } from '../util/api';
import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import { currentUserShowSuccess } from './user.duck';
import * as log from '../util/log';
import StorageService from '../services/StorageService';
import api from '../api';
import { changeSelectedMarketKey, fetchBorrowedLocations } from './BorrowedLocation.duck';
import GeocoderMapbox from '../components/LocationAutocompleteInput/GeocoderMapbox';
import { placeOrigin } from '../util/maps';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/Auth/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/Auth/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/Auth/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/Auth/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/Auth/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/Auth/UPDATE_PROFILE_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/Auth/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/Auth/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/Auth/SEND_VERIFICATION_EMAIL_ERROR';

export const VERIFY_EMAIL_REQUEST = 'app/Auth/VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'app/Auth/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'app/Auth/VERIFY_EMAIL_ERROR';

export const SEND_VERIFICATION_PHONE_REQUEST = 'app/Auth/SEND_VERIFICATION_PHONE_REQUEST';
export const SEND_VERIFICATION_PHONE_SUCCESS = 'app/Auth/SEND_VERIFICATION_PHONE_SUCCESS';
export const SEND_VERIFICATION_PHONE_ERROR = 'app/Auth/SEND_VERIFICATION_PHONE_ERROR';

export const VERIFY_PHONE_REQUEST = 'app/Auth/VERIFY_PHONE_REQUEST';
export const VERIFY_PHONE_SUCCESS = 'app/Auth/VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_ERROR = 'app/Auth/VERIFY_PHONE_ERROR';

export const SETUP_INTENT_REQUEST = 'app/Auth/SETUP_INTENT_REQUEST';
export const SETUP_INTENT_SUCCESS = 'app/Auth/SETUP_INTENT_SUCCESS';
export const SETUP_INTENT_ERROR = 'app/Auth/SETUP_INTENT_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/Auth/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/Auth/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/Auth/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,

  // verify email
  sendVerificationEmailInProgress: false,
  sendVerificationEmailSent: true,
  sendVerificationEmailError: null,
  verifyEmailInProgress: false,
  verifyEmailError: null,

  // verify phone
  sendVerificationPhoneInProgress: false,
  sendVerificationPhoneSent: true,
  sendVerificationPhoneError: null,
  verifyPhoneInProgress: false,
  verifyPhoneError: null,

  // save card
  setupIntentInProgress: false,
  setupIntentError: null,
  setupIntent: null,
  stripeCustomerFetched: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return {
        ...state,
        signupInProgress: true,
        loginError: null,
        signupError: null,
      };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailSent: false,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailSent: true,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      console.error(payload);
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifyEmailInProgress: true,
        verifyEmailError: null,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmailInProgress: false,
      };
    case VERIFY_EMAIL_ERROR:
      console.error(payload);
      return {
        ...state,
        verifyEmailInProgress: false,
        verifyEmailError: payload,
      };

    case SEND_VERIFICATION_PHONE_REQUEST:
      return {
        ...state,
        sendVerificationPhoneInProgress: true,
        sendVerificationPhoneSent: false,
        sendVerificationPhoneError: null,
      };
    case SEND_VERIFICATION_PHONE_SUCCESS:
      return {
        ...state,
        sendVerificationPhoneInProgress: false,
        sendVerificationPhoneSent: true,
      };
    case SEND_VERIFICATION_PHONE_ERROR:
      console.error(payload);
      return {
        ...state,
        sendVerificationPhoneInProgress: false,
        sendVerificationPhoneError: payload,
      };
    case VERIFY_PHONE_REQUEST:
      return {
        ...state,
        verifyPhoneInProgress: true,
        verifyPhoneError: null,
      };
    case VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        verifyPhoneInProgress: false,
      };
    case VERIFY_PHONE_ERROR:
      return {
        ...state,
        verifyPhoneInProgress: false,
        verifyPhoneError: payload,
      };

    case SETUP_INTENT_REQUEST:
      return { ...state, setupIntentInProgress: true, setupIntentError: null };
    case SETUP_INTENT_SUCCESS:
      return {
        ...state,
        setupIntentInProgress: false,
        setupIntentError: null,
        setupIntent: payload,
      };
    case SETUP_INTENT_ERROR:
      console.error(payload);
      return { ...state, setupIntentInProgress: false, setupIntentError: null };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCUstomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload);
      return { ...state, stripeCustomerFetched: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

export const sendVerificationEmailRequest = () => ({ type: SEND_VERIFICATION_EMAIL_REQUEST });
export const sendVerificationEmailSuccess = () => ({ type: SEND_VERIFICATION_EMAIL_SUCCESS });
export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  payload: e,
});

export const verifyEmailRequest = () => ({ type: VERIFY_EMAIL_REQUEST });
export const verifyEmailSuccess = () => ({ type: VERIFY_EMAIL_SUCCESS });
export const verifyEmailError = e => ({ type: VERIFY_EMAIL_ERROR, payload: e });

export const sendVerificationPhoneRequest = () => ({ type: SEND_VERIFICATION_PHONE_REQUEST });
export const sendVerificationPhoneSuccess = () => ({ type: SEND_VERIFICATION_PHONE_SUCCESS });
export const sendVerificationPhoneError = e => ({
  type: SEND_VERIFICATION_PHONE_ERROR,
  payload: e,
});

export const verifyPhoneRequest = () => ({ type: VERIFY_PHONE_REQUEST });
export const verifyPhoneSuccess = () => ({ type: VERIFY_PHONE_SUCCESS });
export const verifyPhoneError = e => ({ type: VERIFY_PHONE_ERROR, payload: e });

export const setupIntentRequest = () => ({ type: SETUP_INTENT_REQUEST });
export const setupIntentSuccess = () => ({ type: SETUP_INTENT_SUCCESS });
export const setupIntentError = e => ({
  type: SETUP_INTENT_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(changeSelectedMarketKey(null, true));
      dispatch(clearCurrentUser());
      log.clearUserId();
      StorageService.removeToken();
      dispatch(userLogout());
      dispatch(fetchBorrowedLocations());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = (params, image) => async (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const {
    email,
    password,
    passwordVerified,
    firstName,
    lastName,
    displayName,
    phoneNumber,
    interests,
    zipCode,
    ...rest
  } = params;
  const role = params.accountType;

  const { predictions } = await new GeocoderMapbox().getPlacePredictions(zipCode);
  const { lat: lng, lng: lat } = placeOrigin(predictions[0]);

  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName, displayName }
    : {
        email,
        password,
        firstName,
        lastName,
        displayName,
        publicData: { ...rest, zipCode, userLocationFromZip: { lat, lng } },
        protectedData: { phoneNumber },
        privateData: { interests },
      };

  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.

  return sdk.currentUser
    .create(createUserParams)
    .then(res => {
      dispatch(signupSuccess());
      api.users.createUser({ ...params, sharetribeId: res.data.data.id.uuid, role });
    })
    .then(() => dispatch(login(email, password)))
    .then(() => image && dispatch(uploadImage(image)))
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file ? actionPayload.file : actionPayload,
    };

    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
        dispatch(updateProfile({ profileImageId: uploadedImage.id }));
      })
      .catch(e => {
        dispatch(uploadImageError({ id, error: storableError(e) }));
      });
  };
}

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

export const signupWithIdp = (params, image) => (dispatch, getState, sdk) => {
  dispatch(signupRequest());
  dispatch(confirmRequest());

  return createUserWithIdp(params)
    .then(() => {
      dispatch(confirmSuccess());
      return dispatch(signupSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .then(() => image && dispatch(uploadImage(image)))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      dispatch(confirmError());
      return dispatch(signupError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  dispatch(sendVerificationEmailRequest());

  return sdk.currentUser
    .sendVerificationEmail()
    .then(res => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(e)));
};

export const verifyEmail = token => (dispatch, getState, sdk) => {
  dispatch(verifyEmailRequest());

  return sdk.currentUser
    .verifyEmail({ verificationToken: token }, { expand: true })
    .then(response => {
      dispatch(verifyEmailSuccess());
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(e => dispatch(verifyEmailError(e)));
};

export const sendVerificationPhone = () => (dispatch, getState, sdk) => {
  dispatch(sendVerificationPhoneRequest());

  return api.users
    .sendSMSVerification()
    .then(res => dispatch(sendVerificationPhoneSuccess()))
    .catch(e => {
      dispatch(sendVerificationPhoneError(e));
    });
};

export const verifyPhone = code => (dispatch, getState, sdk) => {
  dispatch(verifyPhoneRequest());

  return api.users
    .verifyPhoneNumber(code)
    .then(res => {
      dispatch(verifyPhoneSuccess());

      const currentUser = getState().user.currentUser;
      currentUser.attributes.profile.metadata = {
        ...currentUser.attributes.profile.metadata,
        isPhoneNumberVerified: true,
      };
      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(e => dispatch(verifyPhoneError(e)));
};

export const verifyEmailAndPhone = (token, code) => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const { isPhoneNumberVerified } = currentUser.attributes.profile.metadata;
  const { emailVerified } = currentUser.attributes;
  if (!isPhoneNumberVerified && !emailVerified)
    return Promise.all([dispatch(verifyEmail(token)), dispatch(verifyPhone(code))]);
  if (!isPhoneNumberVerified) dispatch(verifyPhone(code));
  if (!emailVerified) dispatch(verifyEmail(token));
};

export const createStripeSetupIntent = () => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());
  return sdk.stripeSetupIntents
    .create()
    .then(response => {
      const setupIntent = response.data.data;
      dispatch(setupIntentSuccess(setupIntent));
      return setupIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'fetch-stripe-customer-failed');
      dispatch(stripeCustomerError(error));
    });
};
