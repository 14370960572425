import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckMark.module.css';

const SIZE_SMALL = 'small';
const SIZE_BIG = 'big';

const IconCheckmark = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);
  if (size === SIZE_SMALL) {
    return (
      <svg className={classes} width="16" height="12" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 2l-8 8-4-4"
          strokeWidth="2.5"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (size === SIZE_BIG) {
    return (
      <svg
        className={classes}
        strokeWidth="1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0.406 0.125 13.594 10.137"
        width="24"
        height="24"
      >
        <path d="M5.023 10.063L.605 5.645a.68.68 0 0 1 0-.961l.961-.962a.68.68 0 0 1 .961 0L5.504 6.7 11.878.324a.68.68 0 0 1 .961 0l.962.961a.68.68 0 0 1 0 .961l-7.817 7.817a.68.68 0 0 1-.961 0z" />
      </svg>
    );
  }
};

IconCheckmark.defaultProps = {
  rootClassName: null,
  className: null,
  size: 'big',
};

const { string } = PropTypes;

IconCheckmark.propTypes = {
  rootClassName: string,
  className: string,
  size: string,
};

export default IconCheckmark;
