import React, { Component } from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '../../components';

import css from './SelectToggleFilter.module.css';

const IconCheckbox = props => {
  const { className } = props;
  return (
    <svg className={className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M5.636621 10.7824771L3.3573694 8.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254782 2.8256927-4.5462307c.3934117-.5431636 1.1545778-.6695372 1.7055985-.278265.5473554.3912721.6731983 1.150729.2797866 1.6951077l-3.6650524 5.709111c-.2199195.306213-.5803433.5067097-.9920816.5067097-.3225487 0-.6328797-.1263736-.8637952-.3560334z" />
      </g>
    </svg>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectToggleFilter extends Component {
  constructor(props) {
    super(props);
    this.toggleOption = this.toggleOption.bind(this);
  }

  toggleOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      iconRootClassName,
      label,
      description,
      queryParamNames,
      initialValues,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const value =
      initialValues &&
      initialValues[queryParamName] &&
      typeof initialValues[queryParamName] === 'string'
        ? initialValues[queryParamName] === 'true'
        : initialValues[queryParamName];
    const classes = classNames(rootClassName || css.root, className);
    const svgClassName = classNames(css.icon || iconRootClassName);

    return (
      <div className={classes}>
        <div className={css.filterLabel}>
          <span>{label}</span>
        </div>
        <div className={css.filterDescription}>
          <span>{description}</span>
        </div>
        <div className={css.content}>
          <Button
            className={classNames(css.container, { [css.containerChecked]: value })}
            onClick={e => {
              e.preventDefault();
              this.toggleOption(!value);
            }}
          >
            <div className={classNames(css.circle, { [css.circleChecked]: value })}>
              {value && <IconCheckbox className={svgClassName} />}
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

SelectToggleFilter.defaultProps = {
  rootClassName: null,
  className: null,
  iconRootClassName: null,
};

SelectToggleFilter.propTypes = {
  rootClassName: string,
  className: string,
  iconRootClassName: string,
  label: string.isRequired,
  description: string.isRequired,
  initialValues: object,
};

export default SelectToggleFilter;
