import React, { useMemo } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import FieldSelectableCheckboxItem from '../FieldSelectableItem/FieldSelectableCheckboxItem';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './FieldInterests.module.css';

const interests = findOptionsForSelectFilter('category', config.custom.filters);

const FieldInterestsRenderer = props => {
  const { rootClassName, className, fields, ...rest } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return (
    <div className={classes}>
      <div className={css.itemsRoot}>
        {interests.map(({ key: value, label }) => {
          const fieldId = `${fields.name}.${value}`;

          return (
            <FieldSelectableCheckboxItem
              key={fieldId}
              id={fieldId}
              fields={fields}
              value={value}
              label={label}
              {...rest}
            />
          );
        })}
      </div>
    </div>
  );
};

FieldInterestsRenderer.defaultProps = {
  rootClassName: null,
  className: null,
};

FieldInterestsRenderer.propTypes = {
  rootClassName: string,
  className: string,
};

const FieldSelectableCheckboxGroup = props => (
  <FieldArray component={FieldInterestsRenderer} {...props} />
);

FieldSelectableCheckboxGroup.propTypes = {
  name: string.isRequired,
};

export default FieldSelectableCheckboxGroup;
