import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconAdd.module.css';

const IconAdd = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} viewBox="0.103 0.062 19.438 19.438" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.33 8.571h-7.298V1.273c0-.666-.544-1.21-1.21-1.21-.665 0-1.21.544-1.21 1.21V8.57H1.313a1.207 1.207 0 1 0-.855 2.065c.22.22.523.355.855.355h7.299v7.299c0 .333.136.635.355.854.22.22.522.356.855.356.666 0 1.21-.545 1.21-1.21V10.99h7.299c.665 0 1.21-.544 1.21-1.21 0-.665-.545-1.21-1.21-1.21z" />
    </svg>
  );
};

const { string } = PropTypes;

IconAdd.defaultProps = {
  className: null,
  rootClassName: null,
};

IconAdd.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAdd;
