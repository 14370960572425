import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Button, FieldToggle, FieldNumberInput, Form } from '../../components';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        values,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;

      const isOnSiteRentalChecked = values && values.onSite;
      const noValues =
        !(values.pickUp || values.onSite) ||
        (isOnSiteRentalChecked ? !values.onSiteRentalMinutes : false);

      const submitDisabled = disabled || submitInProgress || noValues;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.features}>
            <div className={css.feature}>
              <div className={css.checkboxRow}>
                <FieldToggle
                  id="pickUp"
                  name="pickUp"
                  label={
                    <h2 className={css.checkboxLabel}>
                      <FormattedMessage id="EditListingFeaturesForm.pickupTitle" />
                    </h2>
                  }
                />
              </div>
              <div className={css.featureDescription}>
                <FormattedMessage id="EditListingFeaturesForm.pickupDescription" />
              </div>
            </div>
            <div className={css.feature}>
              <div className={css.checkboxRow}>
                <FieldToggle
                  id="onSite"
                  name="onSite"
                  label={
                    <h2 className={css.checkboxLabel}>
                      <FormattedMessage id="EditListingFeaturesForm.onSiteTitle" />
                    </h2>
                  }
                />
              </div>
              <div className={css.featureDescription}>
                <FormattedMessage id="EditListingFeaturesForm.onSiteDescription" />
              </div>
            </div>
            {isOnSiteRentalChecked && (
              <div>
                <div className={css.featureDescription}>
                  <FormattedMessage id="EditListingFeaturesForm.onSiteMinutesDescription1" />
                </div>
                <div className={css.checkboxRow}>
                  <FieldNumberInput
                    type="number"
                    id="onSiteRentalMinutes"
                    name="onSiteRentalMinutes"
                    className={css.minutesInput}
                  />
                  <div className={css.checkboxInput}>
                    <FormattedMessage id="EditListingFeaturesForm.onSiteMinutes" />
                  </div>
                </div>
                <div className={css.featureDescription}>
                  <FormattedMessage id="EditListingFeaturesForm.onSiteMinutesDescription2" />
                </div>
              </div>
            )}
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
