import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { InlineTextButton, PrimaryButton } from '../Button/Button';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { SendMessageForm } from '../../forms';
import IconArrowHead from '../IconArrowHead/IconArrowHead';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import FeedSection from '../TransactionPanel/FeedSection';
import IconClose from '../IconClose/IconClose';

import css from './Chat.module.css';

const Chat = props => {
  const {
    show,
    paramId,
    currentTransaction,
    currentCustomer,
    currentProvider,
    ensuredCurrentUser,
    fetchMessagesError,
    fetchOlderMessagesDone,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchMessagesInProgress,
    messages,
    onOpenReviewModal,
    onShowMoreMessages,
    sendMessageInProgress,
    sendMessageError,
    onMessageSubmit,
    isProvider,
    intl,
    history,
  } = props;

  const [isPaymentNoteShown, setIsPaymentNoteShown] = useState(false);
  const [oldScrollHeight, setOldScrollHeight] = useState(0);

  const chatContent = useRef();

  const viewBooking = useMemo(() => intl.formatMessage({ id: 'Chat.viewBooking' }), [intl]);
  const inputPlaceholderMsg = useMemo(() => intl.formatMessage({ id: 'Chat.typeMessage' }), [intl]);
  const sendingMessageNotAllowed = useMemo(
    () => intl.formatMessage({ id: 'Chat.sendingMessageNotAllowed' }),
    [intl]
  );
  const noChatOpened = useMemo(
    () =>
      !paramId ? (
        <div className={css.noContent}>
          <h3>
            <FormattedMessage id={'Chat.noChatOpened'} />
          </h3>
          <p>
            <FormattedMessage id={'Chat.noChatOpenedInfo'} />
          </p>
        </div>
      ) : null,
    [paramId]
  );
  const isCustomerLoaded = useMemo(() => !!currentCustomer.id, [currentCustomer.id]);
  const isCustomerBanned = useMemo(() => isCustomerLoaded && currentCustomer?.attributes.banned, [
    currentCustomer,
    isCustomerLoaded,
  ]);
  const isCustomerDeleted = useMemo(() => isCustomerLoaded && currentCustomer?.attributes.deleted, [
    currentCustomer,
    isCustomerLoaded,
  ]);
  const isProviderLoaded = useMemo(() => !!currentProvider.id, [currentProvider.id]);
  const isProviderBanned = useMemo(() => isProviderLoaded && currentProvider?.attributes.banned, [
    currentProvider,
    isProviderLoaded,
  ]);
  const isProviderDeleted = useMemo(() => isProviderLoaded && currentProvider?.attributes.deleted, [
    currentProvider,
    isProviderLoaded,
  ]);
  const showSendMessageForm = useMemo(
    () =>
      isCustomerLoaded &&
      isProviderLoaded &&
      !isCustomerBanned &&
      !isCustomerDeleted &&
      !isProviderBanned &&
      !isProviderDeleted,
    [
      isCustomerBanned,
      isCustomerDeleted,
      isCustomerLoaded,
      isProviderBanned,
      isProviderDeleted,
      isProviderLoaded,
    ]
  );

  const user = useMemo(() => (isProvider(currentTransaction) ? currentCustomer : currentProvider), [
    currentCustomer,
    currentProvider,
    currentTransaction,
    isProvider,
  ]);

  const chatReady = useMemo(() => ensuredCurrentUser.id && currentTransaction && messages, [
    currentTransaction,
    ensuredCurrentUser.id,
    messages,
  ]);

  const chatTitleMsg = useMemo(
    () =>
      isCustomerLoaded && isProviderLoaded
        ? intl.formatMessage(
            { id: 'Chat.chatTitle' },
            {
              displayName:
                user?.publicData?.accountType === 'Individual'
                  ? user?.publicData?.username
                  : user?.publicData?.businessName,
            }
          )
        : intl.formatMessage({ id: 'Chat.chatDisabled' }),
    [intl, user, isCustomerLoaded, isProviderLoaded]
  );

  const openTransactionDetails = useCallback(() => {
    history.push(
      createResourceLocatorString(
        isProvider(currentTransaction) ? 'SaleDetailsPage' : 'OrderDetailsPage',
        routeConfiguration(),
        { id: currentTransaction.id.uuid },
        {}
      )
    );
  }, [currentTransaction, history, isProvider]);

  const handleSendMessageFormFocus = useCallback(() => {
    chatContent.current?.scroll({ top: chatContent.current.scrollHeight, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (chatReady && chatContent && chatContent.current) {
      const newScrollHeight = chatContent.current.scrollHeight;
      // Keep scroll at the same position when older messages are loaded
      // or scroll to the bottom when a new message is received or new chat is opened
      chatContent.current.scroll({
        top: fetchOlderMessagesDone ? newScrollHeight - oldScrollHeight : newScrollHeight,
      });
      setOldScrollHeight(newScrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length, ensuredCurrentUser.id]);

  return show ? (
    <div className={css.rootChat}>
      {chatReady ? (
        <React.Fragment>
          <div className={css.chatHeader}>
            <InlineTextButton className={css.backButton} onClick={() => history.push('/messages')}>
              <IconArrowHead direction="left" />
            </InlineTextButton>
            <h3 className={css.chatHeaderTitle}>{chatTitleMsg}</h3>
            <PrimaryButton className={css.viewBooking} onClick={() => openTransactionDetails()}>
              {viewBooking}
            </PrimaryButton>
            {!isPaymentNoteShown && (
              <div className={css.paymentNote}>
                <FormattedMessage id="Chat.protectBooking" />
                <InlineTextButton onClick={() => setIsPaymentNoteShown(true)}>
                  <IconClose rootClassName={css.closeIcon} />
                </InlineTextButton>
              </div>
            )}
          </div>
          <div ref={chatContent} className={css.chatContent}>
            <FeedSection
              rootClassName={css.feedRoot}
              showFeedHeading={false}
              currentTransaction={currentTransaction}
              currentUser={ensuredCurrentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={false}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id.uuid)}
              totalMessagePages={totalMessagePages}
            />
          </div>
          <div className={css.chatFooter}>
            {showSendMessageForm ? (
              <SendMessageForm
                formId={'Chat.SendMessageForm'}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={inputPlaceholderMsg}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={handleSendMessageFormFocus}
                onSubmit={(values, form) => onMessageSubmit(values, form)}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}
          </div>
        </React.Fragment>
      ) : (
        noChatOpened
      )}
    </div>
  ) : null;
};

Chat.defaultProps = {
  show: true,
  messages: [],
  totalMessagePages: 0,
  fetchOlderMessagesDone: false,
};

Chat.propTypes = {
  show: bool.isRequired,
  paramId: string,
  currentTransaction: object,
  currentCustomer: object,
  currentProvider: object,
  currentListing: object,
  ensuredCurrentUser: object,
  messages: array,
  fetchMessagesError: propTypes.error,
  fetchMessagesInProgress: bool.isRequired,
  fetchOlderMessagesDone: bool.isRequired,
  totalMessagePages: number,
  oldestMessagePageFetched: number.isRequired,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onOpenReviewModal: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onMessageSubmit: func.isRequired,
  isProvider: func.isRequired,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(Chat);
