import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconMinus.module.css';

const IconMinus = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="20" height="20" viewBox="0 0 20 20">
      <path d="M1 10L18 10" strokeWidth="2.4" />
    </svg>
  );
};

IconMinus.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMinus.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMinus;
