import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { BORROWING_TYPE } from '../../constants';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;
const WEEKDAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const formatDailyPricesToCurrency = (prices, currency) => {
  const pricesToFormat = cloneDeep(prices);

  for (const day in pricesToFormat) {
    const selectedDay = pricesToFormat[day];
    if (selectedDay.hourlyPrice)
      selectedDay.hourlyPrice = new Money(selectedDay.hourlyPrice, currency);
    if (selectedDay.dailyPrice)
      selectedDay.dailyPrice = new Money(selectedDay.dailyPrice, currency);
    if (selectedDay.weeklyPrice)
      selectedDay.weeklyPrice = new Money(selectedDay.weeklyPrice, currency);
  }

  return pricesToFormat;
};

const formatCurrencyToDailyPrices = prices => {
  const pricesToFormat = cloneDeep(prices);

  for (let day in pricesToFormat) {
    const selectedDay = pricesToFormat[day];
    if (selectedDay.hourlyPrice) selectedDay.hourlyPrice = selectedDay.hourlyPrice.amount;
    else delete selectedDay.hourlyPrice;
    if (selectedDay.dailyPrice) selectedDay.dailyPrice = selectedDay.dailyPrice.amount;
    else delete selectedDay.dailyPrice;
    if (selectedDay.weeklyPrice) selectedDay.weeklyPrice = selectedDay.weeklyPrice.amount;
    else delete selectedDay.weeklyPrice;
    if (Object.keys(selectedDay).length === 0) delete pricesToFormat[day];
  }

  return pricesToFormat;
};

const getDefaultPrice = (pricePerHour, pricePerDay, pricePerWeek, pricesPerDayOfWeek) => {
  return pricePerHour || pricePerDay || pricePerWeek || getFirstPriceFromObject(pricesPerDayOfWeek);
};

const getFirstPriceFromObject = prices => {
  const pricesToFormat = cloneDeep(prices);

  for (let day in pricesToFormat) {
    const selectedDay = pricesToFormat[day];
    if (selectedDay.hourlyPrice) return selectedDay.hourlyPrice;
    if (selectedDay.dailyPrice) return selectedDay.dailyPrice;
    if (selectedDay.weeklyPrice) return selectedDay.weeklyPrice;
  }
};

const getBorrowingType = (pricePerHour, pricePerDay, pricePerWeek) => {
  const borrowingType = [];
  if (pricePerHour) borrowingType.push(BORROWING_TYPE.HOURLY);
  if (pricePerDay) borrowingType.push(BORROWING_TYPE.DAILY);
  if (pricePerWeek) borrowingType.push(BORROWING_TYPE.WEEKLY);
  return borrowingType;
};

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = useMemo(() => ensureOwnListing(listing), [listing]);

  const { price, state, publicData } = useMemo(() => currentListing.attributes, [currentListing]);

  const { pricePerRate, pricesPerDayOfWeek } = useMemo(() => publicData, [publicData]);

  const { pricePerHour, pricePerDay, pricePerWeek } = useMemo(() => pricePerRate || {}, [
    pricePerRate,
  ]);

  const isPublished = useMemo(() => currentListing.id && state !== LISTING_STATE_DRAFT, [
    currentListing.id,
    state,
  ]);
  const panelTitle = useMemo(
    () =>
      isPublished ? (
        <FormattedMessage
          id="EditListingPricingPanel.title"
          values={{
            listingTitle: <ListingLink listing={listing}>{listing.attributes.title}</ListingLink>,
          }}
        />
      ) : (
        <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
      ),
    [isPublished, listing]
  );

  const priceCurrencyValid = useMemo(
    () => (price instanceof Money ? price.currency === config.currency : true),
    [price]
  );

  const hasPricesPerDayOfWeek = useMemo(
    () => pricesPerDayOfWeek && Object.keys(pricesPerDayOfWeek).length > 0,
    [pricesPerDayOfWeek]
  );

  const pricesPerDayOfWeekMaybe = useMemo(
    () =>
      hasPricesPerDayOfWeek && priceCurrencyValid
        ? { pricesPerDayOfWeek: formatDailyPricesToCurrency(pricesPerDayOfWeek, price?.currency) }
        : {},
    [hasPricesPerDayOfWeek, price, priceCurrencyValid, pricesPerDayOfWeek]
  );

  const showWeekPricesMaybe = useMemo(
    () =>
      hasPricesPerDayOfWeek
        ? {
            showWeekPrices: ['showWeekPrices'],
          }
        : {},
    [hasPricesPerDayOfWeek]
  );

  const initialValues = useMemo(
    () => ({
      pricePerHour:
        pricePerHour && priceCurrencyValid ? new Money(pricePerHour, price?.currency) : null,
      pricePerDay:
        pricePerDay && priceCurrencyValid ? new Money(pricePerDay, price?.currency) : null,
      pricePerWeek:
        pricePerWeek && priceCurrencyValid ? new Money(pricePerWeek, price?.currency) : null,
      ...pricesPerDayOfWeekMaybe,
      ...showWeekPricesMaybe,
    }),
    [
      price,
      priceCurrencyValid,
      pricePerDay,
      pricePerHour,
      pricePerWeek,
      pricesPerDayOfWeekMaybe,
      showWeekPricesMaybe,
    ]
  );

  const handleSubmit = useCallback(
    formValues => {
      const {
        pricePerHour,
        pricePerDay,
        pricePerWeek,
        showWeekPrices,
        pricesPerDayOfWeek,
      } = formValues;

      const defaultPrice = getDefaultPrice(
        pricePerHour,
        pricePerDay,
        pricePerWeek,
        pricesPerDayOfWeek
      );

      const pricePerHourMaybe = pricePerHour ? { pricePerHour: pricePerHour.amount } : {};
      const pricePerDayMaybe = pricePerDay ? { pricePerDay: pricePerDay.amount } : {};
      const pricePerWeekMaybe = pricePerWeek ? { pricePerWeek: pricePerWeek.amount } : {};

      const pricesPerDayOfWeekMaybe =
        pricesPerDayOfWeek && showWeekPrices && showWeekPrices.length > 0
          ? { pricesPerDayOfWeek: formatCurrencyToDailyPrices(pricesPerDayOfWeek) }
          : { pricesPerDayOfWeek: {} };

      const borrowingType = getBorrowingType(pricePerHour, pricePerDay, pricePerWeek);

      const updatedValues = {
        price: defaultPrice,
        publicData: {
          borrowingType,
          pricePerRate: {
            ...pricePerHourMaybe,
            ...pricePerWeekMaybe,
            ...pricePerDayMaybe,
          },
          ...pricesPerDayOfWeekMaybe,
        },
      };

      onSubmit(updatedValues);
    },
    [onSubmit]
  );

  return (
    <div className={classes}>
      <div className={css.header}>
        <h1 className={css.title}>{panelTitle}</h1>
        <p className={css.description}>
          <FormattedMessage id="EditListingPricingPanel.description" />
        </p>
      </div>

      {priceCurrencyValid ? (
        <EditListingPricingForm
          className={css.form}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          weekdays={WEEKDAYS}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      ) : (
        <div className={css.priceCurrencyInvalid}>
          <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
        </div>
      )}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
