import React from 'react';
import { string } from 'prop-types';
import { FieldSelect } from '../../components';
import { required } from '../../util/validators';

import css from './EditListingDescriptionForm.module.css';

const CustomCategorySelectField = props => {
  const {
    name,
    id,
    categoryLabelMesage,
    categoryRequiredMessage,
    categoryPlaceholderMessage,
    categoryOptions,
  } = props;

  return categoryOptions ? (
    <FieldSelect
      className={css.category}
      selectClassName={css.select}
      name={name}
      id={id}
      label={categoryLabelMesage}
      validate={required(categoryRequiredMessage)}
    >
      <option value="" disabled>
        {categoryPlaceholderMessage}
      </option>
      {categoryOptions.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

CustomCategorySelectField.defaultProps = {
  categoryOptions: [],
};

CustomCategorySelectField.propTypes = {
  categoryLabelMesage: string.isRequired,
  categoryRequiredMessage: string.isRequired,
  categoryPlaceholderMessage: string.isRequired,
};

export default CustomCategorySelectField;
