import React, { Component, useCallback, useMemo } from 'react';
import { array, string, arrayOf, func, shape, object } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import config from '../../config';
import { ResponsiveImage } from '..';
import { InlineTextButton } from '../Button/Button';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withRouter } from 'react-router';
import NamedLink from '../NamedLink/NamedLink';
import { compose } from 'redux';
import IconSpinner from '../IconSpinner/IconSpinner';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getDayOfWeekStringFromDate } from '../../util/dates';
import { AVAILABILITY_PERIODS } from '../../constants';

import css from './ListingsTable.module.css';

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingsTable.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingsTable.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCategoryInfo = (categoryOptions, key) => {
  return categoryOptions.find(c => c.key === key);
};

const createListingURL = (routes, listing) => {
  const id = listing.sharetribeId;
  const slug = createSlug(listing.title);
  const isPendingApproval = listing.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.state === LISTING_STATE_DRAFT;
  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : null;

  const linkProps =
    isPendingApproval || isDraft
      ? {
          name: 'ListingPageVariant',
          params: {
            id,
            slug,
            variant,
          },
        }
      : {
          name: 'ListingPage',
          params: { id, slug },
        };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

const calculateListingQuantity = (timeslot, availabilityPeriod = {}) => {
  const { timeslots = [] } = timeslot || {};

  const timeslotsLength = timeslots.length;

  if (timeslotsLength < 1) return 0;

  const dayOfWeek = getDayOfWeekStringFromDate(timeslots[0].attributes.start);

  const isDayOfWeekAvailable = availabilityPeriod[dayOfWeek] !== AVAILABILITY_PERIODS.NOT_AVAILABLE;

  if (!isDayOfWeekAvailable) return 0;

  let maxAvailableSeatsInTimeslot = 0;

  for (let index = 0; index < timeslotsLength; index++) {
    const {
      attributes: { seats },
    } = timeslots[index];

    maxAvailableSeatsInTimeslot = Math.max(maxAvailableSeatsInTimeslot, seats);
  }

  return maxAvailableSeatsInTimeslot;
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingsTableComponent = props => {
  const {
    className,
    spacingClassName,
    intl,
    listings,
    timeslots,
    renderSizes,
    filtersConfig,
    history,
    actionsInProgressListingId,
    onToggleMenu,
    onCloseListing,
    onOpenListing,
    onDiscardListing,
    openingErrorListingId,
    closingErrorListingId,
    discardErrorListingId,
  } = props;

  const categoryOptions = findOptionsForSelectFilter('category', filtersConfig);
  const notAvailable = useMemo(() => intl.formatMessage({ id: 'ListingsTable.notAvailable' }), [
    intl,
  ]);

  const onOverListingLink = useCallback(() => {
    // Enforce preloading of ListingPage (loadable component)
    const { component: Page } = findRouteByRouteName('ListingPage', routeConfiguration());
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  }, []);

  const tableHeaders = useMemo(
    () => [
      intl.formatMessage({ id: 'ListingsTable.listingTitle' }),
      intl.formatMessage({ id: 'ListingsTable.category' }),
      intl.formatMessage({ id: 'ListingsTable.quantity' }),
      intl.formatMessage({ id: 'ListingsTable.hourlyPrice' }),
      intl.formatMessage({ id: 'ListingsTable.dailyPrice' }),
      intl.formatMessage({ id: 'ListingsTable.weeklyPrice' }),
      intl.formatMessage({ id: 'ListingsTable.views' }),
      intl.formatMessage({ id: 'ListingsTable.transactions' }),
      intl.formatMessage({ id: 'ListingsTable.actions' }),
    ],
    [intl]
  );

  const classes = useMemo(() => classNames(css.root, className), [className]);

  return (
    <table className={classes}>
      <thead className={css.header}>
        <tr>
          <th />
          {tableHeaders.map(header => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody className={css.body}>
        {listings.map(l => {
          const { sharetribeId, title, price, publicData = {}, metadata, state, image } = l;
          const timeslot = timeslots[sharetribeId];

          const slug = createSlug(title);

          const firstImage = image && image.variants ? { attributes: image } : null;

          const { category: pCategory, pricePerRate, availabilityPeriod } = publicData || {};

          const { transactionCount } = metadata || {};

          const quantity = timeslot ? (
            timeslot.inProgress ? (
              <IconSpinner />
            ) : timeslot.error ? (
              <div className={css.error}>
                <FormattedMessage id="ListingsTable.quantityFetchError" />
              </div>
            ) : (
              calculateListingQuantity(timeslot, availabilityPeriod)
            )
          ) : null;

          const category = getCategoryInfo(categoryOptions, pCategory);

          const { pricePerHour, pricePerDay, pricePerWeek } = pricePerRate || {};

          const pricePerHourAsMoney = pricePerHour
            ? new Money(pricePerHour, price.currency || 'USD')
            : null;

          const { formattedPrice } = priceData(pricePerHourAsMoney, intl);

          const pricePerDayAsMoney = pricePerDay
            ? new Money(pricePerDay, price.currency || 'USD')
            : null;
          const pricePerWeekAsMoney = pricePerWeek
            ? new Money(pricePerWeek, price.currency || 'USD')
            : null;

          const { formattedPrice: formattedPriceDay } = priceData(pricePerDayAsMoney, intl);
          const { formattedPrice: formattedPriceWeek } = priceData(pricePerWeekAsMoney, intl);

          const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
          const isClosed = state === LISTING_STATE_CLOSED;

          const isDraft = state === LISTING_STATE_DRAFT;

          const isPublished = state === LISTING_STATE_PUBLISHED;
          const editListingLinkType = isDraft
            ? LISTING_PAGE_PARAM_TYPE_DRAFT
            : LISTING_PAGE_PARAM_TYPE_EDIT;

          const thisListingInProgress =
            actionsInProgressListingId && actionsInProgressListingId === sharetribeId;

          const hasOpeningError = openingErrorListingId === sharetribeId;
          const hasClosingError = closingErrorListingId === sharetribeId;
          const hasDiscardError = discardErrorListingId === sharetribeId;

          const hasError = hasOpeningError || hasClosingError || hasDiscardError;

          const titleClasses = classNames(css.title, {
            [css.titlePending]: isPendingApproval,
          });

          const listingStatus = isClosed ? (
            <div className={classNames(css.listingState, css.error)}>
              {intl.formatMessage({ id: 'ListingsTable.listingClosed' })}
            </div>
          ) : isDraft ? (
            <div className={css.listingState}>
              {intl.formatMessage({ id: 'ListingsTable.listingDraft' })}
            </div>
          ) : null;

          return (
            <React.Fragment key={sharetribeId}>
              <tr>
                <td>
                  <div className={css.imageWrapper}>
                    <div className={css.aspectWrapper}>
                      <LazyImage
                        rootClassName={classNames(css.rootForImage, {
                          [css.noImageWrapper]: !firstImage,
                        })}
                        alt={title}
                        image={firstImage}
                        variants={['default']}
                        sizes={renderSizes}
                      />
                    </div>
                  </div>
                </td>

                <td>
                  {listingStatus}

                  <div
                    className={css.titleWrapper}
                    onMouseOver={onOverListingLink}
                    onTouchStart={onOverListingLink}
                  >
                    <InlineTextButton
                      className={titleClasses}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        // ManageListingCard contains links, buttons and elements that are working with routing.
                        // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
                        //
                        // NOTE: It might be better to absolute-position those buttons over a card-links.
                        // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
                        history.push(createListingURL(routeConfiguration(), l));
                      }}
                    >
                      {richText(title, {
                        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                        longWordClass: css.longWord,
                      })}
                    </InlineTextButton>
                  </div>
                </td>

                <td>{category ? <span>{category.label}</span> : notAvailable}</td>

                <td>{quantity}</td>

                <td>{formattedPrice ? <div>{formattedPrice}</div> : notAvailable}</td>

                <td>{formattedPriceDay ? <div>{formattedPriceDay}</div> : notAvailable}</td>

                <td>{formattedPriceWeek ? <div>{formattedPriceWeek}</div> : notAvailable}</td>

                <td>{notAvailable}</td>
                <td>{transactionCount ? <div>{transactionCount}</div> : notAvailable}</td>
                <td>
                  {isDraft && (
                    <NamedLink
                      className={css.menuItem}
                      name="EditListingPage"
                      params={{
                        id: sharetribeId,
                        slug,
                        type: LISTING_PAGE_PARAM_TYPE_DRAFT,
                        tab: 'photos',
                      }}
                    >
                      {intl.formatMessage({ id: 'ListingsTable.finishListingDraft' })}
                    </NamedLink>
                  )}
                  {isDraft && (
                    <InlineTextButton
                      rootClassName={classNames(css.menuItem, {
                        [css.menuItemDisabled]: !!actionsInProgressListingId,
                      })}
                      disabled={!!actionsInProgressListingId}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        if (!actionsInProgressListingId) {
                          onToggleMenu(null);
                          onDiscardListing(sharetribeId);
                        }
                      }}
                    >
                      {intl.formatMessage({ id: 'ListingsTable.discardListing' })}
                    </InlineTextButton>
                  )}
                  {isPublished && (
                    <NamedLink
                      className={css.menuItem}
                      name="EditListingPage"
                      params={{
                        id: sharetribeId,
                        slug,
                        type: editListingLinkType,
                        tab: 'description',
                      }}
                    >
                      {intl.formatMessage({ id: 'ListingsTable.edit' })}
                    </NamedLink>
                  )}
                  {isClosed && (
                    <InlineTextButton
                      rootClassName={classNames(css.menuItem, {
                        [css.menuItemDisabled]: !!actionsInProgressListingId,
                      })}
                      disabled={!!actionsInProgressListingId}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        if (!actionsInProgressListingId) {
                          onToggleMenu(null);
                          onOpenListing(sharetribeId);
                        }
                      }}
                    >
                      {intl.formatMessage({ id: 'ListingsTable.openListing' })}
                    </InlineTextButton>
                  )}
                  {isPublished && (
                    <InlineTextButton
                      rootClassName={classNames(css.menuItem, css.menuItemClose, {
                        [css.menuItemDisabled]: !!actionsInProgressListingId,
                      })}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (!actionsInProgressListingId) {
                          onToggleMenu(null);
                          onCloseListing(sharetribeId);
                        }
                      }}
                    >
                      {intl.formatMessage({ id: 'ListingsTable.closeListing' })}
                    </InlineTextButton>
                  )}
                </td>
              </tr>
              <tr className={classNames(css.spacing, spacingClassName)}>
                <td colSpan="10">
                  {thisListingInProgress ? (
                    <div className={classNames(css.spinner, css.alignRight)}>
                      <IconSpinner />
                    </div>
                  ) : hasError ? (
                    <div className={classNames(css.alignRight, css.error)}>
                      {intl.formatMessage({ id: 'ListingsTable.actionFailed' })}
                    </div>
                  ) : null}
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

ListingsTableComponent.defaultProps = {
  className: null,
  spacingClassName: null,
  filtersConfig: config.custom.filters,
  listings: [],
  timeslots: {},

  renderSizes: null,
};

ListingsTableComponent.propTypes = {
  className: string,
  spacingClassName: string,
  intl: intlShape.isRequired,
  filtersConfig: array,
  listings: arrayOf(object).isRequired,
  timeslots: object,

  // Responsive image sizes hint
  renderSizes: string,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(ListingsTableComponent);
