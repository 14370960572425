import { useCallback, useState } from 'react';

export const useCallbackRef = init => {
  const [ref, setRef] = useState(init);

  const refCallback = useCallback(element => {
    if (element !== null) {
      setRef(element);
    }
  }, []);

  return [ref, refCallback];
};
