class StorageService {
  static ram = {};

  static setItem(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      StorageService.ram[key] = value;
    }
  }

  static setWithExpiry(key, value, ttlms) {
    const item = {
      value,
      expiry: new Date().getTime() + ttlms,
    };

    try {
      localStorage.setItem(key, JSON.stringify(item));
    } catch (e) {
      StorageService.ram[key] = value;
    }
  }

  static removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      StorageService.ram[key] = undefined;
    }
  }

  static getItem(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return StorageService.ram[key];
    }
  }

  static getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    const { value, expiry } = JSON.parse(itemStr);

    if (new Date().getTime() > expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return value;
  }

  static setToken(token) {
    StorageService.setItem('token', token);
  }

  static getToken() {
    if (StorageService.getItem('token')) return StorageService.getItem('token');
    return null;
  }

  static removeToken() {
    StorageService.removeItem('token');
  }
}

export default StorageService;
