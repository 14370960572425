import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldNumberInput } from '../../components';

import css from './ChangeBookingDatesForm.module.css';

const ChangeBookingDatesFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        invalid,
        extendBookingSent,
        extendBookingError,
        extendBookingInProgress,
      } = fieldRenderProps;

      const extendPlaceholderMessage = intl.formatMessage({
        id: 'ChangeBookingDatesForm.extendPlaceholder',
      });
      const extendRequiredMessage = intl.formatMessage({
        id: 'ChangeBookingDatesForm.extendRequired',
      });

      const errorMessage = extendBookingError ? (
        <p className={css.error}>
          <FormattedMessage id="ChangeBookingDatesForm.extendFailed" />
        </p>
      ) : null;
      const errorArea = extendBookingError ? errorMessage : null;

      const extendSubmitMessage = intl.formatMessage({
        id: 'ChangeBookingDatesForm.submit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = extendBookingInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldNumberInput
            id="hours"
            name="hours"
            placeholder={extendPlaceholderMessage}
            className={css.seatsInput}
            validate={required(extendRequiredMessage)}
          />

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={extendBookingSent}
          >
            {extendSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ChangeBookingDatesFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  extendBookingError: null,
};

const { bool, func, string } = PropTypes;

ChangeBookingDatesFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  extendBookingSent: bool.isRequired,
  extendBookingInProgress: bool.isRequired,
};

const ChangeBookingDatesForm = compose(injectIntl)(ChangeBookingDatesFormComponent);
ChangeBookingDatesForm.displayName = 'ChangeBookingDatesForm';

export default ChangeBookingDatesForm;
