import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LogoDesktop from '../../assets/LogoFullLight.png';
import LogoMobile from '../../assets/LogoLight.png';

import css from './Logo.module.css';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return <img className={className} {...rest} src={LogoDesktop} alt="borrowed-logo" />;
  }

  return (
    <img
      className={classNames(className, css.logoMobile)}
      {...rest}
      src={LogoMobile}
      alt="borrowed-logo"
    />
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
