import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, Button, FieldRadioButton } from '../../components';
import { LISTING_TYPE } from '../../constants';

import css from './EditListingTypeForm.module.css';

export const EditListingTypeFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        listingTypeOptions,
        claimedItemId,
      } = fieldRenderProps;

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingTypeForm.updateFailed" />
        </p>
      ) : null;

      const typeRequired = required(
        intl.formatMessage({
          id: 'EditListingTypeForm.typeRequired',
        })
      );

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { history } = props;
      const disableListingType = history.action !== 'REPLACE';

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}

          <div>
            <div className={classNames(css.radioButtonRow, { [css.disabled]: disableListingType })}>
              {listingTypeOptions.map(o => {
                return (
                  <FieldRadioButton
                    id={o.key}
                    name={'listingType'}
                    label={o.label}
                    value={o.key}
                    key={o.key}
                    validate={typeRequired}
                    disabled={
                      claimedItemId ? o.key === LISTING_TYPE.WANTED_POST : disableListingType
                    }
                  />
                );
              })}
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingTypeFormComponent.defaultProps = {
  updateError: null,
  claimedItemId: null,
};

EditListingTypeFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  claimedItemId: string,
  listingTypeOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default injectIntl(EditListingTypeFormComponent);
