import { array, bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { formatDateForWantedPosts } from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { InlineTextButton } from '../Button/Button';
import { NamedLink } from '..';
import { propTypes } from '../../util/types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import IconSpinner from '../IconSpinner/IconSpinner';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import defaultImage from '../../assets/wanted-item-avatar.png';

import css from './TransactionCards.module.css';
import { useCallbackRef } from '../../util/hooks';

const TransactionCards = props => {
  const {
    show,
    paramId,
    transactions,
    fetchTransactionsError,
    fetchTransactionsInProgress,
    pagination,
    onShowMoreTransactions,
    ensuredCurrentUser,
    mobileViewport,
    history,
    intl,
  } = props;

  const deletedListing = useMemo(
    () => intl.formatMessage({ id: 'TransactionCards.deletedListing' }),
    [intl]
  );
  const deletedUser = useMemo(() => intl.formatMessage({ id: 'TransactionCards.deletedUser' }), [
    intl,
  ]);
  const errorTransactions = useMemo(
    () =>
      fetchTransactionsError ? (
        <p className={css.error}>
          <FormattedMessage id="TransactionCards.fetchTransactionsFailed" />
        </p>
      ) : null,
    [fetchTransactionsError]
  );
  const noTransactions = useMemo(
    () =>
      !fetchTransactionsInProgress && transactions.length === 0 && !fetchTransactionsError ? (
        <div className={css.noContent}>
          <FormattedMessage id={'TransactionCards.noTransactions'} />
        </div>
      ) : null,
    [fetchTransactionsError, fetchTransactionsInProgress, transactions.length]
  );

  const handleMessageCardClick = useCallback(
    transaction => history.push(`/messages/${transaction ? transaction.sharetribeId : ''}`),
    [history]
  );

  useEffect(() => {
    if (!mobileViewport && !paramId && transactions.length > 0) {
      handleMessageCardClick(transactions[0]);
    }
  }, [handleMessageCardClick, mobileViewport, paramId, transactions]);

  const [msgCardsRef, msgCardsRefCallback] = useCallbackRef(null);
  const [txCardRef, txCardRefCallback] = useCallbackRef(null);
  const [scrolled, setScrolled] = useState(false);

  // Scroll to opened transaction on first render
  useEffect(() => {
    if (msgCardsRef && txCardRef && !scrolled) {
      const myOffset = 179;
      const bottomOfTxCard = txCardRef.offsetHeight + txCardRef.offsetTop - myOffset;
      if (bottomOfTxCard - msgCardsRef.scrollTop > msgCardsRef.clientHeight) {
        setScrolled(true);
        txCardRef.scrollIntoView();
      }
    }
  }, [msgCardsRef, txCardRef, scrolled]);

  return show ? (
    <div ref={msgCardsRefCallback} className={css.root}>
      {errorTransactions}
      {noTransactions}
      {transactions.map((tx, i) => {
        const { sharetribeId, listing, buyer, seller, booking, lastMessage } = tx;
        const otherParticipant =
          ensuredCurrentUser.id?.uuid === tx.seller.sharetribeId ? buyer : seller;

        const bookingDate =
          booking && booking.displayStart ? new Date(booking.displayStart) : new Date(tx.createdAt);
        const { date } = formatDateForWantedPosts(intl, bookingDate);

        const { seen, sentBy } = lastMessage || {};
        const isSentByCurrentUser = sentBy === ensuredCurrentUser.id?.uuid;
        const hasUnreadMessage =
          typeof seen === 'undefined' ? false : !seen && !isSentByCurrentUser;

        const {
          username,
          image,
          sharetribeId: userSharetribeId,
          publicData: { pronouns = null } = {},
        } = otherParticipant || {};
        const imageVariants = image && image.variants && Object.keys(image.variants);
        const profileImage =
          imageVariants && imageVariants[0] && image.variants[imageVariants[0]].url;

        return (
          <div
            id={`tx-${sharetribeId}`}
            ref={elem => (sharetribeId === paramId ? txCardRefCallback(elem) : null)}
            key={sharetribeId}
            className={classNames(css.messageCard, {
              [css.messageCardActive]: sharetribeId === paramId,
            })}
            onClick={() => handleMessageCardClick(tx)}
          >
            <div className={css.imageRoot}>
              <div className={css.imageWrapper}>
                {userSharetribeId ? (
                  <NamedLink
                    name="ProfilePage"
                    params={{ id: userSharetribeId }}
                    className={css.aspectWrapper}
                  >
                    {ensuredCurrentUser.id && (
                      <img
                        className={css.rootForImage}
                        src={profileImage || defaultImage}
                        alt={username}
                      />
                    )}
                  </NamedLink>
                ) : (
                  <img className={css.rootForImage} src={defaultImage} alt="" />
                )}
              </div>
            </div>
            <div className={css.messageCardContent}>
              <h3 className={css.itemName}>
                {otherParticipant?.publicData?.accountType === 'Individual'
                  ? otherParticipant?.publicData?.username
                  : otherParticipant?.publicData?.businessName}
                {pronouns && Array.isArray(pronouns) && `(${pronouns.join(', ')})`}
                {!userSharetribeId && deletedUser}
              </h3>
              <p className={css.personName}>
                {!listing || listing.deleted ? deletedListing : listing.title}
              </p>
              <div className={css.infoWrapper}>
                <div className={css.startDateContainer}>
                  <p>{date}</p>
                </div>
              </div>
            </div>
            {hasUnreadMessage ? <div className={css.dot}></div> : null}
          </div>
        );
      })}
      {pagination?.page < pagination?.totalPages ? (
        <InlineTextButton onClick={() => onShowMoreTransactions(pagination)}>
          <p className={css.showMore}>
            {fetchTransactionsInProgress ? (
              <IconSpinner />
            ) : (
              <FormattedMessage id={'TransactionCards.showMore'} />
            )}
          </p>
        </InlineTextButton>
      ) : null}
    </div>
  ) : null;
};

TransactionCards.defaultProps = {
  show: true,
  transactions: [],
  pagination: null,
};

TransactionCards.propTypes = {
  show: bool.isRequired,
  paramId: string,
  transactions: array.isRequired,
  fetchTransactionsError: propTypes.error,
  fetchTransactionsInProgress: bool.isRequired,
  pagination: object,
  onShowMoreTransactions: func.isRequired,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(TransactionCards);
