import React from 'react';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  composeValidators,
  maxLength,
  moneySubUnitAmountAtLeast,
  required,
  forbidBadWords,
} from '../../util/validators';
import { formatMoney } from '../../util/currency';
import {
  Button,
  Form,
  FieldRadioButton,
  FieldTextInput,
  FieldDateInput,
  FieldCurrencyInput,
  FieldNumberInput,
  FieldSelect,
} from '../../components';
import CustomCategorySelectField from '../EditListingDescriptionForm/CustomCategorySelectField';
import { types as sdkTypes } from '../../util/sdkLoader';
import { SUB_CATEGORY } from '../../constants';
import { convertTime24To12 } from '../../util/dates';

import css from './EditListingWantedForm.module.css';

const { Money } = sdkTypes;
const TITLE_MAX_LENGTH = 60;

const printHourStrings = (h, m = '00') => (h > 9 ? `${h}:${m}` : `0${h}:${m}`);

const ALL_START_HOURS = [];
const ALL_END_HOURS = [];

for (let h = 0; h < 24; h++) {
  ALL_START_HOURS.push(printHourStrings(h), printHourStrings(h, 30));

  ALL_END_HOURS.push(printHourStrings(h, 30), printHourStrings(h + 1));
}

export const EditListingWantedFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        values,
        updated,
        updateError,
        updateInProgress,
        categoryOptions,
        hasFetchCityError,
        isFetchingCity,
      } = fieldRenderProps;

      const badWordsForbidden = forbidBadWords(
        intl.formatMessage({ id: 'EditListingDescriptionForm.badWordsError' })
      );

      const borrowingType = values.borrowingType;

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingWantedForm.updateFailed" />
        </p>
      ) : null;

      const zipCodeInvalidError = hasFetchCityError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingWantedForm.zipCodeInvalid" />
        </p>
      ) : null;

      // title
      const titleMessage = intl.formatMessage({ id: 'EditListingWantedForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingWantedForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingWantedForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      // description
      const descriptionMessage = intl.formatMessage({ id: 'EditListingWantedForm.description' });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingWantedForm.descriptionPlaceholder',
      });

      // zip code
      const zipCodeLabel = intl.formatMessage({
        id: 'EditListingWantedForm.zipCodeLabel',
      });
      const zipCodePlaceholder = intl.formatMessage({
        id: 'EditListingWantedForm.zipCodePlaceholder',
      });
      const zipCodeRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.zipCodeRequired',
      });
      const zipCodeValidators = required(zipCodeRequiredMessage);

      // quantity
      const quantityLabel = intl.formatMessage({
        id: 'EditListingWantedForm.quantityLabel',
      });
      const quantityPlaceholder = intl.formatMessage({
        id: 'EditListingWantedForm.quantityPlaceholder',
      });
      const quantityRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.quantityRequiredMessage',
      });
      const quantityRequired = required(quantityRequiredMessage);

      // borrowing type
      const itemTypeLabel = intl.formatMessage({
        id: 'EditListingWantedForm.typeLabel',
      });
      const typeRequired = required(
        intl.formatMessage({
          id: 'EditListingWantedForm.typeRequired',
        })
      );
      const typeHourlyLabel = intl.formatMessage({
        id: 'EditListingWantedForm.typeHourlyLabel',
      });
      const typeDailyLabel = intl.formatMessage({
        id: 'EditListingWantedForm.typeDailyLabel',
      });
      const typeWeeklyLabel = intl.formatMessage({
        id: 'EditListingWantedForm.typeWeeklyLabel',
      });

      // start date for daily and montly
      const startDateLabel = intl.formatMessage({
        id: 'EditListingWantedForm.startDateLabel',
      });
      const startDatePlaceholder = intl.formatMessage({
        id: 'EditListingWantedForm.startDatePlaceholderText',
      });
      const startDateRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.startDateRequiredMessage',
      });
      const startDateRequired = required(startDateRequiredMessage);

      // end date for daily and montly
      const endDateLabel = intl.formatMessage({
        id: 'EditListingWantedForm.endDateLabel',
      });
      const endDatePlaceholder = intl.formatMessage({
        id: 'EditListingWantedForm.endDatePlaceholderText',
      });
      const endDateRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.endDateRequiredMessage',
      });
      const endDateRequired = required(endDateRequiredMessage);

      // date for hourly
      const hourlyDateLabel = intl.formatMessage({
        id: 'EditListingWantedForm.hourlyDateLabel',
      });
      const hourlyDatePlaceholder = intl.formatMessage({
        id: 'EditListingWantedForm.hourlyDatePlaceholder',
      });
      const hourlyDateRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.hourlyDateRequiredMessage',
      });
      const hourlyDateRequired = required(hourlyDateRequiredMessage);

      // start time for hourly
      const startTimeLabel = intl.formatMessage({
        id: 'EditListingWantedForm.startTimeLabel',
      });
      const startTimePlaceholder = intl.formatMessage({
        id: 'EditListingWantedForm.startTimePlaceholder',
      });
      const startTimeRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.startTimeRequiredMessage',
      });
      const startTimeRequired = required(startTimeRequiredMessage);

      // end time hourly
      const endTimeLabel = intl.formatMessage({
        id: 'EditListingWantedForm.endTimeLabel',
      });
      const endTimePlaceholder = intl.formatMessage({
        id: 'EditListingWantedForm.endTimePlaceholder',
      });
      const endTimeRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.endTimeRequiredMessage',
      });
      const endTimeRequired = required(endTimeRequiredMessage);

      // price
      const pricePerHourLabel = intl.formatMessage({
        id: 'EditListingWantedForm.pricePerHourLabel',
      });
      const pricePerDayLabel = intl.formatMessage({
        id: 'EditListingWantedForm.pricePerDayLabel',
      });
      const pricePerWeekLabel = intl.formatMessage({
        id: 'EditListingWantedForm.pricePerWeekLabel',
      });
      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingWantedForm.pricePlaceholder',
      });
      const priceRequired = required(
        intl.formatMessage({
          id: 'EditListingWantedForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingWantedForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress || isFetchingCity;
      const submitDisabled = invalid || disabled || submitInProgress || isFetchingCity;

      const startHours = values.endTime
        ? ALL_START_HOURS.filter(h => h < values.endTime)
        : ALL_START_HOURS;

      const endHours = values.startTime
        ? ALL_END_HOURS.filter(h => h > values.startTime)
        : ALL_END_HOURS;

      const categoryLabelMesage = intl.formatMessage({
        id: 'EditListingWantedForm.categoryLabel',
      });
      const categoryRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.categoryRequiredMessage',
      });
      const categoryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingWantedForm.categoryPlaceholder',
      });

      const subCategoryOptions = values && values.category ? SUB_CATEGORY[values.category] : [];
      const subCategoryLabelMessage = intl.formatMessage({
        id: 'EditListingWantedForm.subCategoryLabel',
      });
      const subCategoryRequiredMessage = intl.formatMessage({
        id: 'EditListingWantedForm.subCategoryRequiredMessage',
      });
      const subCategoryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingWantedForm.subCategoryPlaceholder',
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          <div>
            <FieldTextInput
              className={css.title}
              type="text"
              id="title"
              name="title"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              validate={composeValidators(
                required(titleRequiredMessage),
                maxLength60Message,
                badWordsForbidden
              )}
              maxLength={TITLE_MAX_LENGTH}
            />

            <FieldTextInput
              className={css.title}
              type="textarea"
              id="description"
              name="description"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage), badWordsForbidden)}
            />

            <FieldNumberInput
              className={css.title}
              id="zipCode"
              name="zipCode"
              autoComplete="off"
              label={zipCodeLabel}
              placeholder={zipCodePlaceholder}
              validate={zipCodeValidators}
            />

            <CustomCategorySelectField
              id="category"
              name="category"
              categoryOptions={categoryOptions}
              categoryLabelMesage={categoryLabelMesage}
              categoryRequiredMessage={categoryRequiredMessage}
              categoryPlaceholderMessage={categoryPlaceholderMessage}
            />

            <CustomCategorySelectField
              id="subCategory"
              name="subCategory"
              categoryOptions={subCategoryOptions}
              categoryLabelMesage={subCategoryLabelMessage}
              categoryRequiredMessage={subCategoryRequiredMessage}
              categoryPlaceholderMessage={subCategoryPlaceholderMessage}
            />

            <FieldNumberInput
              className={css.quantity}
              id="quantity"
              name="quantity"
              label={quantityLabel}
              placeholder={quantityPlaceholder}
              validate={quantityRequired}
            />

            <div className={css.radioButtonRow}>
              <legend>{itemTypeLabel}</legend>
              <FieldRadioButton
                id="hourly"
                name="borrowingType"
                label={typeHourlyLabel}
                value="hourly"
                key="hourly"
                validate={typeRequired}
              />
              <FieldRadioButton
                id="daily"
                name="borrowingType"
                label={typeDailyLabel}
                value="daily"
                key="daily"
                validate={typeRequired}
              />
              <FieldRadioButton
                id="weekly"
                name="borrowingType"
                label={typeWeeklyLabel}
                value="weekly"
                key="weekly"
                validate={typeRequired}
              />
            </div>
            {borrowingType ? (
              borrowingType === 'hourly' ? (
                <div>
                  <FieldDateInput
                    id="hourlyDate"
                    name="hourlyDate"
                    label={hourlyDateLabel}
                    placeholderText={hourlyDatePlaceholder}
                    validate={hourlyDateRequired}
                  />
                  <div className={css.dates}>
                    <FieldSelect
                      className={css.startDate}
                      disabled={!values.hourlyDate}
                      id="startTime"
                      name="startTime"
                      label={startTimeLabel}
                      validate={startTimeRequired}
                    >
                      <option disabled value="">
                        {startTimePlaceholder}
                      </option>
                      {startHours.map(h => (
                        <option key={h} value={h}>
                          {convertTime24To12(h)}
                        </option>
                      ))}
                    </FieldSelect>
                    <FieldSelect
                      disabled={!values.hourlyDate}
                      className={css.endDate}
                      id="endTime"
                      name="endTime"
                      label={endTimeLabel}
                      validate={endTimeRequired}
                    >
                      <option disabled value="">
                        {endTimePlaceholder}
                      </option>
                      {endHours.map(h => (
                        <option key={h} value={h}>
                          {convertTime24To12(h)}
                        </option>
                      ))}
                    </FieldSelect>
                  </div>
                </div>
              ) : (
                <div className={css.dates}>
                  <FieldDateInput
                    className={css.startDate}
                    id="startDate"
                    name="startDate"
                    label={startDateLabel}
                    placeholderText={startDatePlaceholder}
                    isDayBlocked={v => (values.endDate ? v >= values.endDate.date : false)}
                    validate={startDateRequired}
                  />
                  <FieldDateInput
                    className={css.endDate}
                    id="endDate"
                    name="endDate"
                    label={endDateLabel}
                    placeholderText={endDatePlaceholder}
                    isDayBlocked={v => (values.startDate ? v <= values.startDate.date : false)}
                    validate={endDateRequired}
                  />
                </div>
              )
            ) : null}
            {borrowingType && (
              <FieldCurrencyInput
                className={css.price}
                id="price"
                name="price"
                label={
                  borrowingType === 'hourly'
                    ? pricePerHourLabel
                    : borrowingType === 'daily'
                    ? pricePerDayLabel
                    : pricePerWeekLabel
                }
                placeholder={pricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
                validate={priceValidators}
              />
            )}

            {zipCodeInvalidError}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingWantedFormComponent.defaultProps = { updateError: null };

EditListingWantedFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
};

export default injectIntl(EditListingWantedFormComponent);
