import Axios from 'axios';
import StorageService from '../services/StorageService';

const httpClient = Axios.create({
  baseURL: `${process.env.REACT_APP_BORROWED_API_URL}/api/`,
});

httpClient.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    const ourJwt = StorageService.getToken();

    const accessToken = ourJwt ? `Bearer ${ourJwt}` : null;

    if (accessToken) {
      config.headers.Authorization = accessToken;
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default httpClient;
