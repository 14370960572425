import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { formatDateForWantedPosts } from '../../util/dates';

import { FieldDateInput } from '../../components';
import css from './DateRangeController.module.css';

class DateRangeController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.value && props.value.startDate ? new Date(props.value.startDate) : null,
      endDate: props.value && props.value.endDate ? new Date(props.value.endDate) : null,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  onDatesChange(values) {
    const { startDate, endDate } = values;

    const start = startDate ? startDate.date : null;
    const end = endDate ? endDate.date : null;

    if (startDate && endDate) {
      this.setState({ startDate: start, endDate: end });
    } else if (startDate) {
      this.setState({ startDate: start });
    } else {
      this.setState({ endDate: end });
    }
  }

  onReset(startDate, endDate) {
    if (startDate && endDate) {
      this.setState({
        startDate: moment(startDate),
        endDate: moment(endDate),
      });
    } else {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
  }

  render() {
    const { intl, rootClassName, className, value } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const startDateFromState = this.state.startDate;
    const endDateFromState = this.state.endDate;

    const startDateFromForm = value && value.startDate ? new Date(value.startDate) : null;
    const endDateFromForm = value && value.endDate ? new Date(value.endDate) : null;

    const isSelected = startDateFromState && endDateFromState;

    // Value given by Final Form reflects url params and is valid if both dates are set.
    // If only one date is selected state should be used to get the correct date.
    const startDate = isSelected ? startDateFromForm : startDateFromState;
    const endDate = isSelected ? endDateFromForm : endDateFromState;

    const placeholder = formatDateForWantedPosts(intl, new Date()).date;

    return (
      <div className={classes}>
        <FieldDateInput
          initialDate={startDate}
          id="startDate"
          name="startDate"
          placeholderText={placeholder}
          isDayBlocked={v => (endDate ? v >= endDate : false)}
          onChange={startDate => this.onDatesChange({ startDate })}
          readOnly
          useMobileMargins
        />
        <label className={css.label}>
          {intl.formatMessage({ id: 'DateRangeController.separator' })}
        </label>
        <FieldDateInput
          initialDate={endDate}
          id="endDate"
          name="endDate"
          placeholderText={placeholder}
          isDayBlocked={v => (startDate ? v <= startDate : false)}
          onChange={endDate => this.onDatesChange({ endDate })}
          readOnly
          useMobileMargins
        />
      </div>
    );
  }
}

DateRangeController.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

DateRangeController.propTypes = {
  rootClassName: string,
  className: string,
  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(DateRangeController);
