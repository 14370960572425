import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { TRANSITION_ACCEPT } from '../../util/transaction';
import api from '../../api';

const updateProfileQueryParams = {
  expand: true,
  include: ['profileImage', 'stripeAccount', 'stripeCustomer.defaultPaymentMethod'],
  'fields.image': ['variants.square-small', 'variants.square-small2x'],
};

// ================ Action types ================ //

export const UPLOAD_IMAGE_REQUEST = 'app/AccountSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/AccountSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/AccountSettingsPage/UPLOAD_IMAGE_ERROR';

export const SAVE_NAME_AND_PRONOUNS_REQUEST =
  'app/AccountSettingsPage/SAVE_NAME_AND_PRONOUNS_REQUEST';
export const SAVE_NAME_AND_PRONOUNS_SUCCESS =
  'app/AccountSettingsPage/SAVE_NAME_AND_PRONOUNS_SUCCESS';
export const SAVE_NAME_AND_PRONOUNS_ERROR = 'app/AccountSettingsPage/SAVE_NAME_AND_PRONOUNS_ERROR';

export const SAVE_BIO_REQUEST = 'app/AccountSettingsPage/SAVE_BIO_REQUEST';
export const SAVE_BIO_SUCCESS = 'app/AccountSettingsPage/SAVE_BIO_SUCCESS';
export const SAVE_BIO_ERROR = 'app/AccountSettingsPage/SAVE_BIO_ERROR';

export const SAVE_INTERESTS_REQUEST = 'app/AccountSettingsPage/SAVE_INTERESTS_REQUEST';
export const SAVE_INTERESTS_SUCCESS = 'app/AccountSettingsPage/SAVE_INTERESTS_SUCCESS';
export const SAVE_INTERESTS_ERROR = 'app/AccountSettingsPage/SAVE_INTERESTS_ERROR';

export const SAVE_EMAIL_REQUEST = 'app/AccountSettingsPage/SAVE_EMAIL_REQUEST';
export const SAVE_EMAIL_SUCCESS = 'app/AccountSettingsPage/SAVE_EMAIL_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/AccountSettingsPage/SAVE_EMAIL_ERROR';

export const VERIFY_EMAIL_REQUEST = 'app/AccountSettingsPage/VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'app/AccountSettingsPage/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'app/AccountSettingsPage/VERIFY_EMAIL_ERROR';

export const SAVE_PHONE_REQUEST = 'app/AccountSettingsPage/SAVE_PHONE_REQUEST';
export const SAVE_PHONE_SUCCESS = 'app/AccountSettingsPage/SAVE_PHONE_SUCCESS';
export const SAVE_PHONE_ERROR = 'app/AccountSettingsPage/SAVE_PHONE_ERROR';

export const VERIFY_PHONE_REQUEST = 'app/AccountSettingsPage/VERIFY_PHONE_REQUEST';
export const VERIFY_PHONE_SUCCESS = 'app/AccountSettingsPage/VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_ERROR = 'app/AccountSettingsPage/VERIFY_PHONE_ERROR';

export const CHANGE_PASSWORD_REQUEST = 'app/AccountSettingsPage/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'app/AccountSettingsPage/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/AccountSettingsPage/CHANGE_PASSWORD_ERROR';

export const CHECK_FOR_ACTIVE_TRANSACTIONS_REQUEST =
  'app/AccountSettingsPage/CHECK_FOR_ACTIVE_TRANSACTIONS_REQUEST';
export const CHECK_FOR_ACTIVE_TRANSACTIONS_SUCCESS =
  'app/AccountSettingsPage/CHECK_FOR_ACTIVE_TRANSACTIONS_SUCCESS';
export const CHECK_FOR_ACTIVE_TRANSACTIONS_ERROR =
  'app/AccountSettingsPage/CHECK_FOR_ACTIVE_TRANSACTIONS_ERROR';

export const DELETE_USER_REQUEST = 'app/AccountSettingsPage/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'app/AccountSettingsPage/DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'app/AccountSettingsPage/DELETE_USER_ERROR';

export const ADD_PROMO_CODE_REQUEST = 'app/AccountSettingsPage/ADD_PROMO_CODE_REQUEST';
export const ADD_PROMO_CODE_SUCCESS = 'app/AccountSettingsPage/ADD_PROMO_CODE_SUCCESS';
export const ADD_PROMO_CODE_ERROR = 'app/AccountSettingsPage/ADD_PROMO_CODE_ERROR';

export const FETCH_USED_PROMO_CODES_REQUEST =
  'app/AccountSettingsPage/FETCH_USED_PROMO_CODES_REQUEST';
export const FETCH_USED_PROMO_CODES_SUCCESS =
  'app/AccountSettingsPage/FETCH_USED_PROMO_CODES_SUCCESS';
export const FETCH_USED_PROMO_CODES_ERROR = 'app/AccountSettingsPage/FETCH_USED_PROMO_CODES_ERROR';

// ================ Reducer ================ //

const initialState = {
  uploadImageError: null,
  uploadInProgress: false,

  saveNameAndPronounsError: null,
  saveNameAndPronounsInProgress: false,
  saveNameAndPronounsSent: false,

  saveBioError: null,
  saveBioInProgress: false,
  saveBioSent: false,

  saveInterestsError: null,
  saveInterestsInProgress: false,
  saveInterestsSent: false,

  saveEmailError: null,
  saveEmailInProgress: false,
  saveEmailSent: false,

  verifyEmailError: null,
  verifyEmailSent: false,
  verifyEmailInProgress: false,

  savePhoneError: null,
  savePhoneSent: false,
  savePhoneInProgress: false,

  verifyPhoneError: null,
  verifyPhoneSent: false,
  verifyPhoneInProgress: false,

  changePasswordError: null,
  changePasswordInProgress: false,
  passwordChanged: false,

  activeTransactionsInProgress: false,
  activeTransactionsCount: null,
  activeTransactionsError: null,

  addPromoCodeError: null,
  promoCodeAdded: false,
  addPromoCodeInProgress: false,

  fetchUsedPromoCodesInProgress: false,
  usedPromoCodes: [],
  fetchUsedPromoCodesError: null,

  deleteInProgress: false,
  deleteError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      return { ...state, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, uploadInProgress: false, uploadImageError: payload.error };
    }

    case SAVE_NAME_AND_PRONOUNS_REQUEST:
      return {
        ...state,
        saveNameAndPronounsError: null,
        saveNameAndPronounsInProgress: true,
        saveNameAndPronounsSent: false,
      };
    case SAVE_NAME_AND_PRONOUNS_SUCCESS:
      return {
        ...state,
        saveNameAndPronounsInProgress: false,
        saveNameAndPronounsSent: true,
      };
    case SAVE_NAME_AND_PRONOUNS_ERROR:
      console.error(payload);
      return {
        ...state,
        saveNameAndPronounsError: payload,
        saveNameAndPronounsInProgress: false,
      };

    case SAVE_BIO_REQUEST:
      return {
        ...state,
        saveBioError: null,
        saveBioInProgress: true,
      };
    case SAVE_BIO_SUCCESS:
      return {
        ...state,
        saveBioInProgress: false,
        saveBioSent: true,
      };
    case SAVE_BIO_ERROR:
      console.error(payload);
      return {
        ...state,
        saveBioError: payload,
        saveBioInProgress: false,
      };

    case SAVE_INTERESTS_REQUEST:
      return {
        ...state,
        saveInterestsError: null,
        saveInterestsInProgress: true,
      };
    case SAVE_INTERESTS_SUCCESS:
      return {
        ...state,
        saveInterestsInProgress: false,
        saveInterestsSent: true,
      };
    case SAVE_INTERESTS_ERROR:
      console.error(payload);
      return {
        ...state,
        saveInterestsError: payload,
        saveInterestsInProgress: false,
      };

    case SAVE_EMAIL_REQUEST:
      return { ...state, saveEmailInProgress: true, saveEmailError: null, saveEmailSent: false };
    case SAVE_EMAIL_SUCCESS:
      return { ...state, saveEmailInProgress: false, saveEmailSent: true };
    case SAVE_EMAIL_ERROR:
      console.error(payload);
      return {
        ...state,
        saveEmailInProgress: false,
        saveEmailError: payload,
      };

    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifyEmailInProgress: true,
        verifyEmailError: null,
        verifyEmailSent: false,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmailInProgress: false,
        verifyEmailSent: true,
      };
    case VERIFY_EMAIL_ERROR:
      console.error(payload);
      return {
        ...state,
        verifyEmailInProgress: false,
        verifyEmailError: payload,
      };

    case SAVE_PHONE_REQUEST:
      return { ...state, savePhoneInProgress: true, savePhoneError: null, savePhoneSent: false };
    case SAVE_PHONE_SUCCESS:
      return { ...state, savePhoneInProgress: false, savePhoneSent: true };
    case SAVE_PHONE_ERROR:
      console.error(payload);
      return { ...state, savePhoneInProgress: false, savePhoneError: payload };

    case VERIFY_PHONE_REQUEST:
      return {
        ...state,
        verifyPhoneInProgress: true,
        verifyPhoneError: null,
        verifyPhoneSent: false,
      };
    case VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        verifyPhoneInProgress: false,
        verifyPhoneSent: true,
      };
    case VERIFY_PHONE_ERROR:
      console.error(payload);
      return {
        ...state,
        verifyPhoneInProgress: false,
        verifyPhoneError: payload,
      };

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordInProgress: true,
        changePasswordError: null,
        passwordChanged: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordInProgress: false, passwordChanged: true };
    case CHANGE_PASSWORD_ERROR:
      console.error(payload);
      return { ...state, changePasswordInProgress: false, changePasswordError: payload };

    case CHECK_FOR_ACTIVE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        activeTransactionsInProgress: true,
        activeTransactionsCount: null,
        activeTransactionsError: null,
      };
    case CHECK_FOR_ACTIVE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        activeTransactionsInProgress: false,
        activeTransactionsCount: payload,
      };
    case CHECK_FOR_ACTIVE_TRANSACTIONS_ERROR:
      return {
        ...state,
        activeTransactionsInProgress: false,
        activeTransactionsError: payload,
      };

    case ADD_PROMO_CODE_REQUEST:
      return {
        ...state,
        addPromoCodeInProgress: true,
        addPromoCodeError: null,
        promoCodeAdded: false,
      };
    case ADD_PROMO_CODE_SUCCESS:
      return {
        ...state,
        addPromoCodeInProgress: false,
        promoCodeAdded: true,
      };
    case ADD_PROMO_CODE_ERROR:
      console.error(payload);
      return {
        ...state,
        addPromoCodeInProgress: false,
        addPromoCodeError: payload,
      };

    case FETCH_USED_PROMO_CODES_REQUEST:
      return {
        ...state,
        fetchUsedPromoCodesInProgress: true,
        usedPromoCodes: [],
        fetchUsedPromoCodesError: null,
      };
    case FETCH_USED_PROMO_CODES_SUCCESS:
      return {
        ...state,
        fetchUsedPromoCodesInProgress: false,
        usedPromoCodes: payload,
      };
    case FETCH_USED_PROMO_CODES_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchUsedPromoCodesInProgress: false,
        fetchUsedPromoCodesError: payload,
      };

    case DELETE_USER_REQUEST:
      return { ...state, deleteInProgress: true, deleteError: null };
    case DELETE_USER_SUCCESS:
      return { ...state, deleteInProgress: false };
    case DELETE_USER_ERROR:
      console.error(payload);
      return { ...state, deleteInProgress: false, deleteError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

export const saveNameAndPronounsRequest = () => ({
  type: SAVE_NAME_AND_PRONOUNS_REQUEST,
});
export const saveNameAndPronounsSuccess = () => ({
  type: SAVE_NAME_AND_PRONOUNS_SUCCESS,
});
export const saveNameAndPronounsErorr = e => ({
  type: SAVE_NAME_AND_PRONOUNS_ERROR,
  payload: e,
});

export const saveBioRequest = () => ({
  type: SAVE_BIO_REQUEST,
});
export const saveBioSuccess = () => ({
  type: SAVE_BIO_SUCCESS,
});
export const saveBioError = e => ({
  type: SAVE_BIO_ERROR,
  payload: e,
});

export const saveInterestsRequest = () => ({
  type: SAVE_INTERESTS_REQUEST,
});
export const saveInterestsSuccess = () => ({
  type: SAVE_INTERESTS_SUCCESS,
});
export const saveInterestsError = e => ({
  type: SAVE_INTERESTS_ERROR,
  payload: e,
});

export const saveEmailRequest = () => ({
  type: SAVE_EMAIL_REQUEST,
});
export const saveEmailSuccess = () => ({
  type: SAVE_EMAIL_SUCCESS,
});
export const saveEmailError = e => ({
  type: SAVE_EMAIL_ERROR,
  payload: e,
});

export const verifyEmailRequest = () => ({
  type: VERIFY_EMAIL_REQUEST,
});
export const verifyEmailSuccess = () => ({
  type: VERIFY_EMAIL_SUCCESS,
});
export const verifyEmailError = e => ({
  type: VERIFY_EMAIL_ERROR,
  payload: e,
});

export const savePhoneNumberRequest = () => ({
  type: SAVE_PHONE_REQUEST,
});
export const savePhoneNumberSuccess = () => ({
  type: SAVE_PHONE_SUCCESS,
});
export const savePhoneNumberError = e => ({
  type: SAVE_PHONE_ERROR,
  payload: e,
});

export const verifyPhoneNumberRequest = () => ({
  type: VERIFY_PHONE_REQUEST,
});
export const verifyPhoneNumberSuccess = () => ({
  type: VERIFY_PHONE_SUCCESS,
});
export const verifyPhoneNumberError = e => ({
  type: VERIFY_PHONE_ERROR,
  payload: e,
});

export const changePasswordRequest = () => ({ type: CHANGE_PASSWORD_REQUEST });
export const changePasswordSuccess = () => ({ type: CHANGE_PASSWORD_SUCCESS });
export const changePasswordError = e => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: e,
});

export const fetchActiveTransactionsCountRequest = () => ({
  type: CHECK_FOR_ACTIVE_TRANSACTIONS_REQUEST,
});
export const fetchActiveTransactionsCountSuccess = count => ({
  type: CHECK_FOR_ACTIVE_TRANSACTIONS_SUCCESS,
  payload: count,
});
export const fetchActiveTransactionsCountError = e => ({
  type: CHECK_FOR_ACTIVE_TRANSACTIONS_ERROR,
  payload: e,
});

export const addPromoCodeRequest = () => ({ type: ADD_PROMO_CODE_REQUEST });
export const addPromoCodeSuccess = () => ({ type: ADD_PROMO_CODE_SUCCESS });
export const addPromoCodeError = e => ({ type: ADD_PROMO_CODE_ERROR, payload: e });

export const fetchUsedPromoCodesRequest = () => ({ type: FETCH_USED_PROMO_CODES_REQUEST });
export const fetchUsedPromoCodesSuccess = usedPromoCodes => ({
  type: FETCH_USED_PROMO_CODES_SUCCESS,
  payload: usedPromoCodes,
});
export const fetchUsedPromoCodesError = e => ({ type: FETCH_USED_PROMO_CODES_ERROR, payload: e });

export const deleteUserRequest = () => ({ type: DELETE_USER_REQUEST });
export const deleteUserSuccess = () => ({ type: DELETE_USER_SUCCESS });
export const deleteUserError = e => ({ type: DELETE_USER_ERROR, payload: e });

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file ? actionPayload.file : actionPayload,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        return sdk.currentUser
          .updateProfile({ profileImageId: uploadedImage.id.uuid }, updateProfileQueryParams)
          .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }
            const currentUser = entities[0];

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(uploadImageSuccess({ data: { uploadedImage } }));
            dispatch(currentUserShowSuccess(currentUser));
          })
          .catch(e => dispatch(uploadImageError({ error: storableError(e) })));
      })
      .catch(e => {
        dispatch(uploadImageError({ error: storableError(e) }));
      });
  };
}

export const saveNameAndPronouns = data => (dispatch, getState, sdk) => {
  dispatch(saveNameAndPronounsRequest());
  const { firstName, lastName, pronouns, businessName } = data;
  const bodyParams = businessName
    ? { publicData: { businessName } }
    : {
        firstName,
        lastName,
        publicData: {
          pronouns,
        },
      };

  return sdk.currentUser
    .updateProfile(bodyParams, updateProfileQueryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveNameAndPronounsSuccess());
    })
    .catch(e => {
      dispatch(saveNameAndPronounsErorr(e));
      throw e;
    });
};

export const saveBio = data => (dispatch, getState, sdk) => {
  dispatch(saveBioRequest());
  const { bio } = data;

  return sdk.currentUser
    .updateProfile(
      {
        bio,
      },
      updateProfileQueryParams
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveBioSuccess());
    })
    .catch(e => {
      dispatch(saveBioError(e));
      throw e;
    });
};

export const saveInterests = data => (dispatch, getState, sdk) => {
  dispatch(saveInterestsRequest());
  const { interests } = data;

  return sdk.currentUser
    .updateProfile({ privateData: { interests } }, updateProfileQueryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.updateProfile.changeEmail response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveInterestsSuccess());
    })
    .catch(e => {
      dispatch(saveInterestsError(e));
      throw e;
    });
};

export const saveEmail = data => (dispatch, getState, sdk) => {
  dispatch(saveEmailRequest());
  const { email, currentPassword } = data;

  return sdk.currentUser
    .changeEmail({ email, currentPassword }, updateProfileQueryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveEmailSuccess());
    })
    .catch(e => {
      dispatch(saveEmailError(e));
      throw e;
    });
};

export const verifyEmail = code => (dispatch, getState, sdk) => {
  dispatch(verifyEmailRequest());

  return sdk.currentUser
    .verifyEmail(
      {
        verificationToken: code,
      },
      updateProfileQueryParams
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(verifyEmailSuccess());
    })
    .catch(e => {
      dispatch(verifyEmailError(e));
      throw e;
    });
};

export const savePhoneNumber = data => (dispatch, getState, sdk) => {
  dispatch(savePhoneNumberRequest());
  const { phoneNumber } = data;

  return api.users
    .addPhoneNumber(phoneNumber)
    .then(response => {
      const currentUser = getState().user.currentUser;
      currentUser.attributes.profile.metadata = {
        ...currentUser.attributes.profile.metadata,
        isPhoneNumberVerified: false,
      };
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(savePhoneNumberSuccess());
    })
    .catch(e => {
      dispatch(savePhoneNumberError(e));
      throw e;
    });
};

export const verifyPhoneNumber = code => (dispatch, getState, sdk) => {
  dispatch(verifyPhoneNumberRequest());

  return api.users
    .verifyPhoneNumber(code)
    .then(() => {
      const currentUser = getState().user.currentUser;
      currentUser.attributes.profile.metadata = {
        ...currentUser.attributes.profile.metadata,
        isPhoneNumberVerified: true,
      };
      dispatch(currentUserShowSuccess(currentUser));

      dispatch(verifyPhoneNumberSuccess());
    })
    .catch(e => {
      dispatch(verifyPhoneNumberError(e));
      throw e;
    });
};

export const changePassword = params => (dispatch, getState, sdk) => {
  dispatch(changePasswordRequest());
  const { newPassword, currentPassword } = params;

  return sdk.currentUser
    .changePassword({ newPassword, currentPassword }, updateProfileQueryParams)
    .then(() => dispatch(changePasswordSuccess()))
    .catch(e => {
      dispatch(changePasswordError(storableError(e)));
      throw e;
    });
};

export const checkForActiveTransactions = () => (dispatch, getState, sdk) => {
  dispatch(fetchActiveTransactionsCountRequest());

  return sdk.transactions
    .query({
      lastTransitions: [TRANSITION_ACCEPT],
    })
    .then(res => {
      dispatch(fetchActiveTransactionsCountSuccess(res?.data?.meta?.totalItems));
    })
    .catch(e => {
      dispatch(fetchActiveTransactionsCountError(e));
      throw e;
    });
};

export const addPromoCode = code => (dispatch, getState, sdk) => {
  dispatch(addPromoCodeRequest());

  return api.users
    .validatePromoCode(code)
    .then(res => {
      if (res.data.isValidPromoCode) {
        return sdk.currentUser
          .updateProfile(
            {
              privateData: {
                promoCode: code,
              },
            },
            updateProfileQueryParams
          )
          .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }
            const currentUser = entities[0];

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(addPromoCodeSuccess());
            dispatch(currentUserShowSuccess(currentUser));
          })
          .catch(e => {
            dispatch(addPromoCodeError(e));
            throw e;
          });
      } else {
        dispatch(addPromoCodeError({ message: 'Invalid promo code' }));
      }
    })
    .catch(e => {
      dispatch(addPromoCodeError(e));
      throw e;
    });
};

export const fetchUsedPromoCodes = () => (dispatch, getState, sdk) => {
  dispatch(fetchUsedPromoCodesRequest());

  return api.users
    .getUsedPromoCodes()
    .then(res => dispatch(fetchUsedPromoCodesSuccess(res.data.usedPromoCodes)))
    .catch(e => {
      dispatch(fetchUsedPromoCodesError(e));
      throw e;
    });
};

export const deleteUser = currentUser => (dispatch, getState, sdk) => {
  dispatch(deleteUserRequest());

  return api.users
    .deleteUser()
    .then(response => {
      currentUser.attributes.profile.metadata = {
        ...currentUser.attributes.profile.metadata,
        hasAskedDeletion: true,
      };
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(deleteUserSuccess());
    })
    .catch(e => {
      dispatch(deleteUserError(e));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(checkForActiveTransactions());
  dispatch(fetchUsedPromoCodes());
  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser && currentUser.stripeAccount) {
      dispatch(fetchStripeAccount());
    }
    return response;
  });
};
