import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBusiness.module.css';

const IconBusiness = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      viewBox="0.166 0.127 18.375 16.962"
      /*viewBox="0 0 512 512"*/ xmlns="http://www.w3.org/2000/svg"
    >
      {/* <path
        d="M261 149.3V64H48v384h416V149.3H261zm-127.8 256H90.6v-42.7h42.6v42.7zm0-85.3H90.6v-42.7h42.6V320zm0-85.3H90.6V192h42.6v42.7zm0-85.4H90.6v-42.7h42.6v42.7zm85.2 256h-42.6v-42.7h42.6v42.7zm0-85.3h-42.6v-42.7h42.6V320zm0-85.3h-42.6V192h42.6v42.7zm0-85.4h-42.6v-42.7h42.6v42.7zm203 256H261v-42.7h42.6V320H261v-42.7h42.6v-42.7H261V192h160.4v213.3zm-37.6-170.6h-42.6v42.7h42.6v-42.7zm0 85.3h-42.6v42.7h42.6V320z"
        fill="#626262"
      /> */}
      <path d="M9.574 3.895V.127H.166v16.961H18.54V3.895H9.574zM3.93 15.202H2.047v-1.886H3.93v1.886zm0-3.767H2.047V9.548H3.93v1.887zm0-3.768H2.047V5.78H3.93v1.886zm0-3.772H2.047V2.009H3.93v1.886zm3.764 11.307H5.81v-1.886h1.882v1.886zm0-3.767H5.81V9.548h1.882v1.887zm0-3.768H5.81V5.78h1.882v1.886zm0-3.772H5.81V2.009h1.882v1.886zm8.966 11.307H9.574v-1.886h1.882v-1.881H9.574V9.548h1.882V7.662H9.574V5.781h7.085v9.421zM15 7.667h-1.882v1.886h1.881V7.667zm0 3.768h-1.882v1.886h1.881v-1.886z" />
    </svg>
  );
};

const { string } = PropTypes;

IconBusiness.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBusiness.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBusiness;
