import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Stepper.module.css';
import { InlineTextButton } from '../Button/Button';
import { withViewport } from '../../util/contextHelpers';

const Stepper = props => {
  const { rootClassName, className, steps, activeStep, lastCompletedStep } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  const numOfColumns = useMemo(() => {
    const col = steps.length;

    return { gridTemplateColumns: `repeat(${col}, minmax(24px, 1fr))` };
  }, [steps.length]);

  return (
    <div className={classes}>
      <div className={css.steps} style={numOfColumns}>
        {steps.map((step, i) => (
          <React.Fragment key={`circle-${step}`}>
            <div className={css.stepButtonWrapper}>
              {i > 0 && <div className={css.line} />}
              <InlineTextButton
                rootClassName={classNames(css.stepButton, {
                  [css.stepCompleted]: i <= lastCompletedStep && activeStep !== i,
                  [css.stepActive]: activeStep === i,
                })}
                type="button"
              />
              {i < steps.length - 1 && <div className={css.line} />}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

Stepper.defaultProps = {
  rootClassName: null,
  className: null,
  steps: [],
  activeStep: 0,
  lastCompletedStep: -1,
};

const { number, string, array, shape } = PropTypes;

Stepper.propTypes = {
  rootClassName: string,
  className: string,
  steps: array.isRequired,
  lastCompletedStep: number.isRequired,
  activeStep: number.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(Stepper);
