import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { arrayOf, string } from 'prop-types';

import css from './FAQ.module.css';

const FAQComponent = props => {
  const { className, rootClassName, options } = props;
  const [index, setIndex] = useState(-1);

  const classes = classNames(rootClassName || css.root, className);

  const showAnswer = useCallback(
    selectedIndex => setIndex(oldIndex => (oldIndex === selectedIndex ? -1 : selectedIndex)),
    []
  );

  return (
    <div className={classes}>
      <div className={css.container}>
        <div className={css.questions}>
          {options.map((o, i) => (
            <div className={css.questionContainer} key={`learn-more-page-${i}`}>
              <div className={css.question} onClick={() => showAnswer(i)}>
                <span className={css.quenstionText}>{o.question}</span>
                {index === i ? (
                  <span className={css.icon}>-</span>
                ) : (
                  <span className={css.icon}>+</span>
                )}
              </div>
              {index === i && <div className={css.answer}>{o.answer}</div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

FAQComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

FAQComponent.propTypes = {
  className: string,
  rootClassName: string,
  options: arrayOf({
    quenstion: string.isRequired,
    answer: string.isRequired,
  }).isRequired,
};

export default FAQComponent;
