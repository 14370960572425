import React, { useCallback, useMemo } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import tz_lookup from 'tz-lookup';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingAvailabilityPlanForm } from '../../forms';
import { AVAILABILITY_PERIODS } from '../../constants';

import css from './EditListingAvailabilityPanel.module.css';

const WEEKDAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
// const MAX_EXCEPTIONS_COUNT = 100;

const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

const DEFAULT_ENTIRES = {};
const DEFAULT_AVAILABILITY_PERIOD = {};

for (let index = 0; index < WEEKDAYS.length; index++) {
  const dayOfWeek = WEEKDAYS[index];

  DEFAULT_ENTIRES[dayOfWeek] = [
    {
      startTime: '00:00',
      endTime: '24:00',
    },
  ];

  DEFAULT_AVAILABILITY_PERIOD[dayOfWeek] = AVAILABILITY_PERIODS.ALL_DAY;
}

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
// const sortExceptionsByStartTime = (a, b) => {
//   return a.attributes.start.getTime() - b.attributes.start.getTime();
// };

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    // availabilityExceptions,
    // fetchExceptionsInProgress,
    // onAddAvailabilityException,
    // onDeleteAvailabilityException,
    // disabled,
    // ready,
    panelUpdated,
    onSubmit,
    // onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
  } = props;
  // Hooks
  // const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);
  const currentListing = useMemo(() => ensureOwnListing(listing), [listing]);
  const { geolocation, state, publicData } = useMemo(() => currentListing.attributes || {}, [
    currentListing.attributes,
  ]);

  const { pickUpDropOffPlan, seats = 1, availabilityPeriod: listingAvailabilityPeriod } = useMemo(
    () => publicData || {},
    [publicData]
  );

  const isPublished = useMemo(() => currentListing.id && state !== LISTING_STATE_DRAFT, [
    currentListing.id,
    state,
  ]);
  const listingTimezone = useMemo(
    () => (geolocation && tz_lookup(geolocation.lat, geolocation.lng)) || DEFAULT_TIME_ZONE,
    [geolocation]
  );

  const availabilityPlan = useMemo(() => pickUpDropOffPlan || DEFAULT_ENTIRES, [pickUpDropOffPlan]);

  const availabilityPeriod = useMemo(
    () => listingAvailabilityPeriod || DEFAULT_AVAILABILITY_PERIOD,
    [listingAvailabilityPeriod]
  );

  const initialValues = useMemo(() => ({ ...availabilityPlan, seats, availabilityPeriod }), [
    availabilityPeriod,
    availabilityPlan,
    seats,
  ]);

  // const exceptionCount = useMemo(
  //   () => (availabilityExceptions ? availabilityExceptions.length : 0),
  //   [availabilityExceptions]
  // );
  // const sortedAvailabilityExceptions = useMemo(
  //   () => availabilityExceptions.sort(sortExceptionsByStartTime),
  //   [availabilityExceptions]
  // );

  // const exceptionSection = useMemo(
  //   () => (
  //     <section className={css.section}>
  //       <header className={css.sectionHeader}>
  //         <h2 className={css.sectionTitle}>
  //           {fetchExceptionsInProgress ? (
  //             <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />
  //           ) : (
  //             <FormattedMessage
  //               id="EditListingAvailabilityPanel.availabilityExceptionsTitle"
  //               values={{ count: exceptionCount }}
  //             />
  //           )}
  //         </h2>
  //       </header>
  //       {fetchExceptionsInProgress ? (
  //         <div className={css.exceptionsLoading}>
  //           <IconSpinner />
  //         </div>
  //       ) : exceptionCount === 0 ? (
  //         <div className={css.noExceptions}>
  //           <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />
  //         </div>
  //       ) : (
  //         <div className={css.exceptions}>
  //           {sortedAvailabilityExceptions.map(availabilityException => {
  //             const { start, end, seats } = availabilityException.attributes;
  //             return (
  //               <div key={availabilityException.id.uuid} className={css.exception}>
  //                 <div className={css.exceptionHeader}>
  //                   <div className={css.exceptionAvailability}>
  //                     <div
  //                       className={classNames(css.exceptionAvailabilityDot, {
  //                         [css.isAvailable]: seats > 0,
  //                       })}
  //                     />
  //                     <div className={css.exceptionAvailabilityStatus}>
  //                       {seats > 0 ? (
  //                         <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />
  //                       ) : (
  //                         <FormattedMessage id="EditListingAvailabilityPanel.exceptionNotAvailable" />
  //                       )}
  //                     </div>
  //                   </div>
  //                   <button
  //                     type="button"
  //                     className={css.removeExceptionButton}
  //                     onClick={() =>
  //                       onDeleteAvailabilityException({ id: availabilityException.id })
  //                     }
  //                   >
  //                     <IconClose size="normal" className={css.removeIcon} />
  //                   </button>
  //                 </div>
  //                 <TimeRange
  //                   className={css.timeRange}
  //                   startDate={start}
  //                   endDate={end}
  //                   dateType={DATE_TYPE_DATETIME}
  //                   timeZone={availabilityPlan.timezone}
  //                 />
  //                 <div className={css.timeRange}>
  //                   {seats > 0 ? (
  //                     <div>
  //                       <FormattedMessage
  //                         id={`EditListingAvailabilityPanel.seats`}
  //                         values={{ numberOfSeats: seats }}
  //                       />
  //                     </div>
  //                   ) : null}
  //                 </div>
  //               </div>
  //             );
  //           })}
  //         </div>
  //       )}
  //       {exceptionCount <= MAX_EXCEPTIONS_COUNT ? (
  //         <InlineTextButton
  //           type="button"
  //           className={css.addExceptionButton}
  //           onClick={() => setIsEditExceptionsModalOpen(true)}
  //           disabled={disabled}
  //           ready={ready}
  //         >
  //           <FormattedMessage id="EditListingAvailabilityPanel.addException" />
  //         </InlineTextButton>
  //       ) : null}
  //     </section>
  //   ),
  //   [
  //     availabilityPlan.timezone,
  //     disabled,
  //     exceptionCount,
  //     fetchExceptionsInProgress,
  //     onDeleteAvailabilityException,
  //     ready,
  //     sortedAvailabilityExceptions,
  //   ]
  // );

  // // Save exception click handler
  // const saveException = values => {
  //   const { availability, exceptionStartTime, exceptionEndTime, exceptionSeats } = values;

  //   // TODO: add proper seat handling
  //   const seats = availability === 'available' ? exceptionSeats : 0;

  //   return onAddAvailabilityException({
  //     listingId: listing.id,
  //     seats,
  //     start: timestampToDate(exceptionStartTime),
  //     end: timestampToDate(exceptionEndTime),
  //   })
  //     .then(() => {
  //       setIsEditExceptionsModalOpen(false);
  //     })
  //     .catch(e => {
  //       // Don't close modal if there was an error
  //     });
  // };

  const handleSubmit = useCallback(
    values => {
      const { seats: formSeats, availabilityPeriod, ...restOfValues } = values;

      const seats = +formSeats;

      const availabilityPlan = {
        type: 'availability-plan/time',
        timezone: listingTimezone,
        entries: [
          { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats },
          { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats },
          { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats },
          { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats },
          { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats },
          { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats },
          { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats },
        ],
      };

      const submitValues = {
        availabilityPlan,
        publicData: {
          pickUpDropOffPlan: restOfValues,
          seats: formSeats,
          availabilityPeriod,
        },
      };

      onSubmit(submitValues).then(() => !isPublished && onNextTab());
    },
    [isPublished, listingTimezone, onNextTab, onSubmit]
  );

  return (
    <main className={classes}>
      <div className={css.titleContainer}>
        <h1 className={css.title}>
          {isPublished ? (
            <FormattedMessage
              id="EditListingAvailabilityPanel.title"
              values={{
                listingTitle: (
                  <ListingLink listing={listing}>{listing.attributes.title}</ListingLink>
                ),
              }}
            />
          ) : (
            <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
          )}
        </h1>
        <p className={css.description}>
          <FormattedMessage id="EditListingAvailabilityPanel.description" />
        </p>
      </div>

      <EditListingAvailabilityPlanForm
        formId="EditListingAvailabilityPlanForm"
        availabilityPlan={availabilityPlan}
        weekdays={WEEKDAYS}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        inProgress={updateInProgress}
        panelUpdated={panelUpdated}
        fetchErrors={errors}
        // exceptionSection={exceptionSection}
        saveActionMsg={submitButtonText}
      />

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}

      {/* {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityExceptions"
          isOpen={isEditExceptionsModalOpen}
          onClose={() => setIsEditExceptionsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityExceptionForm
            formId="EditListingAvailabilityExceptionForm"
            onSubmit={saveException}
            timeZone={availabilityPlan.timezone}
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null} */}
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
