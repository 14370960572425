import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import { BookingPeriod } from '../BookingBreakdown/LineItemBookingPeriod';
import { ChangeBookingDatesForm } from '../../forms';

import css from './ChangeBookingDatesModal.module.css';

const ChangeBookingDatesModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitExtendBooking,
    transaction,
    extendBookingSent,
    extendBookingError,
    extendBookingInProgress,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });

  const { start, end } = transaction ? transaction.booking : {};

  return !transaction ? null : (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ChangeBookingDatesModal.title" />
      </p>
      <BookingPeriod intl={intl} startDate={start.toString()} endDate={end.toString()} />
      <ChangeBookingDatesForm
        onSubmit={onSubmitExtendBooking}
        extendBookingSent={extendBookingSent}
        extendBookingError={extendBookingError}
        extendBookingInProgress={extendBookingInProgress}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

ChangeBookingDatesModal.defaultProps = {
  className: null,
  rootClassName: null,
  extendBookingSent: false,
  extendBookingInProgress: false,
  extendBookingError: null,
};

ChangeBookingDatesModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  extendBookingInProgress: bool,
};

export default injectIntl(ChangeBookingDatesModal);
