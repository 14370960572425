import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { ReviewRating, UserDisplayName } from '..';
import { propTypes } from '../../util/types';
import { AvatarMedium } from '../Avatar/Avatar';
import { InlineTextButton } from '../Button/Button';
import Modal from '../Modal/Modal';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import IconSpinner from '../IconSpinner/IconSpinner';
// import VerifiedBadge, { BADGE_SIZE_SMALL } from '../VerifiedBadge/VerifiedBadge';

import css from './SectionReviews.module.css';

const CONTENT_LENGHT = 100;

const Review = props => {
  const { review, intl } = props;
  const reviewContent = review.attributes.content;

  const [showMore, setShowMore] = useState(reviewContent.length > CONTENT_LENGHT);

  // const isVerified = useMemo(
  //   () => review.author.attributes.profile.publicData?.facebook?.connected || false,
  //   [review.author.attributes.profile.publicData?.facebook?.connected]
  // );
  const showButtons = useMemo(() => reviewContent.length > CONTENT_LENGHT, [reviewContent.length]);

  return (
    <div className={css.review}>
      <AvatarMedium className={css.avatar} user={review.author} disableProfileLink />
      <div className={css.content}>
        <div className={css.reviewInfo}>
          <div className={css.nameIconWrapper}>
            <UserDisplayName rootClassName={css.userName} user={review.author} intl={intl} />
            {/* <VerifiedBadge isVerified={isVerified} size={BADGE_SIZE_SMALL} /> */}
          </div>
          <ReviewRating
            rating={review.attributes.rating}
            className={css.desktopReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          />
        </div>
        <p className={css.reviewContent}>
          {showButtons ? (
            showMore ? (
              <div>
                {reviewContent.substr(0, CONTENT_LENGHT)}...
                <p className={css.showMoreLess} onClick={() => setShowMore(!showMore)}>
                  <FormattedMessage id={'Reviews.showMore'} />
                </p>
              </div>
            ) : (
              <div>
                {reviewContent}
                <p className={css.showMoreLess} onClick={() => setShowMore(!showMore)}>
                  <FormattedMessage id={'Reviews.showLess'} />
                </p>
              </div>
            )
          ) : (
            reviewContent
          )}
        </p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const Reviews = props => {
  const { className, rootClassName, reviews, intl } = props;
  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return (
    <ul className={classes}>
      {reviews.map(r => (
        <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
          <Review review={r} intl={intl} />
        </li>
      ))}
    </ul>
  );
};

Reviews.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

Reviews.propTypes = {
  reviews: arrayOf(propTypes.review),
  intl: intlShape.isRequired,
};

const SectionReviewsComponent = props => {
  const {
    className,
    rootClassName,
    reviews,
    pagination,
    inProgress,
    showLineBreak,
    error,
    isListing,
    onFetchMore,
    intl,
  } = props;
  const { totalPages, page: currentPage = 1, perPage } = pagination || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const messages = useMemo(
    () => ({
      heading: intl.formatMessage({ id: 'Reviews.heading' }),
      showMore: intl.formatMessage({ id: 'Reviews.showMore' }),
      loadMore: intl.formatMessage({ id: 'Reviews.loadMore' }),
      error: intl.formatMessage({ id: 'Reviews.error' }),
      noReviews: intl.formatMessage(
        { id: 'Reviews.noReviews' },
        { type: intl.formatMessage({ id: isListing ? 'Reviews.typeListing' : 'Reviews.typeUser' }) }
      ),
    }),
    [intl, isListing]
  );

  const initialFetch = useMemo(() => inProgress && reviews.length === 0 && <IconSpinner />, [
    inProgress,
    reviews.length,
  ]);
  const reviewsError = useMemo(() => error && <h5 className={css.errorText}>{messages.error}</h5>, [
    error,
    messages.error,
  ]);
  const noReviews = useMemo(() => !error && reviews.length === 0 && messages.noReviews, [
    error,
    reviews,
    messages.noReviews,
  ]);

  const firstPageReviews = useMemo(() => reviews.slice(0, perPage), [perPage, reviews]);
  const reviewsProps = { className, rootClassName, reviews: firstPageReviews, intl };

  return (
    <>
      <div className={css.sectionWrapper}>
        <h2 className={css.heading}>{messages.heading}</h2>

        {initialFetch}
        {reviewsError}
        {noReviews}

        <Reviews {...reviewsProps} />
        {totalPages > 1 && (
          <InlineTextButton
            className={css.moreReviewsButton}
            disabled={inProgress}
            onClick={() => onFetchMore(currentPage + 1).then(() => setIsModalOpen(true))}
          >
            {messages.showMore}
          </InlineTextButton>
        )}

        {showLineBreak && <hr className={css.linebreak} />}
      </div>
      {isModalOpen && (
        <Modal
          contentClassName={css.modalContent}
          id="Reviews.allReviews"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <Reviews {...reviewsProps} reviews={reviews} />
          {totalPages > currentPage && (
            <InlineTextButton
              className={css.moreReviewsButton}
              spinnerClassName={css.spinner}
              inProgress={inProgress}
              onClick={() => onFetchMore(currentPage + 1)}
            >
              {messages.loadMore}
            </InlineTextButton>
          )}
        </Modal>
      )}
    </>
  );
};

SectionReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
  inProgress: false,
  showLineBreak: false,
  isListing: false,
};

SectionReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),
  pagination: propTypes.pagination,
  inProgress: bool,
  isListing: bool,
  showLineBreak: bool,
  onFetchMore: func,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SectionReviews = injectIntl(SectionReviewsComponent);

export default SectionReviews;
