export const LISTING_TYPE = {
  WANTED_POST: 'WANTED_POST',
  LENDER_POST: 'LENDER_POST',
};

export const BORROWING_TYPE = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
};

export const GENDER_PRONOUNS = {
  female: 'She/Her',
  male: 'He/Him',
  plural: 'They/Them',
  trans: 'Ze/Hir/Hirs',
  none: 'Prefer Not To Say',
  other: 'Other',
};

export const DELIVERY_METHODS = {
  PICK_UP: 'pickUp',
  ON_SITE_RENTAL: 'onSiteRental',
};

export const SUB_CATEGORY = {
  sportingEquipmentAndFitness: [
    { key: 'athleticApparelAndAccessories', label: 'Athletic Apparel & Accessories' },
    { key: 'leisureSportsAndGameRoom', label: 'Leisure Sports & Game Room' },
    { key: 'balls', label: 'Balls' },
    { key: 'flyingDiscs', label: 'Flying Discs' },
    { key: 'goalPosts', label: 'Goal Posts' },
    { key: 'nets', label: 'Nets' },
    { key: 'racquets', label: 'Racquets' },
    { key: 'rodsAndTackle', label: 'Rods and Tackle' },
    { key: 'sticksBatsAndClubs', label: 'Sticks, Bats and Clubs' },
    { key: 'wicketsAndBases', label: 'Wickets and Bases' },
    { key: 'protectiveEquipment', label: 'Protective Equipment' },
    { key: 'trainingEquipment', label: 'Training Equipment' },
    { key: 'bikeAndBikeEquipment', label: 'Bike & Bike Equipment' },
    { key: 'scootersSkateboardsAndSkates', label: 'Scooters, Skateboards & Skates' },
    { key: 'waterSports', label: 'Water Sports' },
    { key: 'winterSports', label: 'Winter Sports' },
    { key: 'campingAndHiking', label: 'Camping and Hiking' },
    { key: 'horsebackRiding', label: 'Horseback Riding' },
    { key: 'other', label: 'Other' },
  ],
  homeGardenTools: [
    { key: 'furniture', label: 'Furniture' },
    { key: 'kitchenAndDining', label: 'Kitchen & Dining' },
    { key: 'bedAndBath', label: 'Bed & Bath' },
    { key: 'gardenAndOutdoor', label: 'Garden & Outdoor' },
    { key: 'lighting', label: 'Lighting' },
    { key: 'storageAndOrganization', label: 'Storage & Organization' },
    { key: 'appliances', label: 'Appliances' },
    { key: 'artCraftsAndSewing', label: 'Art, Crafts & Sewing' },
    { key: 'eventAndPartySupplies', label: 'Event & Party Supplies' },
    { key: 'homeImprovement', label: 'Home Improvement' },
    { key: 'powerAndHandTools', label: 'Power & Hand Tools' },
    { key: 'lampAndLightFixtures', label: 'Lamp & Light Fixtures' },
    { key: 'kitchenAndBathFixtures', label: 'Kitchen & Bath Fixtures' },
    { key: 'cookware', label: 'Cookware' },
    { key: 'hardware', label: 'Hardware' },
    { key: 'smartHome', label: 'Smart Home' },
    { key: 'other', label: 'Other' },
  ],
  officeAndElectronics: [
    { key: 'tvAndVideo', label: 'TV & Video' },
    { key: 'homeAudioAndTheater', label: 'Home Audio & Theater' },
    { key: 'cameraPhotoAndVideo', label: 'Camera, Photo & Video' },
    { key: 'cellPhonesAccessories', label: 'Cell Phones & Accessories' },
    { key: 'headphones', label: 'Headphones' },
    { key: 'videoGames', label: 'Video Games' },
    { key: 'bluetoothWirelessSpeakers', label: 'Bluetooth & Wireless Speakers' },
    { key: 'carElectronics', label: 'Car Electronics' },
    { key: 'musicalInstruments', label: 'Musical Instruments' },
    { key: 'wearableTechnology', label: 'Wearable Technology' },
    { key: 'computersTabletsAndPcProducts', label: 'Computers, Tablets & PC Products' },
    { key: 'monitors', label: 'Monitors' },
    { key: 'accessories', label: 'Accessories' },
    { key: 'networking', label: 'Networking' },
    { key: 'drivesAndStorage', label: 'Drives & Storage' },
    { key: 'computerPartsAndComponents', label: 'Computer Parts & Components' },
    { key: 'printersAndInk', label: 'Printers & Ink' },
    { key: 'officeAndSchoolSupplies', label: 'Office & School Supplies' },
    { key: 'other', label: 'Other' },
  ],
};

export const PROMO_CODES_DISCOUNT = {
  BORROWED5OFF: 500,
  BORROWED10OFF: 1000,
  BORROWED15OFF: 1500,
  BORROWED20OFF: 2000,
};

export const DEFAULT_TITLE = 'draft';

export const AVAILABILITY_PERIODS = {
  ALL_DAY: 'all-day',
  SPECIFIC_TIMES: 'specific-times',
  NOT_AVAILABLE: 'not-available',
};
