import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import Modal from '../Modal/Modal';

import css from './ConfirmationModal.module.css';
import { PrimaryButton } from '../Button/Button';

const ConfirmationModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onSubmit,
    modalTitle,
    modalText,
    modalButtonText,
    inProgress,
    ready,
    onManageDisableScrolling,
    error,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ConfirmationModal.close' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>{modalTitle}</p>
      {modalText && <p className={css.modalText}>{modalText}</p>}
      {error}
      <PrimaryButton
        className={css.marginTop}
        inProgress={inProgress}
        disabled={inProgress || ready}
        ready={ready}
        type="submit"
        onClick={onSubmit}
      >
        {modalButtonText}
      </PrimaryButton>
    </Modal>
  );
};

const { string, node, oneOfType, bool } = PropTypes;

ConfirmationModal.defaultProps = {
  className: null,
  rootClassName: null,
  modalText: null,
};

ConfirmationModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  modalTitle: oneOfType([string, node]),
  modalText: oneOfType([string, node]),
  modalButtonText: oneOfType([string, node]),
  inProgress: bool,
  ready: bool,
};

export default injectIntl(ConfirmationModal);
