import React, { useMemo } from 'react';
import { string, arrayOf, func, shape, object, any } from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatDateForWantedPosts } from '../../util/dates';
import { NamedLink } from '../../components';
import { txState } from '../../containers/InboxPage/InboxPage';

import css from './TransactionsTable.module.css';

const { Money } = sdkTypes;

export const formatOfDate = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
};

export const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingsTable.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingsTable.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const TransactionsTableComponent = props => {
  const { className, intl, transactions, name } = props;
  const Actions = props.actions;

  const tableHeaders = useMemo(
    () => [
      intl.formatMessage({ id: 'TransactionsTable.itemName' }),
      intl.formatMessage({ id: 'TransactionsTable.user' }),
      intl.formatMessage({ id: 'TransactionsTable.quantity' }),
      intl.formatMessage({ id: 'TransactionsTable.timeframe' }),
      intl.formatMessage({ id: 'TransactionsTable.revenue' }),
      intl.formatMessage({ id: 'TransactionsTable.status' }),
      intl.formatMessage({ id: 'TransactionsTable.actions' }),
    ],
    [intl]
  );

  const classes = useMemo(() => classNames(css.root, className), [className]);

  return (
    <table className={classes} key={name}>
      <thead className={css.header}>
        <tr>
          {tableHeaders.map(header => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody className={css.body} key={name}>
        {transactions.map(t => {
          const {
            sharetribeId,
            listing,
            booking,
            sharetribeCustomerId,
            protectedData,
            payoutTotal,
            lastTransition,
            transitions,
          } = t;

          const title = listing ? listing.title : 'DELETED LISTING';
          const displayName = protectedData.buyerUsername
            ? `${protectedData.buyerUsername}`
            : `DELETED USER`;

          const { seats, displayStart, displayEnd, sharetribeId: bookingId } = booking;

          const duration = moment(displayEnd).diff(moment(displayStart), 'hours');
          const isHourly = duration < 24;

          const priceAsMoney = payoutTotal
            ? new Money(payoutTotal.amount, payoutTotal.currency)
            : null;
          const { formattedPrice } = priceData(priceAsMoney, intl);

          const status = txState(intl, { attributes: { lastTransition, transitions } }, 'sale')
            .state;

          return (
            <tr key={sharetribeId}>
              <td>
                <div className={css.listingTitle}>{title}</div>
                <div className={css.bookingId}>{bookingId}</div>
              </td>

              <td>
                <div className={css.itemName}>
                  <NamedLink name="ProfilePage" params={{ id: sharetribeCustomerId }}>
                    {displayName}
                  </NamedLink>
                </div>
              </td>

              <td>{seats}</td>

              <td>
                <div className={css.dateAndTime}>
                  {isHourly ? (
                    <React.Fragment>
                      <div className={css.bubble}>
                        {`${formatDateForWantedPosts(intl, displayStart)?.date}`}
                      </div>
                      <div className={css.bubble}>
                        {`${formatDateForWantedPosts(intl, displayStart)?.time} - ${
                          formatDateForWantedPosts(intl, displayEnd)?.time
                        }`}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className={css.bubble}>
                      {`${formatDateForWantedPosts(intl, displayStart)?.date} - ${
                        formatDateForWantedPosts(intl, displayEnd)?.date
                      }`}
                    </div>
                  )}
                </div>
              </td>

              <td>{formattedPrice}</td>

              <td>{status}</td>

              <td>
                <Actions transaction={t} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

TransactionsTableComponent.defaultProps = {
  className: null,
  spacingClassName: null,
  transactions: [],
};

TransactionsTableComponent.propTypes = {
  className: string,
  spacingClassName: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  transactions: arrayOf(object).isRequired,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  actions: any.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(TransactionsTableComponent);
