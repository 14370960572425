import React from 'react';
import { string } from 'prop-types';
import DateRangeController from './DateRangeController';

const FieldDateRangeController = props => {
  return <DateRangeController {...props} />;
};

FieldDateRangeController.defaultProps = {
  rootClassName: null,
  className: null,
};

FieldDateRangeController.propTypes = {
  rootClassName: string,
  className: string,
};

export default FieldDateRangeController;
