import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import css from './Dropzone.module.css';

const Dropzone = props => {
  const {
    className,
    handleFiles,
    handleButton,
    dropzoneText,
    multiple,
    accept,
    name,
    disabled,
  } = props;

  const onDrop = useCallback(
    acceptedFiles => {
      handleFiles(acceptedFiles);
    },
    [handleFiles]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept,
    multiple,
    disabled,
  });

  const dropzoneClasses = classNames(css.dropzone, {
    [css.dropzoneActive]: isDragActive,
    [css.dropzoneAccept]: isDragAccept,
    [css.dropzoneReject]: isDragReject,
  });

  return (
    <div {...getRootProps()} className={classNames(dropzoneClasses, className)}>
      <input {...getInputProps()} name={name} />
      {isDragActive ? (
        <span>Drop the files here ...</span>
      ) : (
        <>
          {!handleButton ? (
            <span>{dropzoneText}</span>
          ) : (
            <>
              {handleButton}
              <span>{dropzoneText}</span>
            </>
          )}
        </>
      )}
    </div>
  );
};

Dropzone.defaultProps = {
  className: null,
  dropzoneText: 'Click to upload a file or Drag & Drop',
  multiple: true,
  name: 'file',
  accept: ['image/*', 'video/*', 'audio/*', 'application/*'],
};

const { func, bool, string, node, arrayOf, oneOfType } = PropTypes;

Dropzone.propTypes = {
  className: string,
  handleFiles: func.isRequired,
  handleButton: node,
  dropzoneText: oneOfType([string, node]),
  multiple: bool,
  accept: oneOfType([arrayOf(string), string]),
  name: string,
};

export default Dropzone;
