import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, forbidBadWords } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldToggle } from '../../components';
import CustomCategorySelectField from './CustomCategorySelectField';
import { SUB_CATEGORY } from '../../constants';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        categoryOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        values,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const badWordsForbidden = forbidBadWords(
        intl.formatMessage({ id: 'EditListingDescriptionForm.badWordsError' })
      );

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titleSubline = intl.formatMessage({ id: 'EditListingDescriptionForm.titleSubline' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const accessoriesIncludedLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.accessoriesIncluded',
      });
      const accessoriesIncludedSubline = intl.formatMessage({
        id: 'EditListingDescriptionForm.accessoriesIncludedSubline',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionSubline = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionSubline',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const categoryLabelMesage = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryLabel',
      });
      const categoryRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryRequiredMessage',
      });
      const categoryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryPlaceholder',
      });

      const subCategoryOptions = values && values.category ? SUB_CATEGORY[values.category] : [];
      const subCategoryLabelMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.subCategoryLabel',
      });
      const subCategoryRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.subCategoryRequiredMessage',
      });
      const subCategoryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.subCategoryPlaceholder',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.fieldContainer}>
            <FieldTextInput
              id="title"
              name="title"
              type="text"
              label={titleMessage}
              subtitle={titleSubline}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(
                required(titleRequiredMessage),
                maxLength60Message,
                badWordsForbidden
              )}
            />
          </div>

          <div className={css.fieldContainer}>
            <FieldToggle
              id="accessoriesIncluded"
              name="accessoriesIncluded"
              label={accessoriesIncludedLabel}
            />
            <div className={css.subline}>{accessoriesIncludedSubline}</div>
          </div>

          <div className={css.fieldContainer}>
            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              subtitle={descriptionSubline}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage), badWordsForbidden)}
            />
          </div>

          <CustomCategorySelectField
            id="category"
            name="category"
            categoryOptions={categoryOptions}
            categoryLabelMesage={categoryLabelMesage}
            categoryRequiredMessage={categoryRequiredMessage}
            categoryPlaceholderMessage={categoryPlaceholderMessage}
          />

          <CustomCategorySelectField
            id="subCategory"
            name="subCategory"
            categoryOptions={subCategoryOptions}
            categoryLabelMesage={subCategoryLabelMessage}
            categoryRequiredMessage={subCategoryRequiredMessage}
            categoryPlaceholderMessage={subCategoryPlaceholderMessage}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
