import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { Button, Form, FieldCurrencyInput, FieldCheckbox, Tooltip } from '../../components';
import { withViewport } from '../../util/contextHelpers';

import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

const haveOnlyNullProperties = obj => {
  let haveOnlyNullValues = true;
  obj &&
    Object.keys(obj).forEach(dayName => {
      Object.keys(obj[dayName]).forEach(property => {
        if (obj[dayName][property] !== null) haveOnlyNullValues = false;
      });
    });
  return haveOnlyNullValues;
};

const DailyPrice = props => {
  const { dayOfWeek, pricePlaceholder, showLabel, intl } = props;

  const hourlyLabelMaybe = showLabel
    ? { label: intl.formatMessage({ id: 'EditListingPricingForm.hourlyPrice' }) }
    : {};
  const dailyLabelMaybe = showLabel
    ? { label: intl.formatMessage({ id: 'EditListingPricingForm.dailyPrice' }) }
    : {};

  return (
    <div className={css.dailyPriceContainer}>
      <div className={css.dailyPriceHeader}>
        <FormattedMessage id={`EditListingPricingForm.dayOfWeek.${dayOfWeek}`} />
      </div>

      <div className={css.dailyPriceFields}>
        <FieldCurrencyInput
          className={classNames(css.priceInput, css.firstDailyPriceInput)}
          id={`pricesPerDayOfWeek.${dayOfWeek}.hourlyPrice`}
          name={`pricesPerDayOfWeek.${dayOfWeek}.hourlyPrice`}
          placeholder={pricePlaceholder}
          {...hourlyLabelMaybe}
          currencyConfig={config.currencyConfig}
        />

        <FieldCurrencyInput
          className={css.priceInput}
          id={`pricesPerDayOfWeek.${dayOfWeek}.dailyPrice`}
          name={`pricesPerDayOfWeek.${dayOfWeek}.dailyPrice`}
          placeholder={pricePlaceholder}
          {...dailyLabelMaybe}
          currencyConfig={config.currencyConfig}
        />

        <div className={css.priceInput} />
      </div>
    </div>
  );
};

export const EditListingPricingFormComponent = props => {
  const [minimumValueError, setMinimumValueError] = useState(false);
  return (
    <FinalForm
      {...props}
      validate={values => {
        let someValueLess = false;
        Object.keys(values).forEach(v => {
          if (values[v] && values[v].amount < config.listingMinimumPriceSubUnits)
            someValueLess = true;
          if (v === 'pricesPerDayOfWeek') {
            Object.keys(values[v]).forEach(w => {
              Object.keys(values[v][w]).forEach(p => {
                if (
                  values[v][w] &&
                  values[v][w][p] &&
                  values[v][w][p].amount < config.listingMinimumPriceSubUnits
                )
                  someValueLess = true;
              });
            });
          }
        });
        someValueLess ? setMinimumValueError(true) : setMinimumValueError(false);
      }}
      render={formRenderProps => {
        const {
          className,
          ready,
          handleSubmit,
          disabled,
          weekdays,
          values,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          intl,
          viewport,
        } = formRenderProps;

        const showWeekPrices = values.showWeekPrices && values.showWeekPrices.length > 0;

        const pricePlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.pricePlaceholder',
        });
        const weekPricesSubline = intl.formatMessage({
          id: 'EditListingPricingForm.showWeekPricesDescription',
        });

        const { pricePerHour, pricePerDay, pricePerWeek, pricesPerDayOfWeek } = values;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const ratePriceRequired = !showWeekPrices && !pricePerHour && !pricePerDay && !pricePerWeek;
        const weekPriceRequired =
          showWeekPrices &&
          (!pricePerHour &&
            !pricePerDay &&
            !pricePerWeek &&
            haveOnlyNullProperties(pricesPerDayOfWeek));
        const submitDisabled =
          submitInProgress ||
          disabled ||
          ratePriceRequired ||
          weekPriceRequired ||
          minimumValueError;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <div>
              <div className={css.generalPricingContainer}>
                <h3 className={css.pricingHeader}>
                  <FormattedMessage id="EditListingPricingForm.hourlyPrice" />
                  <Tooltip
                    id="hourlyPriceTooltip"
                    content={intl.formatMessage({ id: 'EditListingPricingForm.tooltipHourly' })}
                    className={css.tooltip}
                  />
                </h3>
                <h3 className={css.pricingHeader}>
                  <FormattedMessage id="EditListingPricingForm.dailyPrice" />
                  <Tooltip
                    id="dailyPriceTooltip"
                    content={intl.formatMessage({ id: 'EditListingPricingForm.tooltipDaily' })}
                    className={css.tooltip}
                  />
                </h3>
                <h3 className={css.pricingHeader}>
                  <FormattedMessage id="EditListingPricingForm.weeklyPrice" />
                  <Tooltip
                    id="weeklyPriceTooltip"
                    content={intl.formatMessage({ id: 'EditListingPricingForm.tooltipWeekly' })}
                    className={css.tooltip}
                  />
                </h3>
              </div>
              <div className={css.pricingDescription}>
                <FormattedMessage id="EditListingPricingForm.generalPricingDescription" />
              </div>
              <div className={css.generalPricingContainer}>
                <FieldCurrencyInput
                  className={css.priceInput}
                  id="pricePerHour"
                  name="pricePerHour"
                  placeholder={pricePlaceholder}
                  currencyConfig={config.currencyConfig}
                />
                <FieldCurrencyInput
                  className={css.priceInput}
                  id="pricePerDay"
                  name="pricePerDay"
                  placeholder={pricePlaceholder}
                  currencyConfig={config.currencyConfig}
                />
                <FieldCurrencyInput
                  className={css.priceInput}
                  id="pricePerWeek"
                  name="pricePerWeek"
                  placeholder={pricePlaceholder}
                  currencyConfig={config.currencyConfig}
                />
              </div>
              {ratePriceRequired && (
                <div className={css.error}>
                  {intl.formatMessage({ id: 'EditListingPricingForm.priceRequired' })}
                </div>
              )}
            </div>

            <div className={css.container}>
              <FieldCheckbox
                key="showWeekPrices"
                // className={css.checkbox}
                textClassName={css.checkboxLabel}
                id="showWeekPrices"
                name="showWeekPrices"
                label={intl.formatMessage({ id: 'EditListingPricingForm.showWeekPrices' })}
                value="showWeekPrices"
                useSuccessColor
              />
              <p className={css.subline}>{weekPricesSubline}</p>
            </div>

            {showWeekPrices && (
              <div className={css.dailyPrices}>
                <div className={css.dailyPricingContainer}>
                  <h3 className={css.dailyPricingLabel}>
                    <FormattedMessage id="EditListingPricingForm.hourlyPrice" />
                  </h3>
                  <h3 className={css.dailyPricingLabel}>
                    <FormattedMessage id="EditListingPricingForm.dailyPrice" />
                  </h3>
                  <h3 className={css.dailyPricingLabel}>
                    <FormattedMessage id="EditListingPricingForm.weeklyPrice" />
                  </h3>
                </div>
                <Field
                  id="pricesPerDayOfWeek"
                  name="pricesPerDayOfWeek"
                  render={() =>
                    weekdays.map(w => (
                      <DailyPrice
                        dayOfWeek={w}
                        key={w}
                        pricePlaceholder={pricePlaceholder}
                        showLabel={viewport.width < 768}
                        intl={intl}
                      />
                    ))
                  }
                />
                {weekPriceRequired && (
                  <div className={css.error}>
                    {intl.formatMessage({ id: 'EditListingPricingForm.priceRequired' })}
                  </div>
                )}
              </div>
            )}
            {minimumValueError && (
              <div className={css.minimumPriceError}>
                {intl.formatMessage(
                  { id: 'EditListingPricingForm.minimumPriceRequired' },
                  {
                    minPrice: formatMoney(
                      intl,
                      new Money(config.listingMinimumPriceSubUnits, config.currency)
                    ),
                  }
                )}
              </div>
            )}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(
  injectIntl,
  withViewport
)(EditListingPricingFormComponent);
