import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  NamedLink /*, VerifiedBadge*/,
} from '../../components';

import css from './SignupFormStepTwo.module.css';

class SignupFormStepTwoComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            // email
            onVerifyEmail,
            emailVerified,
            email,
            onSendVerificationEmail,
            sendVerificationEmailInProgress,
            sendVerificationEmailSent,
            sendVerificationEmailError,
            verifyEmailInProgress,
            // phone
            onVerifyPhone,
            isPhoneNumberVerified,
            phoneNumber,
            onSendVerificationPhone,
            sendVerificationPhoneInProgress,
            sendVerificationPhoneSent,
            sendVerificationPhoneError,
            verifyPhoneInProgress,
            values,
            intl,
            // authWithFacebook,
            // facebookVerified,
            // connectedUser,
          } = fieldRenderProps;

          const emailVerificationPlaceholder = intl.formatMessage({
            id: 'SignupFormStepTwo.emailVerificationPlaceholder',
          });
          const emailVerificationRequiredMessage = intl.formatMessage({
            id: 'SignupFormStepTwo.emailVerificationRequiredMessage',
          });
          const emailVerificationRequired = validators.required(emailVerificationRequiredMessage);
          const emailVerificationVerified = intl.formatMessage({
            id: 'SignupFormStepTwo.emailVerificationVerified',
          });
          const emailVerificationError = intl.formatMessage({
            id: 'SignupFormStepTwo.emailVerificationError',
          });

          const phoneVerificationPlaceholder = intl.formatMessage({
            id: 'SignupFormStepTwo.phoneVerificationPlaceholder',
          });
          const phoneVerificationRequiredMessage = intl.formatMessage({
            id: 'SignupFormStepTwo.phoneVerificationRequiredMessage',
          });
          const phoneVerificationRequired = validators.required(phoneVerificationRequiredMessage);
          const phoneVerificationError = intl.formatMessage({
            id: 'SignupFormStepTwo.phoneVerificationError',
          });

          const verificationCodeSentEmail = intl.formatMessage(
            {
              id: 'SignupFormStepTwo.verificationCodeSent',
            },
            { emailOrPhone: email }
          );

          const verificationCodeSentPhoneNumber = intl.formatMessage(
            {
              id: 'SignupFormStepTwo.verificationCodeSent',
            },
            { emailOrPhone: phoneNumber }
          );

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = verifyEmailInProgress || verifyPhoneInProgress;
          const submitPhoneDisabled = !isPhoneNumberVerified && !values.code;
          const submitEmailDisabled = !emailVerified && !values.token;
          const submitDisabled = submitEmailDisabled || submitPhoneDisabled || submitInProgress;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <h3 className={css.stepTitle}>
                  <FormattedMessage id="SignupPageStepTwo.stepTitle" />
                </h3>
                <div className={css.description}>
                  <FormattedMessage id="SignupFormStepTwo.stepDescription" />
                </div>
                <div className={css.row}>
                  <h3
                    className={classNames(css.label, {
                      [css.titleDisabled]: emailVerified,
                    })}
                  >
                    <FormattedMessage id="SignupFormStepTwo.emailVerificationLabel" />
                  </h3>
                  <PrimaryButton
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      onSendVerificationEmail();
                    }}
                    inProgress={sendVerificationEmailInProgress}
                    className={css.button}
                    disabled={emailVerified}
                  >
                    <FormattedMessage id="SignupFormStepTwo.emailVerificationButtonText" />
                  </PrimaryButton>
                  <FieldTextInput
                    className={css.input}
                    type="text"
                    id={formId ? `${formId}.token` : 'token'}
                    name="token"
                    disabled={emailVerified}
                    placeholder={emailVerificationPlaceholder}
                    validate={emailVerified ? () => {} : emailVerificationRequired}
                  />
                  <PrimaryButton
                    type="button"
                    className={css.verifyButton}
                    disabled={submitEmailDisabled || verifyEmailInProgress || emailVerified}
                    inProgress={verifyEmailInProgress}
                    ready={emailVerified}
                    onClick={() => onVerifyEmail(values.token)}
                  >
                    <FormattedMessage id="SignupFormStepTwo.verifyEmail" />
                  </PrimaryButton>
                </div>

                <div className={css.error}>
                  {sendVerificationEmailError
                    ? sendVerificationEmailError.code === 409
                      ? emailVerificationVerified
                      : emailVerificationError
                    : null}
                </div>
                <div className={css.codeSent}>
                  {emailVerified
                    ? emailVerificationVerified
                    : sendVerificationEmailSent
                    ? verificationCodeSentEmail
                    : null}
                </div>
                <hr className={css.linebreak}></hr>
                <div className={css.row}>
                  <h3
                    className={classNames(css.label, {
                      [css.titleDisabled]: isPhoneNumberVerified,
                    })}
                  >
                    <FormattedMessage id="SignupFormStepTwo.phoneVerificationLabel" />
                  </h3>
                  <PrimaryButton
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      onSendVerificationPhone();
                    }}
                    inProgress={sendVerificationPhoneInProgress}
                    className={css.button}
                    disabled={isPhoneNumberVerified}
                  >
                    <FormattedMessage id="SignupFormStepTwo.phoneVerificationButtonText" />
                  </PrimaryButton>
                  <FieldTextInput
                    className={css.input}
                    type="text"
                    id={formId ? `${formId}.code` : 'code'}
                    name="code"
                    placeholder={isPhoneNumberVerified ? () => {} : phoneVerificationPlaceholder}
                    validate={isPhoneNumberVerified ? () => {} : phoneVerificationRequired}
                    disabled={isPhoneNumberVerified}
                  />
                  <PrimaryButton
                    type="button"
                    className={css.verifyButton}
                    disabled={submitPhoneDisabled || verifyPhoneInProgress || isPhoneNumberVerified}
                    inProgress={verifyPhoneInProgress}
                    ready={isPhoneNumberVerified}
                    onClick={() => onVerifyPhone(values.code)}
                  >
                    <FormattedMessage id="SignupFormStepTwo.verifyPhoneNumber" />
                  </PrimaryButton>
                </div>
                <div className={css.error}>
                  {sendVerificationPhoneError ? phoneVerificationError : null}
                </div>
                <div className={css.codeSent}>
                  {sendVerificationPhoneSent ? verificationCodeSentPhoneNumber : null}
                </div>
                <NamedLink name="AccountSettingsPage">
                  <FormattedMessage id="SignupFormStepTwo.replaceEmailPhoneLink" />
                </NamedLink>
                {/* <hr className={css.linebreak}></hr>
                <h3>
                  <FormattedMessage id="SignupFormStepTwo.optionalVerification" />
                </h3>
                <div>
                  <FormattedMessage id="SignupFormStepTwo.optionalVerificationDescription" />
                </div>
                <h3>
                  <VerifiedBadge isVerified />
                </h3>
                <div className={css.row}>
                  <h3 className={classNames(css.label, { [css.titleDisabled]: facebookVerified })}>
                    <FormattedMessage id="SignupFormStepTwo.facebook" />
                  </h3>
                  <PrimaryButton
                    onClick={authWithFacebook}
                    className={css.button}
                    type="button"
                    disabled={facebookVerified}
                  >
                    <FormattedMessage id="SignupFormStepTwo.connectToFacebook" />
                  </PrimaryButton>

                  <div className={css.input} />
                </div>
                <div className={css.codeSent}>
                  {facebookVerified ? (
                    <FormattedMessage
                      id="SignupFormStepTwo.connectedToFacebook"
                      values={{ profileName: connectedUser }}
                    />
                  ) : null}
                </div>
                <hr className={css.linebreak} /> */}
              </div>

              <div className={css.bottomWrapper}>
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SignupFormStepTwo.submit" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SignupFormStepTwoComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

SignupFormStepTwoComponent.propTypes = {
  inProgress: bool,

  emailVerified: bool,
  isPhoneNumberVerified: bool,

  // onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupFormStepTwo = compose(injectIntl)(SignupFormStepTwoComponent);
SignupFormStepTwo.displayName = 'SignupFormStepTwo';

export default SignupFormStepTwo;
